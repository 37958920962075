import { useCallback, useMemo, useEffect, useRef, useState } from 'react';
import {
    faBuilding,
    faCaretDown,
    faCaretRight,
    faFolder,
    faFolderPlus,
    faGlobe,
    faLock,
    faPaste,
    faPen,
    faPlus,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Workspace } from '@workspace/domain/workspace';
import { Link, useParams } from 'react-router-dom';
import { FolderItem } from './FolderItem';
import { ViewModelItem } from './ViewModelItem';
import { useSidebarWorkspace } from './useSidebarWorkspace';
import { RTDBPath, usePrimitiveValueListener } from '@framework/repository';
import { WorkspaceInternalPublicMemberRoleJSON } from '@schema-common/workspace';
import { SidebarMenuItem } from './SidebarMenuItem';
import { ViewModelCreateModal } from '@workspace/view-model-folder/ui/ViewModelCreateModal';
import {
    useCreateFolderToRoot,
    useCreateViewModelFromClipboardPayloadHandler,
} from '@workspace/view-model-folder/hooks';
import { useCurrentUserId } from '@framework/auth';
import { HighlightText } from './HighlightText';
import { useSidebarTreeContext } from './SidebarTreeContext';
import { WorkspaceEditModal } from '@user/pages/WorkspacePage/internal/WorkspaceEditModal';
import { SidebarMenuButton } from './SidebarMenuButton';
import { WorkspaceDeleteConfirmModal } from '@user/pages/WorkspacePage/internal/WorkspaceDeleteConfirmModal';
import { RouteParams } from '@user/pages/RouteParams';
import { FolderCreateModal } from '@workspace/view-model-folder/ui/FolderCreateModal';
import { RootFolderTree } from '@workspace/view-model-folder/domain/FolderTree';
import { ViewModelOperation } from '@view-model/domain/view-model';
import { useActionLogSender } from '@framework/action-log';

type Modals = 'ViewModelCreate' | 'WorkspaceEdit' | 'WorkspaceDelete' | 'FolderCreate';

// サイドバーのワークスペースのメニューから
const useHandleModals = () => {
    const [openModal, setOpenModal] = useState<Modals | null>(null);

    return {
        openModal,
        handleViewModelCreateModalOpen: useCallback(() => {
            setOpenModal('ViewModelCreate');
        }, []),
        handleWorkspaceEditModalOpen: useCallback(() => {
            setOpenModal('WorkspaceEdit');
        }, []),
        handleWorkspaceDeleteModalOpen: useCallback(() => {
            setOpenModal('WorkspaceDelete');
        }, []),
        handleFolderCreateModalOpen: useCallback(() => {
            setOpenModal('FolderCreate');
        }, []),
        handleModalClose: useCallback(() => {
            setOpenModal(null);
        }, []),
    };
};

const useIsSidebarMenuButtonShowable = (
    workspace: Workspace
): {
    isMenuButtonShowable: boolean;
    isAdminMenuButtonShowable: boolean;
} => {
    const currentUserId = useCurrentUserId();
    return useMemo(() => {
        if (!currentUserId)
            return {
                isMenuButtonShowable: false,
                isAdminMenuButtonShowable: false,
            };

        const currentUserMemberRole = workspace.getMemberRoleOf(currentUserId);

        const isAdminMenuButtonShowable = currentUserMemberRole === 'admin';
        const isEditorMenuButtonShowable = currentUserMemberRole === 'editor';
        return {
            isMenuButtonShowable: isAdminMenuButtonShowable || isEditorMenuButtonShowable,
            isAdminMenuButtonShowable,
        };
    }, [currentUserId, workspace]);
};

type Props = {
    workspace: Workspace;
    shouldBeScrolled: boolean;
};

export const WorkspaceItem: React.FC<Props> = ({ workspace, shouldBeScrolled }: Props) => {
    const actionLogSender = useActionLogSender();
    const {
        sortedFolders,
        sortedViewModels,
        isOpen,
        toggleWorkspace,
        isSelected,
        resetScrollWorkspace,
        resetScrollViewModel,
        scrolledViewModelId,
    } = useSidebarWorkspace(workspace.id);
    const [internalPublicSetting] = usePrimitiveValueListener<WorkspaceInternalPublicMemberRoleJSON>(
        RTDBPath.Group.groupContentInternalPublicWorkspacePath(workspace.ownerGroupId, workspace.id)
    );
    const { workspaceId: workspaceIdParams } = useParams<RouteParams>();
    const {
        openModal,
        handleViewModelCreateModalOpen,
        handleWorkspaceEditModalOpen,
        handleWorkspaceDeleteModalOpen,
        handleFolderCreateModalOpen,
        handleModalClose,
    } = useHandleModals();
    const createFolderToRoot = useCreateFolderToRoot(workspace.id);

    // ビューモデルをワークスペース直下に作成するメソッド
    const handleViewModelInRootSubmit = useCallback(
        async (name: string) => {
            await ViewModelOperation.createToRoot(workspace.id, name, actionLogSender);
            handleModalClose();
        },
        [actionLogSender, handleModalClose, workspace.id]
    );

    const createViewModelFromClipboardPayload = useCreateViewModelFromClipboardPayloadHandler(
        workspace.ownerGroupId,
        workspace.id,
        RootFolderTree.ROOT_ID
    );

    const ref = useRef<HTMLDivElement | null>(null);

    const { isMenuButtonShowable, isAdminMenuButtonShowable } = useIsSidebarMenuButtonShowable(workspace);

    const { searchKeyword } = useSidebarTreeContext();

    useEffect(() => {
        if (shouldBeScrolled && ref.current) {
            const rect = ref.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            if (rect.bottom > windowHeight || rect.top < 0) {
                ref.current.scrollIntoView();
            }
            resetScrollWorkspace();
        }
    }, [shouldBeScrolled, ref, resetScrollWorkspace]);

    return (
        <div className="relative" ref={ref}>
            <div className="flex items-center">
                <div className="mr-1">
                    <button className="focus:outline-none" onClick={toggleWorkspace}>
                        <FontAwesomeIcon icon={isOpen ? faCaretDown : faCaretRight} className="size-4" />
                    </button>
                </div>
                <SidebarMenuItem
                    isSelected={isSelected}
                    isMenuButtonShowable={isMenuButtonShowable}
                    popupMenu={
                        <>
                            <SidebarMenuButton
                                handleClick={handleViewModelCreateModalOpen}
                                icon={faPlus}
                                title={'新規ビューモデル'}
                            />
                            <SidebarMenuButton
                                handleClick={handleFolderCreateModalOpen}
                                icon={faFolderPlus}
                                title={'新規フォルダ'}
                            />
                            <SidebarMenuButton
                                handleClick={createViewModelFromClipboardPayload}
                                icon={faPaste}
                                title={'ビューモデルをペースト'}
                            />
                            {isAdminMenuButtonShowable && (
                                <>
                                    <SidebarMenuButton
                                        handleClick={handleWorkspaceEditModalOpen}
                                        icon={faPen}
                                        title={'編集'}
                                    />
                                    <SidebarMenuButton
                                        handleClick={handleWorkspaceDeleteModalOpen}
                                        icon={faTrashAlt}
                                        title={'削除'}
                                        className="text-red-700"
                                    />
                                </>
                            )}
                        </>
                    }
                >
                    <Link to={`/workspaces/${workspace.id}`} className="flex items-center justify-start">
                        <span className="fa-layers fa-fw mr-1 shrink-0 text-brand">
                            <FontAwesomeIcon icon={faFolder} />
                            <FontAwesomeIcon
                                icon={
                                    workspace.isPersonal
                                        ? faUser
                                        : workspace.isPublicSpace
                                          ? faGlobe
                                          : internalPublicSetting
                                            ? faBuilding
                                            : faLock
                                }
                                inverse
                                transform="shrink-8 down-1"
                            />
                        </span>
                        <HighlightText
                            className="min-w-0 truncate"
                            text={workspace.name.toString()}
                            keyword={searchKeyword}
                        />
                    </Link>
                </SidebarMenuItem>
            </div>

            {/* ビューモデル作成モーダル */}
            {openModal === 'ViewModelCreate' && (
                <ViewModelCreateModal
                    isOpen={openModal === 'ViewModelCreate'}
                    onSubmit={handleViewModelInRootSubmit}
                    onClose={handleModalClose}
                />
            )}

            {/* ワークスペース編集モーダル */}
            {openModal === 'WorkspaceEdit' && (
                <WorkspaceEditModal
                    isOpen={openModal === 'WorkspaceEdit'}
                    onClose={handleModalClose}
                    workspace={workspace}
                />
            )}

            {/* ワークスペース削除モーダル */}
            {openModal === 'WorkspaceDelete' && (
                <WorkspaceDeleteConfirmModal
                    isOpen={openModal === 'WorkspaceDelete'}
                    onClose={handleModalClose}
                    workspace={workspace}
                    shouldMoveToGroupPage={workspaceIdParams === workspace.id}
                />
            )}

            {/* フォルダー作成モーダル */}
            {openModal === 'FolderCreate' && (
                <FolderCreateModal
                    isOpen={openModal === 'FolderCreate'}
                    onSubmit={createFolderToRoot}
                    onClose={handleModalClose}
                />
            )}

            {isOpen && (
                <div className="ml-4">
                    <div>
                        {sortedFolders.map((folder) => (
                            <FolderItem
                                key={folder.id}
                                workspace={workspace}
                                folder={folder}
                                isMenuButtonShowable={isMenuButtonShowable}
                            />
                        ))}
                    </div>
                    <div>
                        {sortedViewModels.map((viewModel) => (
                            <ViewModelItem
                                key={viewModel.id}
                                viewModel={viewModel}
                                workspaceSetting={workspace.setting}
                                shouldBeScrolled={scrolledViewModelId === viewModel.id}
                                resetScrollViewModel={resetScrollViewModel}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
