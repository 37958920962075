import Mousetrap, { ExtendedKeyboardEvent } from 'mousetrap';
import { ModelKey } from '@view-model/domain/key';

type Unsubscribe = () => void;

const ShortcutEvents = {
    escape: ['esc'],
    enter: ['enter'],
    selectAll: ['mod+a'],
    shiftKey: ['shift'],
    shiftKeyUp: ['shift'],
    modKey: ['mod'],
    modKeyUp: ['mod'],
    delete: ['del', 'backspace'],
    undo: ['mod+z'],
    redo: ['mod+shift+z'],
    up: ['up'],
    down: ['down'],
    left: ['left'],
    right: ['right'],
} as Record<string, string[]>;
type ShortcutEventNames = keyof typeof ShortcutEvents;

type ModelMountedCallbacks = {
    onSelectAll: () => void;
    onEscape: () => void;
    onEnter: () => void;
    onDelete: () => void;
    onUp: () => void;
    onDown: () => void;
    onLeft: () => void;
    onRight: () => void;
};

type Handler = (e: ExtendedKeyboardEvent) => void;

export class ShortcutManager {
    private readonly _callbacks: Record<ShortcutEventNames, Handler[]> = {};
    private readonly _viewMounted: Record<string, Unsubscribe[]> = {};
    private readonly _unsubscribes: Unsubscribe[] = [];

    public constructor() {
        this.emit = this.emit.bind(this);
        this.listenOn = this.listenOn.bind(this);
    }

    /**
     * キーボードイベントのリスンを開始する
     */
    addListener(): void {
        Mousetrap.bind(ShortcutEvents['escape'], (e) => this.emit('escape', e));
        Mousetrap.bind(ShortcutEvents['enter'], (e) => this.emit('enter', e));
        Mousetrap.bind(ShortcutEvents['selectAll'], (e) => this.emit('selectAll', e));
        Mousetrap.bind(ShortcutEvents['shiftKey'], (e) => this.emit('shiftKey', e));
        Mousetrap.bind(ShortcutEvents['shiftKeyUp'], (e) => this.emit('shiftKeyUp', e), 'keyup');
        Mousetrap.bind(ShortcutEvents['modKey'], (e) => this.emit('modKey', e));
        Mousetrap.bind(ShortcutEvents['modKeyUp'], (e) => this.emit('modKeyUp', e), 'keyup');
        Mousetrap.bind(ShortcutEvents['delete'], (e) => this.emit('delete', e));
        Mousetrap.bind(ShortcutEvents['undo'], (e) => this.emit('undo', e));
        Mousetrap.bind(ShortcutEvents['redo'], (e) => this.emit('redo', e));
        Mousetrap.bind(ShortcutEvents['up'], (e) => this.emit('up', e));
        Mousetrap.bind(ShortcutEvents['down'], (e) => this.emit('down', e));
        Mousetrap.bind(ShortcutEvents['left'], (e) => this.emit('left', e));
        Mousetrap.bind(ShortcutEvents['right'], (e) => this.emit('right', e));
    }

    /**
     * キーボードイベントのリスンを終了する
     */
    removeListener(): void {
        Mousetrap.unbind(ShortcutEvents['escape']);
        Mousetrap.unbind(ShortcutEvents['enter']);
        Mousetrap.unbind(ShortcutEvents['selectAll']);
        Mousetrap.unbind(ShortcutEvents['shiftKey']);
        Mousetrap.unbind(ShortcutEvents['shiftKeyUp'], 'keyup');
        Mousetrap.unbind(ShortcutEvents['modKey']);
        Mousetrap.unbind(ShortcutEvents['modKeyUp'], 'keyup');
        Mousetrap.unbind(ShortcutEvents['delete']);
        Mousetrap.unbind(ShortcutEvents['undo']);
        Mousetrap.unbind(ShortcutEvents['redo']);
        Mousetrap.unbind(ShortcutEvents['up']);
        Mousetrap.unbind(ShortcutEvents['down']);
        Mousetrap.unbind(ShortcutEvents['left']);
        Mousetrap.unbind(ShortcutEvents['right']);
    }

    /**
     * ビューモデル全体でのキーボードイベントをリスン開始する
     * @param onIsMultiSelectionModeChanged
     * @param onDelete
     * @param onUndo
     * @param onRedo
     */
    onMounted(
        turnOnMultiSelectionMode: () => void,
        turnOffMultiSelectionMode: () => void,
        onDelete: () => void,
        onUndo: () => void,
        onRedo: () => void
    ): void {
        // Shiftキーまたは、OS環境別の修飾キー(Macの場合 meta, 他では ctrl) を押されている場合には、複数選択モードとして取り扱う
        this._unsubscribes.push(this.listenOn('shiftKey', () => turnOnMultiSelectionMode()));
        this._unsubscribes.push(this.listenOn('shiftKeyUp', () => turnOffMultiSelectionMode()));
        this._unsubscribes.push(this.listenOn('modKey', () => turnOnMultiSelectionMode()));
        this._unsubscribes.push(this.listenOn('modKeyUp', () => turnOffMultiSelectionMode()));

        this._unsubscribes.push(this.listenOn('delete', onDelete));
        this._unsubscribes.push(this.listenOn('undo', onUndo));
        this._unsubscribes.push(this.listenOn('redo', onRedo));
    }

    /**
     * ビューモデル全体でのキーボードイベントをリスン終了する
     */
    onWillUnmount(): void {
        while (this._unsubscribes.length) {
            this._unsubscribes.pop()?.();
        }
    }

    /**
     * モデル単位でのキーボードイベントをリスン開始する
     * @param modelKey
     * @param param1
     */
    onModelContentsViewMounted(
        modelKey: ModelKey,
        { onSelectAll, onEscape, onEnter, onDelete, onUp, onDown, onLeft, onRight }: ModelMountedCallbacks
    ): void {
        const unsubscribes = this._viewMounted[modelKey.toString()] || [];

        this._viewMounted[modelKey.toString()] = [
            ...unsubscribes,
            this.listenOn('selectAll', onSelectAll),
            this.listenOn('escape', onEscape),
            this.listenOn('enter', onEnter),
            this.listenOn('delete', onDelete),
            this.listenOn('up', onUp),
            this.listenOn('down', onDown),
            this.listenOn('left', onLeft),
            this.listenOn('right', onRight),
        ];
    }

    /**
     * モデル単位でのキーボードイベントをリスン終了する
     * @param modelKey
     */
    onModelContentsViewWillUnmount(modelKey: ModelKey): void {
        const u = this._viewMounted[modelKey.toString()] || [];
        u.forEach((unsubscribe) => unsubscribe());
        delete this._viewMounted[modelKey.toString()];
    }

    /**
     * ESCキーの入力イベントをりすん開始する。
     * @param cb
     * @returns リスン終了するための関数
     */
    listenOnEscape(cb: (e: ExtendedKeyboardEvent) => void): Unsubscribe {
        return this.listenOn('escape', cb);
    }

    private listenOn(event: ShortcutEventNames, cb: (e: ExtendedKeyboardEvent) => void): Unsubscribe {
        (this._callbacks[event] = this._callbacks[event] || []).push(cb);
        return () => (this._callbacks[event] = this._callbacks[event].filter((i) => i !== cb));
    }

    // private once(event: ShortcutEventNames, cb: () => void): Unsubscribe {
    //     const unsubscribe = this.listenOn(event, (...args) => {
    //         unsubscribe();
    //         cb(...args);
    //     });
    //     return unsubscribe;
    // }

    private emit(event: ShortcutEventNames, e: ExtendedKeyboardEvent): void {
        if (e.preventDefault) {
            e.preventDefault();
        }
        (this._callbacks[event] || []).forEach((cb) => cb(e));
    }
}
