import { useCallback, useEffect, useState } from 'react';
import { FlatButton } from '@framework/ui/atoms';
import { Modal } from '@framework/ui/elements';
import { ImageCropModal } from '@framework/ui/elements/ImageCropModal';
import { generateDefaultIcon } from '@framework/utils';
import { GroupEntity, GroupLicense } from '@group/domain';
import { GroupAnalysisSetting, GroupMemberSignUpMethod } from '@group/domain/GroupEntity';
import { GroupOperation } from '@group/domain/GroupEntity/GroupOperation';
import { usePrevious } from '@view-model/models/common/hooks/usePrevious';
import { AnalysisSettingForm } from './AnalysisSettingForm';
import { AvailableSignUpMethodForm } from './AvailableSignUpMethodForm';
import { PublicIconUploader } from '@framework/utils/PublicIconUploader';
import { toast } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { GroupLicenseInfo } from './GroupLicenseInfo';

type Props = {
    isOpen: boolean;
    onClose(): void;
    groupEntity: GroupEntity;
    license: GroupLicense | null | undefined;
};

export const GroupEditModal: React.FC<Props> = ({ isOpen, onClose, groupEntity, license }: Props) => {
    const [processing, setProcessing] = useState<boolean>(false);
    const [name, setName] = useState('');
    const [signUpMethods, setSignUpMethods] = useState(GroupMemberSignUpMethod.buildNew());
    const [analysisSetting, setAnalysisSetting] = useState(GroupAnalysisSetting.buildNew());
    const [isCropModalOpen, setIsCropModalOpen] = useState<boolean>(false);
    const [uploadedImage, setUploadedImage] = useState<File | null>(null);
    const [iconImage, setIconImage] = useState<File | null>(null);
    const [iconUrl, setIconUrl] = useState<string | null>(groupEntity.imageUrl);
    const previousIsOpen = usePrevious(isOpen);

    const handleUploadImage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) setUploadedImage(event.target.files[0]);
    }, []);

    const isValid = name.length > 0 && !signUpMethods.isEmpty();

    // isOpen が false から true に変化したときに props の GroupEntity から state に値を複製する。
    useEffect(() => {
        if (isOpen && !previousIsOpen) {
            setName(groupEntity.name.toString());
            setSignUpMethods(groupEntity.setting.availableSignUpMethods);
            setAnalysisSetting(groupEntity.setting.analysisSetting);
            setIconUrl(groupEntity.imageUrl);
        }
    }, [isOpen, previousIsOpen, groupEntity]);

    useEffect(() => {
        if (uploadedImage) setIsCropModalOpen(true);
    }, [uploadedImage]);

    const handleChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }, []);
    const handleChangeSignUpMethods = useCallback((signUpMethods: GroupMemberSignUpMethod) => {
        setSignUpMethods(signUpMethods);
    }, []);

    const handleChangeAnalysisSetting = useCallback((analysisSetting: GroupAnalysisSetting) => {
        setAnalysisSetting(analysisSetting);
    }, []);

    const handleClose = useCallback(() => {
        setProcessing(false);
        onClose();
    }, [onClose]);

    const handleSubmit = async () => {
        setProcessing(true);

        const newIconUrl = iconImage ? await PublicIconUploader.uploadGroupIcon(groupEntity.id, iconImage) : iconUrl;
        setIconUrl(newIconUrl);

        if (await GroupOperation.update(groupEntity.id, name, signUpMethods, analysisSetting, newIconUrl)) {
            toast.success('グループ情報を変更しました。');
            handleClose();
        } else {
            toast.error('グループ情報の変更に失敗しました。');
            setProcessing(false);
        }
    };

    const handleCropModalClose = useCallback(() => {
        (document.getElementById('icon-image') as HTMLInputElement).value = '';
        setUploadedImage(null);
        setIsCropModalOpen(false);
    }, []);

    const handleCropModalSubmit = useCallback((image: File) => {
        setIconImage(image);
        setIconUrl(URL.createObjectURL(image));
        setIsCropModalOpen(false);
    }, []);

    const handleImageClear = useCallback(() => {
        setIconImage(null);
        setIconUrl(null);
    }, []);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} className="flex flex-col">
                {/* header */}
                <div className="mb-1 px-8 pb-4 pt-8 text-2xl font-bold">グループを編集</div>
                <hr className="w-full" />

                {/* content */}
                <div className="flex flex-col p-8">
                    <div className="flex justify-between">
                        {iconUrl ? (
                            <div className="relative size-16">
                                <div className="absolute size-16 overflow-hidden rounded-full">
                                    <img className="size-full" src={iconUrl} alt={name} />
                                </div>
                                <div className="absolute flex size-16 items-start justify-end">
                                    <div
                                        className="relative flex cursor-pointer items-center justify-center"
                                        onClick={handleImageClear}
                                    >
                                        <FontAwesomeIcon icon={faCircle} className="absolute text-2xl text-red-500" />
                                        <FontAwesomeIcon icon={faTimes} className="absolute text-xl text-white" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="relative size-16 overflow-hidden rounded-full">
                                <div className="absolute size-full opacity-50">
                                    {generateDefaultIcon({
                                        id: groupEntity.id,
                                        name: groupEntity.name.toString(),
                                        size: 64,
                                    })}
                                </div>
                                <div className="absolute flex size-full items-center justify-center">
                                    <FontAwesomeIcon icon={faPlus} className="text-2xl text-blue-500" />
                                </div>
                                <input
                                    type="file"
                                    id="icon-image"
                                    className="absolute size-full cursor-pointer opacity-0"
                                    accept="image/png, image/jpeg"
                                    onChange={handleUploadImage}
                                />
                            </div>
                        )}
                        <div className="flex w-5/6 flex-col">
                            <div className="font-bold">名前</div>
                            <input
                                type="text"
                                className="rounded-sm border p-2"
                                value={name}
                                onChange={handleChangeName}
                                autoFocus
                            />
                        </div>
                    </div>

                    <div className="pb-2 pt-8 font-bold">新規ユーザ登録時に利用可能なログイン方法</div>
                    <AvailableSignUpMethodForm signUpMethod={signUpMethods} onChange={handleChangeSignUpMethods} />

                    <div className="pb-2 pt-8 font-bold">分析機能の使用</div>
                    <AnalysisSettingForm analysisSetting={analysisSetting} onChange={handleChangeAnalysisSetting} />

                    {license && (
                        <>
                            <div className="pb-2 pt-8 font-bold">ライセンスの情報</div>
                            <GroupLicenseInfo license={license} />
                        </>
                    )}
                </div>

                {/* footer */}
                <hr className="w-full" />
                <div className="flex justify-end px-8 pb-8 pt-4">
                    <FlatButton onClick={handleClose}>閉じる</FlatButton>
                    <FlatButton
                        color="brand"
                        onClick={handleSubmit}
                        className="ml-2 px-8"
                        disabled={!isValid || processing}
                        loading={processing}
                    >
                        保存
                    </FlatButton>
                </div>
            </Modal>
            {uploadedImage && (
                <ImageCropModal
                    isOpen={isCropModalOpen}
                    onClose={handleCropModalClose}
                    onSubmit={handleCropModalSubmit}
                    src={uploadedImage}
                />
            )}
        </>
    );
};
