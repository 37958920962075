import { useCallback, useState } from 'react';
import { FlatButton } from '@framework/ui/atoms';
import { GroupEntity } from '@group/domain';
import { MemberEditModal, MemberRemoveModal } from './MemberEdit';
import { MenuName } from './GroupMemberEditTab';
import { MemberInvitationListModal, MemberInvitationModal } from './Invitations';
import { MemberIconList } from '@workspace/ui/WorkspaceList/MemberIconList';
import { UserPublicProfile } from '@user/PublicProfile';
import { useCurrentUserData } from '@user/UserData';

type Props = {
    groupEntity: GroupEntity;
    userProfiles: UserPublicProfile[];
};

export const GroupMemberEditButton: React.FC<Props> = ({ groupEntity, userProfiles }: Props) => {
    const currentUserData = useCurrentUserData();
    const [targetModal, setTargetModal] = useState<MenuName | null>(null);
    const handleClose = useCallback(() => setTargetModal(null), []);
    const handleOpenMembers = useCallback(() => setTargetModal('members'), []);
    const handleOpenInvitations = useCallback(() => setTargetModal('invitations'), []);

    const handleClickMenu = useCallback((menu: MenuName) => setTargetModal(menu), []);

    // メンバー削除モーダルの「閉じる」を押したときには、メンバー一覧に戻る
    const handleCloseRemoveModal = useCallback(() => setTargetModal('members'), []);

    // メンバー招待フォームの「閉じる」を押した時は、招待一覧に戻る
    const handleCloseInvitationFormModal = useCallback(() => setTargetModal('invitations'), []);

    if (!currentUserData) return null;

    return (
        <>
            <div onClick={handleOpenMembers} role="button">
                <MemberIconList profiles={userProfiles} numberOfDisplayIcons={2} />
            </div>
            <FlatButton className="px-4 py-1 text-base" onClick={handleOpenInvitations}>
                メンバーの招待
            </FlatButton>

            <MemberEditModal
                isOpen={targetModal === 'members'}
                onClose={handleClose}
                groupEntity={groupEntity}
                currentUserId={currentUserData.id}
                onClickMenu={handleClickMenu}
            />

            <MemberRemoveModal
                isOpen={targetModal === 'remove'}
                onClose={handleCloseRemoveModal}
                groupEntity={groupEntity}
                currentUserId={currentUserData.id}
                onClickMenu={handleClickMenu}
            />

            <MemberInvitationListModal
                isOpen={targetModal === 'invitations'}
                onClose={handleClose}
                groupEntity={groupEntity}
                onClickMenu={handleClickMenu}
            />

            <MemberInvitationModal
                isOpen={targetModal === 'invitation'}
                onClose={handleCloseInvitationFormModal}
                groupEntity={groupEntity}
                sender={currentUserData}
                onClickMenu={handleClickMenu}
            />
        </>
    );
};
