import { useEffect, useState } from 'react';
import { FullScreenLoading } from '@framework/ui';
import { AuthContext, AuthStatus } from './AuthContext';
import { FirebaseAuthenticationClient } from '../firebase/auth/FirebaseAuthenticationClient';
import { UserId } from '@schema-common/base';
import { IdTokenSyncToCookie } from './IdTokenSyncToCookie';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';
import { ActiveAuthSessionListener } from './ActiveAuthSessionListener';
import { isEqual } from 'lodash';

const NullValue: AuthStatus = {
    currentUserId: null,
    currentSessionId: null,
    userType: null,
};

type Props = {
    children: (currentUserId: UserId | null) => React.ReactNode;
};

export const AuthContextProvider: React.FC<Props> = ({ children }: Props) => {
    const [loading, setLoading] = useState(true);
    const [authStatus, setAuthStatus] = useState<AuthStatus>(NullValue);

    // このコンポーネント(AuthContextProvider)の初回描画時に、 Firebase Authentication の認証状態 subscribe を開始する
    useEffect(() => {
        const unsubscribe = FirebaseAuthenticationClient.onAuthStateChanged(async (firebaseUser) => {
            const uid = firebaseUser?.uid || null;
            if (!uid) {
                setAuthStatus(NullValue);
                setLoading(false);
                return;
            }

            // Firebase Auth の認証状態が変化したときには、一度 loading 状態に切り替える。
            setLoading(true);

            FirebaseAuthenticationClient.getClaims()
                .then(async (claims) => {
                    // カスタムクレイムが取得できない場合には未ログイン状態として扱う
                    if (!claims) {
                        setAuthStatus(NullValue);
                        return;
                    }

                    // カスタムクレイムに userId が存在するにもかかわらず、 sessionId が存在しない場合には、
                    // 新形式での認証セッションへの移行(fallback)を試みる。
                    if (claims.userId && !claims.sessionId) {
                        const token = await FunctionsUserActions.reAuthenticate();
                        if (token) {
                            // 新たなカスタム認証トークンを利用して認証した後で、カスタムクレイムを再取得する
                            await FirebaseAuthenticationClient.signInWithCustomToken(token);
                            claims = await FirebaseAuthenticationClient.getClaims();
                            if (!claims) {
                                setAuthStatus(NullValue);
                                return;
                            }
                        }
                    }

                    // それでもセッションIDが取得できない場合には諦めて未ログイン状態として扱う
                    const sessionId = claims.sessionId;
                    if (!sessionId) {
                        setAuthStatus(NullValue);
                        return;
                    }

                    // 認証セッションの情報をRTDBから取得し、有効期限内であることをチェックする
                    await ActiveAuthSessionListener.start(sessionId, (authSession) => {
                        if (authSession) {
                            const newValue: AuthStatus = {
                                currentSessionId: authSession.sessionId,
                                currentUserId: authSession.userId,
                                userType: authSession.userType,
                            };
                            // AuthStatus の全ての値に変化がなければ、Stateの値が更新扱いされないようにチェックする
                            setAuthStatus((prev) => (isEqual(prev, newValue) ? prev : newValue));
                        } else {
                            // 認証セッションの有効期限が切れているので、フロントエンドの認証状態もサインアウトさせる
                            FirebaseAuthenticationClient.signOut();
                            setAuthStatus(NullValue);
                        }
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        });

        return () => {
            unsubscribe();
            ActiveAuthSessionListener.stop();
        };
    }, []);

    return (
        <div className="h-full">
            {loading ? (
                <FullScreenLoading />
            ) : (
                <AuthContext.Provider value={authStatus}>
                    {children(authStatus.currentUserId)}

                    {/* ログイン中ユーザのIDトークンをCookieに同期する */}
                    <IdTokenSyncToCookie />
                </AuthContext.Provider>
            )}
        </div>
    );
};
