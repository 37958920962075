import { ObjectRepository, RTDBPath } from '@framework/repository';
import { UserDataJSON } from '@schema-app/users/{userKey}/user-data/UserDataJSON';
import { UserData } from './UserData';
import { UserId } from '@schema-common/base';

type UserDataRepository = ObjectRepository<UserDataJSON, UserData>;

export const createUserDataRepository = (userId: UserId): UserDataRepository => {
    return new ObjectRepository(UserData, RTDBPath.User.userDataPath(userId));
};
