import { ObjectRepository, RTDBPath } from '@framework/repository';
import { Viewer } from './Viewer';
import { ViewerJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-viewer-contents/{viewModelId}/viewers/{clientId}/ViewerJSON';
import { ClientId, ViewModelId } from '@schema-common/base';

type ViewerRepository = ObjectRepository<ViewerJSON, Viewer>;

export const createViewerRepository = (viewModelId: ViewModelId, clientId: ClientId): ViewerRepository => {
    return new ObjectRepository(Viewer, RTDBPath.ViewModelViewer.viewerPath(viewModelId, clientId));
};
