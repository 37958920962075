import { ViewModelContent, ViewModelEntity } from '@view-model/domain/view-model';
import { ModelCascadeRepository } from '@view-model/infrastructure/view-model/cascade/ModelCascadeRepository';
import {
    ConsistencyLinkCollection,
    ConsistencyLinkMapRepository,
    createConsistencyLinkMapRepository,
} from '@view-model/domain/ConsistencyLink';
import { IPositionSetRepository, PositionSet, PositionSetRepository } from '@view-model/models/common/PositionSet';
import { ObjectRepository, RTDBPath } from '@framework/repository';
import { ViewRepository } from '@view-model/infrastructure/view-model/ViewRepository';
import { ViewModelId } from '@schema-common/base';
import { ViewModelJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-models/{viewModelId}/ViewModelJSON';

export class ViewModelCascadeRepository {
    private readonly viewModelRepository: ObjectRepository<ViewModelJSON, ViewModelEntity>;
    private readonly viewRepository: ViewRepository;
    private readonly viewPositionRepository: IPositionSetRepository;
    private readonly consistencyLinkMapRepository: ConsistencyLinkMapRepository;
    private readonly modelCascadeRepository: ModelCascadeRepository;

    constructor(private readonly viewModelId: ViewModelId) {
        this.viewModelRepository = new ObjectRepository(ViewModelEntity, RTDBPath.ViewModel.viewModelPath(viewModelId));
        this.viewRepository = new ViewRepository(viewModelId);

        this.viewPositionRepository = new PositionSetRepository(RTDBPath.View.positionsPath(viewModelId));

        this.consistencyLinkMapRepository = createConsistencyLinkMapRepository(viewModelId);

        this.modelCascadeRepository = new ModelCascadeRepository(viewModelId);
    }

    async saveContent(content: ViewModelContent): Promise<void> {
        const { viewPositions, consistencyLinks, models } = content;

        await content.saveViews(this.viewRepository);
        await this.viewPositionRepository.saveMany(viewPositions);
        await this.modelCascadeRepository.saveContentsCollection(models);
        await this.consistencyLinkMapRepository.saveItems(consistencyLinks);
    }

    async loadContent(): Promise<ViewModelContent | null> {
        const viewModel = await this.viewModelRepository.get();
        if (!viewModel) return null;

        const views = await this.viewRepository.loadCollection();
        const viewPositions = await this.viewPositionRepository.loadAll();
        const links = await this.consistencyLinkMapRepository.get();
        const models = await this.modelCascadeRepository.loadContentsCollection();

        return new ViewModelContent(
            viewModel.name,
            views,
            viewPositions || PositionSet.buildEmpty(),
            links || new ConsistencyLinkCollection(),
            models
        );
    }
}
