import { Random } from '@framework/Random';
import { ModelCommentEntity } from './ModelCommentEntity';
import { ModelCommentAuthor } from './ModelCommentAuthor';
import { Timestamp } from '@framework/Timestamp';
import { ModelCommentThreadJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/model-contents/{modelId}/model-comment-threads/{modelCommentThreadId}/ModelCommentThreadJSON';
import { ModelCommentJSON } from '@schema-common/view-model';
import { ModelCommentId, ModelCommentThreadId } from '@schema-common/base';

export class ModelCommentThread {
    private readonly replyComments: ModelCommentEntity[];
    constructor(
        public readonly id: ModelCommentThreadId,
        private readonly status: ModelCommentThreadJSON['status'],
        private readonly primaryComment: ModelCommentEntity,
        replyComments: ModelCommentEntity[]
    ) {
        // 投稿日付順に並び替える
        this.replyComments = [...replyComments].sort((a, b) => a.compareByPostAt(b));
    }

    static buildNewId(): ModelCommentThreadId {
        return Random.generateRandomID(20, 'MCT');
    }

    static buildNew(author: ModelCommentAuthor, body: string): ModelCommentThread {
        const primaryComment = ModelCommentEntity.buildNew(author, body);
        return new this(this.buildNewId(), 'Open', primaryComment, []);
    }

    static load(dump: ModelCommentThreadJSON): ModelCommentThread {
        const { id, status, primaryComment, replyComments } = dump;
        return new this(
            id,
            status,
            ModelCommentEntity.load(primaryComment),
            (replyComments || []).map((comment) => ModelCommentEntity.load(comment))
        );
    }

    dump(): ModelCommentThreadJSON {
        const { id, status, primaryComment, replyComments } = this;
        return {
            id,
            status,
            primaryComment: primaryComment.dump(),
            replyComments: replyComments.map((comment) => comment.dump()),
        };
    }

    cloneNew(): ModelCommentThread {
        return new ModelCommentThread(
            ModelCommentThread.buildNewId(),
            this.status,
            this.primaryComment.cloneNew(),
            this.replyComments.map((comment) => comment.cloneNew())
        );
    }

    isEqual(other: ModelCommentThread): boolean {
        return (
            this.id === other.id &&
            this.status === other.status &&
            this.primaryComment.isEqual(other.primaryComment) &&
            this.replyComments.length === other.replyComments.length &&
            this.replyComments.every((reply) => {
                const otherReply = other.getReplyComment(reply.id);
                return otherReply && reply.isEqual(otherReply);
            })
        );
    }

    isOpen(): boolean {
        return this.status === 'Open';
    }

    isResolved(): boolean {
        return this.status === 'Resolved';
    }

    getPrimaryComment(): ModelCommentEntity {
        return this.primaryComment;
    }

    getReplyComments(): ModelCommentEntity[] {
        return [...this.replyComments];
    }

    getRegularReplyComments(): ModelCommentEntity[] {
        return this.replyComments.filter((reversedReplyComment) => {
            return reversedReplyComment.isRegularComment();
        });
    }

    addReplyComment(author: ModelCommentAuthor, body: string, type?: ModelCommentJSON['type']): ModelCommentThread {
        const { id, status, primaryComment, replyComments } = this;
        const newComment = ModelCommentEntity.buildNew(author, body, type);
        return new ModelCommentThread(id, status, primaryComment, [...replyComments, newComment]);
    }

    removeReplyComment(commentId: ModelCommentId): ModelCommentThread {
        return ModelCommentThread.load({
            ...this.dump(),
            replyComments: this.replyComments.filter(({ id }) => id !== commentId).map((comment) => comment.dump()),
        });
    }

    getReplyComment(commentId: ModelCommentId): ModelCommentEntity | undefined {
        return this.replyComments.find(({ id }) => id === commentId);
    }

    withStatus(status: ModelCommentThreadJSON['status']): ModelCommentThread {
        const { id, primaryComment, replyComments } = this;
        return new ModelCommentThread(id, status, primaryComment, replyComments);
    }

    withCommentBody(commentId: ModelCommentId, body: string): ModelCommentThread {
        const { primaryComment, replyComments } = this;

        if (primaryComment.id === commentId) {
            return ModelCommentThread.load({
                ...this.dump(),
                primaryComment: primaryComment.withBody(body).dump(),
            });
        }

        return ModelCommentThread.load({
            ...this.dump(),
            replyComments: replyComments
                .map((comment) => (comment.id === commentId ? comment.withBody(body) : comment))
                .map((comment) => comment.dump()),
        });
    }

    compareByLastPostAt(otherThread: ModelCommentThread): number {
        return this.getLastPostAt().compareTo(otherThread.getLastPostAt());
    }

    getLastPostAt(): Timestamp {
        // 返信コメントが1件以上あれば、その最後の要素の投稿日時を返す (返信コメントはコンストラクタで並び替え済み)
        if (this.replyComments.length > 0) {
            return this.replyComments[this.replyComments.length - 1].getPostAt();
        }

        return this.primaryComment.getPostAt();
    }
}
