import { useCallback, useMemo, useState } from 'react';
import { UserIconMenu } from '@framework/ui/elements/TopNavigation';
import { GroupEntity, GroupLicense } from '@group/domain';
import { useUserPublicProfiles } from '@user/PublicProfile';
import { SidebarOpenButton, useSidebarContext } from '@user/Sidebar';
import { GroupEditModal } from './editGroup';
import { GroupMemberEditButton } from './groupMemberEdit';

type Props = {
    currentGroup: GroupEntity;
    currentUserId: string;
    license: GroupLicense | null | undefined;
};

export const HEADER_HEIGHT = 64;

export const Header: React.FC<Props> = ({ currentGroup, currentUserId, license }: Props) => {
    const isAdminRole = currentGroup.isAdminRoleOf(currentUserId);

    const userIds = useMemo(() => currentGroup.members.userIds(), [currentGroup]);
    const userProfiles = useUserPublicProfiles(userIds);

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = useCallback(() => setIsOpen(true), []);
    const handleCloseModal = useCallback(() => setIsOpen(false), []);

    const { isSidebarOpenButtonVisible, toggleSidebar } = useSidebarContext();

    return (
        <div className="border-b-2">
            <div className="flex items-center" style={{ height: HEADER_HEIGHT - 2 }}>
                {isSidebarOpenButtonVisible && (
                    <div className="size-12">
                        <SidebarOpenButton onClick={toggleSidebar} />
                    </div>
                )}
                <div className="relative ml-auto flex items-center justify-items-stretch gap-x-4 p-2">
                    {isAdminRole && userProfiles && (
                        <>
                            <button className="text-brand focus:outline-none" onClick={handleOpenModal}>
                                グループ設定
                            </button>
                            <GroupMemberEditButton userProfiles={userProfiles} groupEntity={currentGroup} />
                        </>
                    )}
                    <UserIconMenu userId={currentUserId} />
                    <GroupEditModal
                        isOpen={isOpen}
                        onClose={handleCloseModal}
                        groupEntity={currentGroup}
                        license={license}
                    />
                </div>
            </div>
        </div>
    );
};
