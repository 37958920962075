import { useCallback, useMemo } from 'react';
import { Point, Rect, Size } from '@view-model/models/common/basic';
import { CalloutRect } from './CalloutRect';
import { DraggableSVGGElement } from '../DraggableSVGGElement';
import { CalloutText } from './CalloutText';

type Props = {
    editing: boolean;
    text: string;
    position: Point;
    size: Size;
    minHeight: number;
    targetRect: Rect;
    tailBaseLineMargin: number;
    tailBaseLineLength: number;
    onDragStart(position: Point): void;
    onDrag(dx: number, dy: number): void;
    onDragEnd(): void;
    onChangeSize(size: Size): void;
    onStartEdit?(text: string): void;
    onEdit?(text: string): void;
    onEndEdit?(text: string): void;
    onBlur?(): void;
    onDblClick?(): void;
};

/**
 * 噴き出しコンポーネント
 * @param editing 編集中かどうか。切り替えることによって編集開始または編集終了する
 * @param text 吹き出しのテキスト
 * @param position 吹き出しの位置（矩形領域の位置）
 * @param size 吹き出しのサイズ（矩形領域のサイズ）
 * @param minHeight 吹き出しの最小高さ
 * @param targetRect 吹き出しが指し示す対象の領域。この矩形に吹き出しのしっぽが接する。
 * @param tailBaseLineMargin 吹き出ししっぽ底辺の矩形端からのマージン
 * @param tailBaseLineLength 吹き出ししっぽ底辺の長さ
 * @param onDragStart
 * @param onDrag
 * @param onDragEnd
 * @param onChangeSize
 * @param onStartEdit
 * @param onEdit
 * @param onEndEdit
 * @param onBlur
 * @param onDblClick
 * @constructor
 */
export const Callout: React.FC<Props> = ({
    editing,
    text,
    position,
    size,
    minHeight,
    targetRect,
    tailBaseLineMargin,
    tailBaseLineLength,
    onDragStart,
    onDrag,
    onDragEnd,
    onChangeSize,
    onStartEdit,
    onEdit,
    onEndEdit,
    onBlur,
    onDblClick,
}: Props) => {
    const tail = useMemo(() => {
        const calloutRect = new CalloutRect(position, size, tailBaseLineMargin, tailBaseLineLength);
        return calloutRect.tailTo(targetRect);
    }, [position, size, tailBaseLineLength, tailBaseLineMargin, targetRect]);

    const handleOnDragStart = useCallback(() => {
        onDragStart(position);
    }, [onDragStart, position]);

    return (
        <>
            {tail && <path className="fill-gray-300" stroke="none" d={tail.toSVGPath()} />}
            <DraggableSVGGElement
                position={position}
                onDrag={onDrag}
                onDragStart={handleOnDragStart}
                onDragEnd={onDragEnd}
            >
                <rect
                    className="fill-gray-300"
                    width={size.width}
                    height={size.height}
                    rx={12}
                    ry={12}
                    stroke="none"
                    cursor="move"
                />
                <CalloutText
                    editing={editing}
                    text={text}
                    size={size}
                    minHeight={minHeight}
                    onChangeSize={onChangeSize}
                    onStartEdit={onStartEdit}
                    onEdit={onEdit}
                    onEndEdit={onEndEdit}
                    onBlur={onBlur}
                    onDblClick={onDblClick}
                />
            </DraggableSVGGElement>
        </>
    );
};
