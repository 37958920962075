import { useCallback, useEffect, useRef, useState } from 'react';
import { Rect } from '@view-model/models/common/basic';
import { MIN_VIEW_HEIGHT, MIN_VIEW_WIDTH } from '../../constants';
import { SideLine } from './SideLine';
import { CornerPointer } from './CornerPointer';
import { ResizingOverlay } from './ResizingOverlay';
import { useViewOperation } from '@view-model/ui/components/View/ViewContext';

const MARGIN = 12; // ビューに対するリサイズ矩形のマージン

type Props = {
    viewRect: Rect;
    onResizeStart(): void;
    onResizeEnd(viewRect: Rect): void;
};

type DragEventAxis = {
    dx: number;
    dy: number;
};

export const ResizableFrame: React.FC<Props> = ({ viewRect, onResizeStart, onResizeEnd }: Props) => {
    const [resizingViewRect, setResizingViewRect] = useState(viewRect);
    const [isDrag, setIsDrag] = useState(false);
    const oldViewRectRef = useRef(viewRect); // リサイズ開始時のビュー矩形を保持
    const newViewRectRef = useRef(viewRect); // リサイズ完了時のビュー矩形を保持
    const viewAdapter = useViewOperation();

    // グリッドへのスナップなど上位のコンポーネントでviewRect props が更新された場合にリサイズ中矩形の状態もリセットする
    // これをしないと、ドラッグで離した時の矩形がそのまま描画されてしまう
    useEffect(() => {
        oldViewRectRef.current = viewRect;
        newViewRectRef.current = viewRect;
        setResizingViewRect(viewRect);
    }, [viewRect]);

    // resizingViewRectを直接handleResizeEnd内でonResizeEndで渡したいが、そうするとhandleResizeEndの依存関係に
    // resizingViewRectのstateが入り、リサイズイベントごとにhandleResizeEndコールバックが更新され、それに応じて
    // 下位コンポーネント更新（D3Dドラッグのリスナー解除、登録）が発生するため、refにresizingViewRectを保存することで
    // handleResizeEndがドラッグの度に更新されないようにする
    useEffect(() => {
        newViewRectRef.current = resizingViewRect;
    }, [resizingViewRect]);

    const handleDragStart = useCallback(() => {
        setIsDrag(true);
        onResizeStart();
    }, [onResizeStart]);

    const handleDragEnd = useCallback(async () => {
        setIsDrag(false);
        await viewAdapter.handleResizingConfirmed(newViewRectRef.current, oldViewRectRef.current, onResizeEnd);
        // 元のviewRectが保持される判定になった場合はviewRectの更新が行われずresizingViewRectがdragEnd時の矩形のままになるので
        // viewRectが保持判定になったときにoldViewRectRefの値でresizingViewRectとnewViewRectRefを更新しておく
        // viewRectが更新された場合は上のuseEffectでresizingViewRectが更新される
        newViewRectRef.current = oldViewRectRef.current;
        setResizingViewRect(oldViewRectRef.current);
    }, [onResizeEnd, viewAdapter]);

    const handleDragTopSide = useCallback(
        ({ dy }: DragEventAxis) => setResizingViewRect((prev) => prev.resizeByMovingTopSide(dy, MIN_VIEW_HEIGHT)),
        []
    );

    const handleDragBottomSide = useCallback(
        ({ dy }: DragEventAxis) => setResizingViewRect((prev) => prev.resizeByMovingBottomSide(dy, MIN_VIEW_HEIGHT)),
        []
    );

    const handleDragLeftSide = useCallback(
        ({ dx }: DragEventAxis) => setResizingViewRect((prev) => prev.resizeByMovingLeftSide(dx, MIN_VIEW_WIDTH)),
        []
    );

    const handleDragRightSide = useCallback(
        ({ dx }: DragEventAxis) => setResizingViewRect((prev) => prev.resizeByMovingRightSide(dx, MIN_VIEW_WIDTH)),
        []
    );

    const handleDragTopLeftCorner = useCallback(
        ({ dx, dy }: DragEventAxis) =>
            setResizingViewRect((prev) => prev.resizeByMovingTopLeftCorner(dx, dy, MIN_VIEW_WIDTH, MIN_VIEW_HEIGHT)),
        []
    );

    const handleDragTopRightCorner = useCallback(
        ({ dx, dy }: DragEventAxis) =>
            setResizingViewRect((prev) => prev.resizeByMovingTopRightCorner(dx, dy, MIN_VIEW_WIDTH, MIN_VIEW_HEIGHT)),
        []
    );

    const handleDragBottomLeftCorner = useCallback(
        ({ dx, dy }: DragEventAxis) =>
            setResizingViewRect((prev) => prev.resizeByMovingBottomLeftCorner(dx, dy, MIN_VIEW_WIDTH, MIN_VIEW_HEIGHT)),
        []
    );

    const handleDragBottomRightCorner = useCallback(
        ({ dx, dy }: DragEventAxis) =>
            setResizingViewRect((prev) =>
                prev.resizeByMovingBottomRightCorner(dx, dy, MIN_VIEW_WIDTH, MIN_VIEW_HEIGHT)
            ),
        []
    );

    const { left, top, right, bottom } = resizingViewRect.applyMarginKeepingCenter(MARGIN).getLTRB();

    return (
        <>
            {/* Left */}
            <SideLine
                x1={left}
                y1={top}
                x2={left}
                y2={bottom}
                onDragStart={handleDragStart}
                onDrag={handleDragLeftSide}
                onDragEnd={handleDragEnd}
            />
            {/* Right */}
            <SideLine
                x1={right}
                y1={top}
                x2={right}
                y2={bottom}
                onDragStart={handleDragStart}
                onDrag={handleDragRightSide}
                onDragEnd={handleDragEnd}
            />
            {/* Top */}
            <SideLine
                x1={left}
                y1={top}
                x2={right}
                y2={top}
                onDragStart={handleDragStart}
                onDrag={handleDragTopSide}
                onDragEnd={handleDragEnd}
            />
            {/* Bottom */}
            <SideLine
                x1={left}
                y1={bottom}
                x2={right}
                y2={bottom}
                onDragStart={handleDragStart}
                onDrag={handleDragBottomSide}
                onDragEnd={handleDragEnd}
            />
            {/* TopLeft */}
            <CornerPointer
                cx={left}
                cy={top}
                cursor="nwse-resize"
                onDragStart={handleDragStart}
                onDrag={handleDragTopLeftCorner}
                onDragEnd={handleDragEnd}
            />
            {/* TopRight */}
            <CornerPointer
                cx={right}
                cy={top}
                cursor="nesw-resize"
                onDragStart={handleDragStart}
                onDrag={handleDragTopRightCorner}
                onDragEnd={handleDragEnd}
            />
            {/* BottomLeft */}
            <CornerPointer
                cx={left}
                cy={bottom}
                cursor="nesw-resize"
                onDragStart={handleDragStart}
                onDrag={handleDragBottomLeftCorner}
                onDragEnd={handleDragEnd}
            />
            {/* BottomRight */}
            <CornerPointer
                cx={right}
                cy={bottom}
                cursor="nwse-resize"
                onDragStart={handleDragStart}
                onDrag={handleDragBottomRightCorner}
                onDragEnd={handleDragEnd}
            />
            {isDrag && <ResizingOverlay resizingViewRect={resizingViewRect} />}
        </>
    );
};
