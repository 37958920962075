import { FontSize } from '@model-framework/text';

const StickyZoneFontSizeList = [FontSize.S, FontSize.M, FontSize.L] as const;
export type StickyZoneFontSize = (typeof StickyZoneFontSizeList)[number];

export namespace StickyZoneFontSize {
    export const S = FontSize.S;
    export const M = FontSize.M;
    export const L = FontSize.L;

    export function getFontSize(size: StickyZoneFontSize): string {
        if (size == StickyZoneFontSize.S) {
            return '24px';
        }
        if (size == StickyZoneFontSize.M) {
            return '42px';
        }
        if (size == StickyZoneFontSize.L) {
            return '48px';
        }

        return '42px';
    }

    export function values(): Readonly<StickyZoneFontSize[]> {
        return StickyZoneFontSizeList;
    }

    export function fromString(value: string): StickyZoneFontSize | undefined {
        return StickyZoneFontSizeList.find((fontSize) => fontSize.toString() === value);
    }

    export function load(fontSize: string): StickyZoneFontSize {
        // 未知の値の場合には L に fallback する
        return StickyZoneFontSize.fromString(fontSize) || StickyZoneFontSize.L;
    }

    export function dump(fontSize: StickyZoneFontSize): string {
        return fontSize.toString();
    }

    export function include(fontSize: FontSize): boolean {
        return StickyZoneFontSizeList.includes(fontSize);
    }
}
