import { StickyNodeDescription, StickyNodeDescriptionEditLockKey, StickyNodeDescriptionRepository } from '../domain';
import { EditingUser, EditingUserRepository } from '@model-framework/text/editing-user';
import { CommandManager, UpdateCommand } from '@model-framework/command';
import { NodeId } from '@schema-common/base';

export class StickyNodeDescriptionOperation {
    constructor(
        private readonly repository: StickyNodeDescriptionRepository,
        private readonly editingUser: EditingUser
    ) {}

    /**
     * 付箋の補足説明の表示・非表示切り替え。存在しない場合は新規作成。
     */
    static toggleNodeDescription({
        nodeId,
        commandManager,
        editingUserRepository,
        repository,
        editingUser,
    }: {
        repository: StickyNodeDescriptionRepository;
        editingUser: EditingUser;
        nodeId: NodeId;
        commandManager: CommandManager;
        editingUserRepository: EditingUserRepository;
    }): void {
        repository.get().then((description: StickyNodeDescription | null) => {
            if (!description) {
                // 存在しない場合は新規作成。ここではエンティティを永続化するだけでUndoスタックには詰まない。
                // テキストが空文字のため、有効なテキストがUndo/Redoで復元されるように、初回の編集時にUndoスタックを詰む。
                StickyNodeDescriptionOperation.createNewDescription({
                    nodeId,
                    editingUserRepository,
                    editingUser,
                    repository,
                });
                return;
            }

            const command = new UpdateCommand(description, description.toggleVisibility(), repository);
            commandManager.execute(command);
        });
    }

    private static createNewDescription({
        nodeId,
        editingUserRepository,
        editingUser,
        repository,
    }: {
        nodeId: NodeId;
        editingUserRepository: EditingUserRepository;
        editingUser: EditingUser;
        repository: StickyNodeDescriptionRepository;
    }): void {
        const newDescription = StickyNodeDescription.build(nodeId);
        repository.save(newDescription).then();

        // 新規作成時は編集開始する
        const editLockKey = StickyNodeDescriptionEditLockKey.fromNodeId(nodeId);
        editingUserRepository.save(editLockKey, editingUser).then();
    }
}
