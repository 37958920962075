import { getClientId } from '@framework/app';
import { useHandler } from '@framework/hooks/useHandler';
import { ViewModelOperationLogSender } from '@model-framework/action-log';
import { useCommandManager } from '@model-framework/command';
import { HyperLinkShow, HyperLinkViewModelIcon } from '@model-framework/hyperlink';
import { RectShape } from '@model-framework/shape';
import { FontSize } from '@model-framework/text';
import { EditingUser, EditingUserIconView, useOtherUserEditingToast } from '@model-framework/text/editing-user';
import { GroupId, ModelId, NodeId, ViewModelId } from '@schema-common/base';
import { ViewModelURL } from '@user/pages/urls';
import { UserPublicProfile } from '@user/PublicProfile';
import { MLAPIOperator } from '@view-model/application/ml-api/MLAPIOperator';
import { MLAPIPayload } from '@view-model/application/ml-api/MLAPIPayload';
import { NodeKey } from '@view-model/domain/key';
import { ViewEntity } from '@view-model/domain/view';
import { Point } from '@view-model/models/common/basic';
import { ThemeColor } from '@view-model/models/common/color';
import { LinkCreator } from '@view-model/models/common/components/LinkCreator';
import { Position } from '@view-model/models/common/types/ui';
import { CreatedUserView, DraggableSVGGElement } from '@view-model/models/framework/ui';
import { memo, useEffect, useMemo, useState } from 'react';
import { NodeOperation } from './adapter';
import { LinkableTargetContainer, NodeMenu, NodeOtherUserSelected, NodeSelected, NodeTextarea } from './components';
import { NodeFontSize, NodeName, NodeStyle, StickyNode } from './domain';
import { NodeReactionStatusView } from '../NodeReaction/components';
import { useNodeReaction } from '../NodeReaction';
import { useNodeEditingUser } from '../editing-users/useNodeEditingUser';
import { useOtherUserIsSelected } from '../client-selected-items/useOtherUserIsSelected';

// NOTE: Propsを変えたら、propsAreEqual()も変えること
type Props = {
    readonly: boolean;
    viewModelId: ViewModelId;
    view: ViewEntity;
    modelId: ModelId;
    node: StickyNode;
    position: Point;
    ownerGroupId: GroupId;
    currentUserProfile: UserPublicProfile;
    isSelected: boolean;
    isMultiSelected: boolean;
    isLinkableTarget: boolean;
    nodeShape: RectShape;
    baseColor: string;
    showMenu: boolean;
    showNodeCreatedUser: boolean;
    logSender: ViewModelOperationLogSender;
    startEditOnMount: boolean;
    onClick(id: NodeId): void;
    onDragStart(id: NodeId): void;
    onDrag(dx: number, dy: number): void;
    onDragEnd(): void;
    onEditStart(id: NodeId): void;
    onNameEditStartedOnMount(): void;
    onLinkerStart(key: NodeKey, startPosition: Position): void;
    onLinkerMove(currentPosition: Position): void;
    onLinkerEnd(currentPosition: Position): void;
    onClickViewModelLink(e: React.MouseEvent): void;
    onGroupSelectedNode(): void;
    onDelete(): void;
    onFontSizeSelected(fontSize: FontSize): void;
    onThemeColorSelected(color: ThemeColor): void;
    onAnalysisStart(): void;
    onAnalysisSuccess(payload: MLAPIPayload): void;
    onAnalysisFailure(): void;
};

type State = {
    name: NodeName;
    previousName: NodeName;
    editingName: boolean;
};

const StickyNodeViewFn = (props: Props) => {
    const commandManager = useCommandManager();

    const [state, setState] = useState<State>({
        name: props.node.name,
        previousName: props.node.name,
        editingName: false,
    });

    const user = useMemo(() => {
        return EditingUser.buildFromProfile(props.currentUserProfile, getClientId());
    }, [props.currentUserProfile]);

    const editingUser = useNodeEditingUser(props.node.key);

    const notifyOtherUserEditingToast = useOtherUserEditingToast();

    const otherUserEditing = !!(editingUser && !editingUser.isEqual(user));

    const otherUserSelected = useOtherUserIsSelected({
        nodeId: props.node.id,
        clientId: getClientId(),
        modelId: props.modelId,
    });

    const nodeOperation = useMemo(
        () =>
            new NodeOperation(
                props.viewModelId,
                props.modelId,
                props.node.key,
                commandManager,
                props.currentUserProfile
            ),
        [props.viewModelId, props.modelId, props.node.key, commandManager, props.currentUserProfile]
    );

    const isViewModelUpdatable = !props.readonly;

    const handleNameStartEdit = useHandler((): void => {
        if (!isViewModelUpdatable) return;
        props.onEditStart(props.node.id);

        if (otherUserEditing) {
            notifyOtherUserEditingToast();
            return;
        }

        setState((prev) => ({
            ...prev,
            name: props.node.name,
            previousName: props.node.name,
            editingName: true,
        }));

        nodeOperation.saveMyEditingUser();
    });

    useEffect(() => {
        if (props.startEditOnMount) {
            handleNameStartEdit();
            props.onNameEditStartedOnMount();
        }
    });

    const updateStyle = useHandler((style: NodeStyle): void => {
        const currentStyle = props.node.style;
        nodeOperation.handleUpdateStyle(style, currentStyle);
    });

    const handleChangeFontSize = useHandler((newFontSize: FontSize): void => {
        const newNodeFontSize = NodeFontSize.fromFontSize(newFontSize);
        updateStyle(props.node.style.withFontSize(newNodeFontSize));
    });

    const handleNameChange = useHandler((name: NodeName): Promise<void> => {
        setState((prev) => ({ ...prev, name }));
        return nodeOperation.handleEditingNameChanged(name) ?? Promise.resolve();
    });

    const handleNameEndEdit = useHandler((name: NodeName): void => {
        setState((prev) => ({ ...prev, editingName: false }));
        nodeOperation.clearMyEditingUser();
        nodeOperation.confirmEditName(name, state.previousName).then();
    });

    const handleNodeDragStart = useHandler((): void => {
        if (props.readonly) {
            return;
        }
        props.onDragStart(props.node.id);
    });

    const handleNodeDrag = useHandler((dx: number, dy: number): void => {
        props.onDrag(dx, dy);
    });

    const handleNodeDragEnd = useHandler((): void => {
        props.onDragEnd();
    });

    const handleNodeClick = useHandler((): void => {
        props.onClick(props.node.id);
    });

    const shouldShowCreatedUser = props.showNodeCreatedUser && props.node.createdUserKey !== null;

    const handleLinkerStart = useHandler((startPosition: Position): void => {
        const { x, y } = startPosition;
        props.onLinkerStart(props.node.key, props.node.position.add(x, y));
    });

    const handleLinkerMove = useHandler((currentPosition: Position): void => {
        const { x, y } = currentPosition;
        props.onLinkerMove(props.node.position.add(x, y));
    });

    const handleLinkerEnd = useHandler((currentPosition: Position): void => {
        const { x, y } = currentPosition;
        props.onLinkerEnd(props.node.position.add(x, y));
    });

    const handleToggleNodeDescription = useHandler((): void => {
        nodeOperation.toggleNodeDescription();
    });

    const handleSaveHyperLink = useHandler((url: string | null): void => {
        nodeOperation.handleEditingURLChanged(url, props.node.url);
    });

    const handleAnalysisSelected = useHandler((): void => {
        MLAPIOperator.chatGPTSimilarNode(props.viewModelId, props.view, props.node, props.logSender).then(
            async (payload) => {
                if (payload === null) {
                    props.onAnalysisFailure();
                } else {
                    props.onAnalysisSuccess(payload);
                }
            }
        );

        props.onAnalysisStart();
    });

    const { width, height } = props.node.size();
    const baseMargin = 8;
    const baseRectShape = props.nodeShape.resize({
        dWidth: baseMargin * 2,
        dHeight: baseMargin * 2,
    });

    const nodeMenuPosition = props.position.addXY(width / 2 + 32, 0);

    const { fillColor, borderColor } = props.node.style.getStyles();

    const { reaction, toggleReaction } = useNodeReaction(props.viewModelId, props.modelId, props.node.id);

    return (
        <g>
            <DraggableSVGGElement
                position={props.position}
                onDragMoveStarted={handleNodeDragStart}
                onDragMoveEnded={handleNodeDragEnd}
                onDrag={handleNodeDrag}
                onClick={handleNodeClick}
            >
                {/* ゾーンに含まれているか否かを区別するための土台の色 (ゾーンに含まれない時にはキャンバスと同じ白色) */}
                <g transform={`translate(${-baseMargin},${-baseMargin})`}>
                    {baseRectShape.render({ fill: props.baseColor, cursor: 'move' })}
                </g>

                <g>
                    {props.nodeShape.render({
                        fill: fillColor,
                        stroke: borderColor,
                        strokeWidth: 2,
                    })}
                </g>
                {isViewModelUpdatable && props.showMenu && (
                    <LinkCreator
                        elementSize={{ width, height }}
                        onLinkerStart={handleLinkerStart}
                        onLinkerMove={handleLinkerMove}
                        onLinkerEnd={handleLinkerEnd}
                    />
                )}
                <NodeTextarea
                    width={width}
                    height={height}
                    isMyEditing={state.editingName}
                    otherUserEditing={otherUserEditing}
                    name={state.editingName ? state.name : props.node.name}
                    fontSize={props.node.style.fontSize}
                    themeColor={props.node.style.themeColor}
                    onStartEdit={handleNameStartEdit}
                    onEndEdit={handleNameEndEdit}
                    onChange={handleNameChange}
                    onChangeFontSize={handleChangeFontSize}
                    isHyperLink={!!props.node.url}
                />
                {
                    // 自分が対象の付箋を選択している場合に枠線で囲む
                    props.isSelected || props.isMultiSelected ? (
                        <NodeSelected shape={props.nodeShape} margin={6} />
                    ) : // 他のユーザが選択している場合にも枠線で囲む
                    otherUserSelected ? (
                        <NodeOtherUserSelected shape={props.nodeShape} margin={12} />
                    ) : null
                }
                {editingUser && (
                    <EditingUserIconView
                        currentEditingUser={editingUser}
                        position={new Point(0, height)}
                        iconSize={48}
                    />
                )}
                {shouldShowCreatedUser && props.node.createdUserId && !editingUser && (
                    <CreatedUserView userId={props.node.createdUserId} position={new Point(0, height)} />
                )}

                {props.isLinkableTarget && <LinkableTargetContainer shape={props.nodeShape} margin={12} />}

                {/* 選択時のハイパーリンク表示 */}
                {props.isSelected && props.node.url && (
                    <HyperLinkShow
                        x={width + 25}
                        y={height}
                        url={props.node.url}
                        onClickViewModelLink={props.onClickViewModelLink}
                    />
                )}

                {/* 付箋左上のアイコン表示 */}
                {props.node.url && ViewModelURL.fromURLString(props.node.url) && <HyperLinkViewModelIcon />}
            </DraggableSVGGElement>

            {isViewModelUpdatable && props.showMenu && (
                <NodeMenu
                    groupId={props.ownerGroupId}
                    currentStyle={props.node.style}
                    offset={nodeMenuPosition}
                    textEditing={state.editingName}
                    url={props.node.url}
                    onDeleteNode={props.onDelete}
                    onGroupSelectedNode={props.onGroupSelectedNode}
                    onToggleNodeDescription={handleToggleNodeDescription}
                    onSaveHyperLink={handleSaveHyperLink}
                    onFontSizeSelected={props.onFontSizeSelected}
                    onThemeColorSelected={props.onThemeColorSelected}
                    onAnalysisSelected={handleAnalysisSelected}
                    reaction={reaction}
                    toggleReaction={toggleReaction}
                />
            )}

            <NodeReactionStatusView
                reaction={reaction}
                toggleReaction={toggleReaction}
                nodeRect={props.node.getRect()}
                iconSize={40}
            />
        </g>
    );
};

function propsAreEqual(prevProps: Props, nextProps: Props): boolean {
    return (
        prevProps.readonly === nextProps.readonly &&
        prevProps.viewModelId === nextProps.viewModelId &&
        prevProps.view.isEqual(nextProps.view) &&
        prevProps.modelId === nextProps.modelId &&
        prevProps.node.isEqual(nextProps.node) &&
        prevProps.position.isEqual(nextProps.position) &&
        prevProps.ownerGroupId === nextProps.ownerGroupId &&
        prevProps.currentUserProfile === nextProps.currentUserProfile &&
        prevProps.isSelected === nextProps.isSelected &&
        prevProps.isMultiSelected === nextProps.isMultiSelected &&
        prevProps.isLinkableTarget === nextProps.isLinkableTarget &&
        prevProps.nodeShape.isEqual(nextProps.nodeShape) &&
        prevProps.baseColor === nextProps.baseColor &&
        prevProps.showMenu === nextProps.showMenu &&
        prevProps.showNodeCreatedUser === nextProps.showNodeCreatedUser &&
        prevProps.logSender === nextProps.logSender &&
        prevProps.startEditOnMount === nextProps.startEditOnMount &&
        prevProps.onClick === nextProps.onClick &&
        prevProps.onDragStart === nextProps.onDragStart &&
        prevProps.onDrag === nextProps.onDrag &&
        prevProps.onDragEnd === nextProps.onDragEnd &&
        prevProps.onEditStart === nextProps.onEditStart &&
        prevProps.onNameEditStartedOnMount === nextProps.onNameEditStartedOnMount &&
        prevProps.onLinkerStart === nextProps.onLinkerStart &&
        prevProps.onLinkerMove === nextProps.onLinkerMove &&
        prevProps.onLinkerEnd === nextProps.onLinkerEnd &&
        prevProps.onClickViewModelLink === nextProps.onClickViewModelLink &&
        prevProps.onGroupSelectedNode === nextProps.onGroupSelectedNode &&
        prevProps.onDelete === nextProps.onDelete &&
        prevProps.onFontSizeSelected === nextProps.onFontSizeSelected &&
        prevProps.onThemeColorSelected === nextProps.onThemeColorSelected &&
        prevProps.onAnalysisStart === nextProps.onAnalysisStart &&
        prevProps.onAnalysisSuccess === nextProps.onAnalysisSuccess &&
        prevProps.onAnalysisFailure === nextProps.onAnalysisFailure
    );
}

export const StickyNodeView = memo(StickyNodeViewFn, propsAreEqual);
