import { useCallback, useEffect, useState } from 'react';
import { TopNavigation, TOP_NAVIGATION_HEIGHT } from '@framework/ui/elements/TopNavigation';
import { WorkspaceEditModal } from './internal/WorkspaceEditModal';
import { WorkspaceDeleteConfirmModal } from './internal/WorkspaceDeleteConfirmModal';
import { WorkspaceEditButton } from './internal/WorkspaceEditButton';
import { MemberSummary } from '@user/pages/WorkspacePage/internal/MemberSummary';
import { Description } from '@user/pages/WorkspacePage/internal/Description';
import { PageTitleManager } from '@user/pages/PageTitle/PageTitleManager';
import { ViewModelRootFolder } from '@workspace/view-model-folder/ui/ViewModelRootFolder';
import { Workspace } from '@workspace/domain/workspace/Workspace';
import { usePageViewLogger } from '@framework/action-log';
import { ShareSetting } from './internal/ShareSetting';
import { SidebarOpenButton, useSidebarContext } from '@user/Sidebar';
import { WorkspaceMemberRole } from '@workspace/domain/workspace';
import { RTDBPath, usePrimitiveValueListener } from '@framework/repository';
import { WorkspaceInternalPublicMemberRoleJSON } from '@schema-common/workspace';
import { WorkspacePublicStatusIcon } from '@workspace/ui';
import { useCurrentUserId } from '@framework/auth';

const PersonalWorkspaceDescription: React.FC = () => {
    return (
        <div className="flex w-full flex-col">
            <div className="mb-1 flex flex-row items-center justify-between border-b border-gray-500">
                <div className="text-2xl font-bold">公開設定</div>
            </div>

            <div>
                <div className="pt-1 font-bold">パーソナルワークスペースです</div>
                <div className="pt-1 text-sm">
                    パーソナルワークスペースは、自分だけがアクセスできる個人のワークスペースです。
                    <br />
                    パーソナルワークスペース内のビューモデルは、自分だけが閲覧・変更でき、グループ内や外部に共有することはできません。
                </div>
            </div>
        </div>
    );
};

const useWorkspacePageView = (workspace: Workspace | null): void => {
    const pageViewLogger = usePageViewLogger('WorkspacePage');

    useEffect(() => {
        if (!workspace) return;

        pageViewLogger.landing({
            workspaceId: workspace.id,
            workspaceName: workspace.name.value,
        });
        return () => pageViewLogger.leaving();
    }, [workspace, pageViewLogger]);
};

type ModalTarget = 'Edit' | 'Delete';

type Props = {
    workspace: Workspace;
};

export const WorkspacePage: React.FC<Props> = ({ workspace }: Props) => {
    useWorkspacePageView(workspace);

    const { isSidebarOpenButtonVisible, toggleSidebar } = useSidebarContext();

    useEffect(() => {
        const pageTitleManager = new PageTitleManager();
        pageTitleManager.setWorkspacePageTitle(workspace.name);
        return () => {
            pageTitleManager.setDefaultPageTitle();
        };
    }, [workspace]);

    const currentUserId = useCurrentUserId();
    const [internalPublicSetting] = usePrimitiveValueListener<WorkspaceInternalPublicMemberRoleJSON>(
        RTDBPath.Group.groupContentInternalPublicWorkspacePath(workspace.ownerGroupId, workspace.id)
    );

    const isEditable = !!(currentUserId && workspace.getMemberRoleOf(currentUserId) === WorkspaceMemberRole.admin);

    const [modalTarget, setModalTarget] = useState<ModalTarget | null>(null);

    const showEditModal = useCallback(() => setModalTarget('Edit'), []);
    const showDeleteModal = useCallback(() => setModalTarget('Delete'), []);
    const closeModal = useCallback(() => setModalTarget(null), []);

    return (
        <div className="block h-screen w-full overflow-y-auto">
            <TopNavigation>
                {isSidebarOpenButtonVisible && (
                    <div className="size-10">
                        <SidebarOpenButton onClick={toggleSidebar} />
                    </div>
                )}
            </TopNavigation>
            <div
                className="overflow-y-auto px-10 pb-32 pt-3"
                style={{ height: `calc(100vh - ${TOP_NAVIGATION_HEIGHT}px)` }}
            >
                <div className="my-2 flex w-full items-center justify-between border-b-2 p-1">
                    <div className="flex flex-row items-center justify-start" style={{ width: 'calc(100% - 8rem)' }}>
                        {workspace.imageUrl && (
                            <>
                                <div className="inline-block size-8 overflow-hidden rounded-full">
                                    <img
                                        className="size-full object-cover"
                                        src={workspace.imageUrl}
                                        alt={workspace.name.value}
                                    />
                                </div>
                                <div className="pr-2" />
                            </>
                        )}
                        <div className="flex justify-start truncate text-3xl font-bold">{workspace.name.value}</div>
                        <div className="flex px-2 text-2xl">
                            <WorkspacePublicStatusIcon
                                isPublicSpace={workspace.isPublicSpace}
                                isPersonal={workspace.isPersonal}
                                internalPublicSetting={internalPublicSetting}
                            />
                        </div>
                    </div>
                    {isEditable && currentUserId && (
                        <div className="flex items-end justify-end">
                            <WorkspaceEditButton onEdit={showEditModal} onDelete={showDeleteModal} />

                            {modalTarget === 'Edit' && (
                                <WorkspaceEditModal isOpen={true} onClose={closeModal} workspace={workspace} />
                            )}

                            {modalTarget === 'Delete' && (
                                <WorkspaceDeleteConfirmModal
                                    isOpen={true}
                                    onClose={closeModal}
                                    workspace={workspace}
                                    shouldMoveToGroupPage={true}
                                />
                            )}
                        </div>
                    )}
                </div>

                <div className="flex w-full flex-row justify-between py-4">
                    <div className="flex w-3/4 flex-col">
                        <ViewModelRootFolder currentUserId={currentUserId} workspace={workspace} />
                    </div>
                    <div className="flex w-1/4 flex-col">
                        {workspace.isPersonal ? (
                            <PersonalWorkspaceDescription />
                        ) : (
                            <>
                                <Description workspace={workspace} isEditable={isEditable} />
                                <div className="pt-4" />
                                <ShareSetting
                                    workspace={workspace}
                                    internalPublicSetting={internalPublicSetting}
                                    isEditable={isEditable}
                                />
                                <div className="pt-4" />
                                <MemberSummary workspace={workspace} isEditable={isEditable} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
