import { useCallback } from 'react';
import { UserIcon } from '@framework/ui/atoms/UserIcon';
import { useGroupMemberInvitations } from './useGroupMemberInvitations';
import {
    GroupMemberInvitation,
    GroupMemberRole,
    GroupMemberInvitationStatus,
    GroupMemberInvitationOperation,
} from '@group/domain';
import { Loading } from '@framework/ui/atoms/Loading';
import { toast } from 'react-hot-toast';
import { usePopupRef } from '@framework/hooks';
import { classNames } from '@framework/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';

type Props = {
    groupId: string;
};

export const MemberInvitationList: React.FC<Props> = ({ groupId }: Props) => {
    const invitations = useGroupMemberInvitations(groupId);

    const handleDeactivate = useCallback(async (invitation: GroupMemberInvitation) => {
        if (await GroupMemberInvitationOperation.deactivate(invitation)) {
            toast.success('招待を無効化しました');
        } else {
            toast.error('招待の無効化に失敗しました');
        }
    }, []);

    if (!invitations) return <Loading />;

    return (
        <table className="table-fixed border text-sm">
            <thead>
                <tr>
                    <th className="sticky top-0 w-5/12 bg-white px-4 py-2">送付先</th>
                    <th className="sticky top-0 w-1/12 bg-white py-2">招待者</th>
                    <th className="sticky top-0 w-2/12 bg-white py-2">ロール</th>
                    <th className="sticky top-0 w-2/12 bg-white py-2">状態</th>
                    <th className="sticky top-0 w-1/12 bg-white py-2"></th>
                </tr>
            </thead>
            <tbody>
                {invitations.map((invitation) => (
                    <Item key={invitation.id} memberInvitation={invitation} onDeactivate={handleDeactivate} />
                ))}
            </tbody>
        </table>
    );
};

type ItemProps = {
    memberInvitation: GroupMemberInvitation;
    onDeactivate(invitation: GroupMemberInvitation): void;
};

const Item: React.FC<ItemProps> = ({ memberInvitation, onDeactivate }: ItemProps) => {
    const { invitation, senderUser } = memberInvitation;
    const { isOpen, popupRef, handleClose, handleOpen } = usePopupRef<HTMLDivElement>(false);

    const handleDeactivate = useCallback(() => {
        onDeactivate(memberInvitation);
        handleClose();
    }, [onDeactivate, memberInvitation, handleClose]);

    return (
        <tr className="relative text-sm">
            <td className="bg-gray-200 px-2 py-1">
                {invitation.email}
                <br />
                {memberInvitation.createdAt.fromNow()}
            </td>
            <td className="bg-gray-200 px-2 py-1">
                <div className="flex justify-center">
                    <UserIcon
                        name={senderUser.name}
                        id={senderUser.userId}
                        imageUrl={senderUser.imageUrl}
                        size="medium"
                    />
                </div>
            </td>
            <td className="bg-gray-200 px-2 py-1 text-center">
                {GroupMemberRole.getLocalString(invitation.memberRole)}
            </td>
            <td className="bg-gray-200 px-2 py-1 text-center">
                {GroupMemberInvitationStatus.getLocalString(memberInvitation.status)}
            </td>
            <td className="bg-gray-200 px-2 py-1 text-center">
                {memberInvitation.isPending() && (
                    <>
                        <button
                            className="size-6 rounded-full border border-gray-500 text-gray-700"
                            onClick={handleOpen}
                        >
                            <FontAwesomeIcon icon={faEllipsisH} />
                        </button>
                        <div
                            className={classNames('absolute right-0 z-10 bg-white', isOpen ? 'block' : 'hidden')}
                            ref={popupRef}
                        >
                            <button className="px-4 py-2" onClick={handleDeactivate}>
                                招待を無効化
                            </button>
                        </div>
                    </>
                )}
            </td>
        </tr>
    );
};
