import { LinkKey } from '@view-model/domain/key';
import { LinkEntity } from './LinkEntity';
import { LinkStyle } from './vo';
import { LinkJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/model-contents/{modelId}/links/{linkId}/LinkJSON';
import { LinkId, ModelId, ViewModelId } from '@schema-common/base';
import { RefBuilder, Reference, RTDBPath } from '@framework/repository';
import { ClientSelectedItemRepository } from '@view-model/models/framework/client-selected-item';

export class LinkRepository {
    private readonly clientSelectedItemRepository: ClientSelectedItemRepository;

    public constructor(
        private readonly viewModelId: ViewModelId,
        private readonly modelId: ModelId
    ) {
        this.clientSelectedItemRepository = new ClientSelectedItemRepository(viewModelId, modelId);
    }

    private linksRef(): Reference {
        return RefBuilder.ref(RTDBPath.Link.linksPath(this.viewModelId, this.modelId));
    }

    private linkRef(linkId: LinkId): Reference {
        return RefBuilder.ref(RTDBPath.Link.linkPath(this.viewModelId, this.modelId, linkId));
    }

    public saveLink(link: LinkEntity): Promise<LinkEntity | null> {
        return this.saveLinks([link]).then((links) => links[0]);
    }

    public saveLinks(links: LinkEntity[]): Promise<LinkEntity[]> {
        const updates: Record<string, LinkJSON> = {};
        links.forEach((link) => {
            updates[`${link.key.id}`] = link.dump();
        });

        return this.linksRef()
            .update(updates)
            .then(() => links);
    }

    public async loadLinks(): Promise<LinkEntity[]> {
        const ref = this.linksRef();
        const snapshot = await ref.once('value');
        const links = (snapshot.val() as Record<string, LinkJSON>) || {};

        return Object.values(links).map((content) => LinkEntity.load(content));
    }

    public deleteLink(key: LinkKey): Promise<void> {
        return this.deleteLinks([key]);
    }

    public async deleteLinks(keys: LinkKey[]): Promise<void> {
        if (keys.length === 0) return;
        const ref = this.linksRef();
        const updates: Record<string, null> = {};
        keys.forEach((key) => {
            updates[`${key.id}`] = null;
        });
        await ref.update(updates);

        const itemIds = keys.map((key) => key.id);
        await this.clientSelectedItemRepository.deleteAllByItemIds(itemIds);
    }

    async saveStyles(styles: Record<LinkId, LinkStyle>): Promise<void> {
        if (Object.keys(styles).length === 0) return;

        const updates: Record<string, LinkJSON['style']> = {};
        Object.entries(styles).forEach(([id, style]) => {
            updates[`${id}/style`] = style.dump();
        });

        const ref = this.linksRef();
        await ref.update(updates);
    }

    async getStyle(id: LinkId): Promise<LinkStyle | null> {
        const ref = this.linkRef(id).child('style');

        const snapshot = await ref.once('value');
        const j = snapshot.val() as LinkJSON['style'];
        if (!j) return null;

        return LinkStyle.load(j);
    }

    public onAddLink(callback: (link: LinkEntity) => void): void {
        this.linksRef().on('child_added', (snapshot) => {
            const value = snapshot.val() as LinkJSON;
            const link = LinkEntity.load(value);
            callback(link);
        });
    }

    public offAddLink(): void {
        this.linksRef().off('child_added');
    }

    public onDeleteLink(callback: (key: LinkKey) => void): void {
        this.linksRef().on('child_removed', (snapshot) => {
            const value = snapshot.val() as LinkJSON;
            if (value?.key) {
                callback(new LinkKey(value.key));
            }
        });
    }

    public offDeleteLink(): void {
        this.linksRef().off('child_removed');
    }

    onUpdateLink(linkId: LinkId, callback: (link: LinkEntity) => void): void {
        this.linkRef(linkId).on('value', (snapshot) => {
            const value = snapshot.val() as LinkJSON | null;
            if (!value) return;

            const link = LinkEntity.load(value);
            callback(link);
        });
    }

    offUpdateLink(linkId: LinkId): void {
        this.linkRef(linkId).off('value');
    }
}
