import { useState } from 'react';
import { Resizing, StickyZoneResizer } from '../components';
import { StickyZone, StickyZoneSize } from '../domain';
import { useStickyZoneContext } from '../adapter';
import { Point, Size } from '@view-model/models/common/basic';
import { ModelLayout } from '@view-model/models/sticky/layout';

type Props = {
    stickyZone: StickyZone;
    position: Point;
};

export const StickyZoneResizerContainer: React.FC<Props> = ({ stickyZone, position }: Props) => {
    const [previousSize, setPreviousSize] = useState<StickyZoneSize | null>(null);
    const [previousPosition, setPreviousPosition] = useState<Point | null>(null);

    const zoneAdapter = useStickyZoneContext();

    function buildResizeObject(
        previousPosition: Point,
        previousSize: StickyZoneSize,
        { dTop, dRight, dBottom, dLeft }: Resizing,
        withSnap: boolean
    ): { size: StickyZoneSize; position: Point } {
        const { x, y } = previousPosition.dump();
        const { width, height } = previousSize.dump();

        const [x0, y0] = [x + dLeft, y + dTop];
        const leftTop = withSnap ? ModelLayout.snapPosition({ x: x0, y: y0 }) : { x: x0, y: y0 };

        const [x1, y1] = [leftTop.x + width + dRight - dLeft, leftTop.y + height + dBottom - dTop];
        const rightBottom = withSnap ? ModelLayout.snapPosition({ x: x1, y: y1 }) : { x: x1, y: y1 };

        const size = new StickyZoneSize(rightBottom.x - leftTop.x, rightBottom.y - leftTop.y);
        const position = Point.load(leftTop);
        return { size, position };
    }

    function handleResizeStart() {
        setPreviousPosition(position);
        setPreviousSize(stickyZone.size);
    }

    function handleResize(position: { x: number; y: number }, size: { width: number; height: number }) {
        if (!previousPosition) return;

        // リサイズ途中の状態を同期する
        zoneAdapter.handleResize(Point.load(position).add(previousPosition), Size.load(size));
    }

    function handleResizeEnd(delta: Resizing) {
        if (!previousPosition || !previousSize) return;

        const { size, position } = buildResizeObject(previousPosition, previousSize, delta, true);

        zoneAdapter.handleResizeEnd(previousPosition, Size.load(previousSize), position, Size.load(size));

        setPreviousSize(null);
        setPreviousPosition(null);
    }

    const offsetPos = previousPosition ? previousPosition.subtract(position) : null;

    return (
        <g transform={offsetPos ? `translate(${offsetPos.x}, ${offsetPos.y})` : ''}>
            <StickyZoneResizer
                defaultSize={stickyZone.size.dump()}
                onResizeStart={handleResizeStart}
                onResize={handleResize}
                onResizeEnd={handleResizeEnd}
                minSize={{ width: 256, height: 256 }}
            />
        </g>
    );
};
