import { useRef } from 'react';
import { DraggableSVGGElement } from '@model-framework/ui';
import { useD3DblClickCallback } from '@view-model/models/common/hooks';

type Position = {
    x: number;
    y: number;
};

type Props = {
    position: Position;
    onDblClick(): void;
    onDragStart(): void;
    onDrag(dx: number, dy: number): void;
    onDragEnd(): void;
};

export const BendingPoint: React.FC<Props> = ({ position, onDblClick, onDrag, onDragEnd, onDragStart }: Props) => {
    const domRef = useRef<SVGGElement>(null);

    const handleDragStart = () => {
        onDragStart();
    };

    const handleDrag = (dx: number, dy: number) => {
        onDrag(dx, dy);
    };

    const handleDragEnd = () => {
        onDragEnd();
    };

    useD3DblClickCallback(
        domRef,
        function () {
            onDblClick();
        },
        true
    );

    return (
        <g ref={domRef}>
            <DraggableSVGGElement
                position={position}
                onDragStart={handleDragStart}
                onDrag={handleDrag}
                onDragEnd={handleDragEnd}
            >
                <circle r="16" fill="white" stroke="#aaa" strokeWidth="4" className="hover:fill-brand" />
            </DraggableSVGGElement>
        </g>
    );
};
