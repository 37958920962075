import { useState } from 'react';
import { ViewModelURL } from '@user/pages/urls';
import { Link as InternalLink } from 'react-router-dom';
import { ForeignObjectAutosize } from '@view-model/models/common/components/ForeignObjectAutosize';
import { Size } from '@view-model/models/common/basic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faGlobe, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import './style/hyperLinkShow.css';

type Props = {
    x: number;
    y: number;
    url: string;
    onClickViewModelLink?(e: React.MouseEvent): void;
};

const RECT_HEIGHT = 64;

const checkDomainSame = (url: URL) => {
    const currentOrigin = window.location.origin;
    return currentOrigin === url.origin;
};

export const HyperLinkShow: React.FC<Props> = ({ x, y, url, onClickViewModelLink }: Props) => {
    const viewModelURL = ViewModelURL.fromURLString(url);
    const urlObj = new URL(url);
    const isSameDomain = checkDomainSame(urlObj);
    const [rectSize, setRectSize] = useState(new Size(0, RECT_HEIGHT));

    const handleSizeChange = (newSize: Size) => {
        setRectSize(new Size(newSize.width, RECT_HEIGHT));
    };

    return (
        <g transform={`translate(${x}, ${y})`}>
            <rect
                className="stroke-gray-300"
                fill={'white'}
                strokeWidth={2}
                width={rectSize.width}
                height={rectSize.height}
            />
            <ForeignObjectAutosize onSizeChange={handleSizeChange}>
                <div className="hyper-link-rect" style={{ height: rectSize.height }}>
                    {viewModelURL ? (
                        <>
                            <div className="bg-brand p-1 text-white">
                                <FontAwesomeIcon icon={faProjectDiagram} size="2x" />
                            </div>
                            <div className="hyper-link-text">
                                <InternalLink to={viewModelURL.href} onClick={onClickViewModelLink}>
                                    {url}
                                </InternalLink>
                            </div>
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon icon={faGlobe} size="2x" />
                            {isSameDomain ? (
                                <div className="hyper-link-text">
                                    <InternalLink to={urlObj.pathname} onClick={onClickViewModelLink}>
                                        {url}
                                    </InternalLink>
                                </div>
                            ) : (
                                <a href={url} className="hyper-link-text">
                                    {url}
                                </a>
                            )}
                        </>
                    )}
                    {/* 別タブで開くアイコン */}
                    <a className="pl-3 pr-1 hover:bg-gray-300" href={url} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faExternalLinkAlt} size="2x" />
                        <span className="pl-3 text-[30px]">新規タブ | New tab</span>
                    </a>
                </div>
            </ForeignObjectAutosize>
        </g>
    );
};
