import { HuddleUser } from '@view-model/application/notification/message/HuddleUser';
import { IMessage } from '@framework/notification';
import { UserId, ViewId } from '@schema-common/base';
import { HuddleMessageNotificationJSON } from '@schema-app/workspace-contents/{workspaceKey}/notifications/view-model/{viewModelId}/huddle/{id}/HuddleMessageNotificationJSON';
import { UserPublicProfile } from '@user/PublicProfile';

/**
 * ビューへの集合メッセージ
 */
export class HuddleMessage implements IMessage<HuddleMessageNotificationJSON['payload']> {
    constructor(
        public readonly viewId: ViewId,
        public readonly caller: HuddleUser
    ) {}

    dump(): HuddleMessageNotificationJSON['payload'] {
        return {
            viewId: this.viewId,
            caller: this.caller.dump(),
        };
    }

    isOwnMessage(currentUserId: UserId | undefined): boolean {
        if (!currentUserId) return false;
        return this.caller.id === currentUserId;
    }

    getCallerName(): string {
        return this.caller.name;
    }

    static build(viewId: ViewId, profile: UserPublicProfile): HuddleMessage {
        return new HuddleMessage(viewId, HuddleUser.buildFromUser(profile));
    }

    static load(dump: HuddleMessageNotificationJSON['payload']): HuddleMessage {
        return new HuddleMessage(dump.viewId, HuddleUser.load(dump.caller));
    }
}
