import { Point } from '@view-model/models/common/basic/Point';
import { ModelElementId, NodeKey } from '@view-model/domain/key';
import { StickyNodeDescriptionLayout } from '../components';
import { ModelId, NodeId, ViewModelId } from '@schema-common/base';
import { StickyNodeDescriptionJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/model-contents/{modelId}/node-descriptions/{nodeId}/StickyNodeDescriptionJSON';
import { CreateCommand } from '@model-framework/command';
import { ObjectRepository, RTDBPath } from '@framework/repository';

export class StickyNodeDescription {
    private constructor(
        public readonly nodeId: NodeId,
        public readonly content: string,
        public readonly relativePosition: Point,
        private readonly visible: boolean
    ) {}

    static build(nodeId: NodeId, visible = true): StickyNodeDescription {
        return new StickyNodeDescription(nodeId, '', StickyNodeDescriptionLayout.initialRelativePosition(), visible);
    }

    isVisible(): boolean {
        return this.visible;
    }

    dump(): StickyNodeDescriptionJSON {
        const { nodeId, content, relativePosition, visible } = this;
        return {
            nodeId,
            content,
            relativePosition: relativePosition.dump(),
            visible,
        };
    }

    static load(dump: StickyNodeDescriptionJSON): StickyNodeDescription {
        const { nodeId, content, relativePosition, visible } = dump;
        return new StickyNodeDescription(nodeId, content, Point.fromPosition(relativePosition), visible);
    }

    withRelativePosition(newRelativePosition: Point): StickyNodeDescription {
        const { nodeId, content, visible } = this;
        return new StickyNodeDescription(nodeId, content, newRelativePosition, visible);
    }

    withContent(newContent: string): StickyNodeDescription {
        const { nodeId, visible, relativePosition } = this;
        return new StickyNodeDescription(nodeId, newContent, relativePosition, visible);
    }

    toggleVisibility(): StickyNodeDescription {
        const { nodeId, content, relativePosition, visible } = this;
        return new StickyNodeDescription(nodeId, content, relativePosition, !visible);
    }

    isEqual(other: StickyNodeDescription): boolean {
        return (
            other.nodeId === this.nodeId &&
            other.content === this.content &&
            other.visible === this.visible &&
            other.relativePosition.isEqual(this.relativePosition)
        );
    }

    cloneNew(nodeKeyMap: Record<string, NodeKey>): StickyNodeDescription {
        const { nodeId, content, relativePosition, visible } = this;

        const nodeKey = NodeKey.buildFromID(nodeId);
        const newNodeId = nodeKeyMap[nodeKey.toString()]?.id as NodeId | undefined;

        return new StickyNodeDescription(newNodeId || nodeId, content, relativePosition, visible);
    }

    /**
     * 与えられた elementId が補足説明の説明対象かどうかを返します。
     * @param elementId
     */
    relatedTo(elementId: ModelElementId): boolean {
        return this.nodeId === elementId;
    }

    buildCreateCommand(viewModelId: ViewModelId, modelId: ModelId): CreateCommand<StickyNodeDescription> {
        const repo = new ObjectRepository(
            StickyNodeDescription,
            RTDBPath.Node.descriptionPath(viewModelId, modelId, this.nodeId)
        );

        return new CreateCommand(this, repo);
    }
}
