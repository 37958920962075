import { RefObject, useEffect, useRef } from 'react';
import { drag } from 'd3';
import { select } from 'd3-selection';

/**
 * ドラッグコールバックに渡されるイベント変数の型（D3DragEvent型の部分型）。
 *
 * D3DragEvent型が3つのジェネリックをもつ型で属性も多く、見通しをよくするため簡潔な型を定義しています。
 * 必要に応じてD3DragEventに定義されている属性を追加、削除してください。
 */
export type DragEvent = {
    dx: number;
    dy: number;
};

type DragCallback = {
    onDragStart?(event: DragEvent): void;
    onDrag?(event: DragEvent): void;
    onDragEnd?(event: DragEvent): void;
};

/**
 * D3Drag でDOM要素のドラッグイベントをリスニングするためのフック
 * このフックから返されるrefオブジェクトをReactコンポーネントのrefパラメータに差し込むことでドラッグイベントが
 * コールバックされるようになる。
 *
 * コールバックに渡されるイベントオブジェクトについてはD3DragEventインタフェースを参照してください。
 *
 * @param onDragStart ドラッグ開始時に呼び出されるコールバック関数
 * @param onDrag ドラッグ時に呼び出されるコールバック関数
 * @param onDragEnd ドラッグ終了時に呼び出されるコールバック関数
 */
export const useD3Drag = <T extends Element>({ onDragStart, onDrag, onDragEnd }: DragCallback): RefObject<T> => {
    const ref = useRef<T | null>(null);

    useEffect(() => {
        // useEffectの戻り値関数でも同じelemが使われるようにref.currentではなく変数にキャプチャ
        // 型エラー（TS2345）が出るが、うまく型指定側で対応できないため仕方なくasで回避
        const elem = ref.current as Element | null;
        if (!elem) return;

        const behavior = drag();

        if (onDragStart) behavior.on('start', onDragStart);
        if (onDrag) behavior.on('drag', onDrag);
        if (onDragEnd) behavior.on('end', onDragEnd);

        select(elem).call(behavior);

        return () => void select(elem).on('.drag', null);
    }, [onDrag, onDragEnd, onDragStart]);

    return ref;
};
