import { Id } from '@framework/domain';
import { ServerValue } from '@framework/repository';
import { Timestamp } from '@framework/Timestamp';
import { ClientSelectedItemJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-viewer-contents/{viewModelId}/client-selected-items/{modelId}/ClientSelectedItemJSON';
import { ClientId } from '@schema-common/base';

export type ClientSelectedItemId = string;

export class ClientSelectedItem {
    readonly id: ClientSelectedItemId;

    constructor(
        public readonly clientId: ClientId,
        public readonly itemId: Id,
        public readonly createdAt: Timestamp
    ) {
        this.id = `${clientId}--${itemId}`;
    }

    dump(): ClientSelectedItemJSON {
        const { itemId, clientId, createdAt } = this;
        return {
            itemId,
            clientId,
            createdAt: createdAt.toUnixTimestamp(),
        };
    }

    /**
     * 新規永続化用のシリアライズ結果を返す。
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dumpForNew(): Omit<ClientSelectedItemJSON, 'createdAt'> & { createdAt: any } {
        return {
            ...this.dump(),
            createdAt: ServerValue.TIMESTAMP,
        };
    }

    static load(dump: ClientSelectedItemJSON): ClientSelectedItem {
        const { clientId, createdAt, itemId } = dump;
        return new ClientSelectedItem(clientId, itemId, Timestamp.fromUnixTimestamp(createdAt));
    }

    isEqualClientId(clientId: ClientId): boolean {
        return this.clientId === clientId;
    }
}
