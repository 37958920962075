import { useCallback } from 'react';
import { GroupMemberRole } from '@group/domain';
import { EmailParseResult } from './EmailAddressParser';
import { MemberInvitationEmailInput } from './MemberInvitationEmailInput';

type Props = {
    emails: EmailParseResult[];
    role: GroupMemberRole;
    message: string;
    disabled: boolean;
    onChangeEmails(emails: EmailParseResult[]): void;
    onChangeRole(role: GroupMemberRole): void;
    onChangeMessage(message: string): void;
};

export const MemberInvitationForm: React.FC<Props> = ({
    emails,
    role,
    message,
    disabled,
    onChangeEmails,
    onChangeMessage,
    onChangeRole,
}: Props) => {
    const handleChangeMessage = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            onChangeMessage(event.target.value);
        },
        [onChangeMessage]
    );

    const handleAddEmails = useCallback(
        (addEmails: EmailParseResult[]) => {
            onChangeEmails([...emails, ...addEmails]);
        },
        [emails, onChangeEmails]
    );
    const handleRemoveEmail = useCallback(
        (removeEmail: string) => {
            onChangeEmails(emails.filter(({ email }) => email != removeEmail));
        },
        [emails, onChangeEmails]
    );
    const handleRemoveInvalidEmails = useCallback(() => {
        onChangeEmails(emails.filter(({ valid }) => valid));
    }, [emails, onChangeEmails]);

    return (
        <div className="flex flex-col pr-4">
            <div className="pt-4 text-2xl font-bold">メールで招待する</div>

            <div className="pt-4 font-bold">送付先</div>
            <MemberInvitationEmailInput
                emails={emails}
                role={role}
                disabled={disabled}
                onChangeRole={onChangeRole}
                onAddEmails={handleAddEmails}
                onRemoveEmail={handleRemoveEmail}
                onRemoveInvalidEmails={handleRemoveInvalidEmails}
            />

            <div className="pt-4 font-bold">メッセージを追加 (任意)</div>
            <textarea
                value={message}
                onChange={handleChangeMessage}
                className="w-full rounded-sm border border-gray-500 p-2"
                rows={5}
                disabled={disabled}
            />
            <div className="pt-1 text-sm">※ 招待メールには、あなたの名前とメールアドレスが記載されます。</div>
        </div>
    );
};
