import { IPositionSetRepository } from './IPositionSetRepository';
import { PositionSet } from './PositionSet';
import { PositionSetUpdateCommand } from './PositionSetUpdateCommand';
import { ModelLayout } from '@view-model/models/sticky/layout';

export class DragManager {
    private startPositions: PositionSet | null = null;
    private currentPositions: PositionSet | null = null;

    constructor(private readonly repository: IPositionSetRepository) {}

    /**
     * ドラッグ開始時にドラッグ対象の id を指定して呼び出す。
     * @param ids ドラッグ移動先の id 配列
     */
    async dragStart(ids: string[]): Promise<void> {
        if (this.startPositions) {
            // Example issue: https://github.com/levii/balus-app/issues/282
            console.warn(
                'DragManager.dragStart() is called multiple times before dragEnd(). ' +
                    'It may cause an unpredictable problem.'
            );
        }

        const positions = await this.repository.getMany(ids);
        this.startPositions = positions;
        this.currentPositions = positions;
    }

    /**
     * ドラッグ時の差分移動量を受け取って、現在の位置情報を返す
     *
     * @param dx x軸方向の移動量
     * @param dy y軸方向の移動量
     * @return 移動後の PositionSet を返す
     */
    async drag(dx: number, dy: number): Promise<PositionSet | null> {
        if (!this.currentPositions) return null;

        this.currentPositions = this.currentPositions.moveAll(dx, dy);
        this.repository.saveMany(this.currentPositions).then();

        return this.currentPositions;
    }

    /**
     * ドラッグ完了処理
     *
     * @return ドラッグ移動に伴う位置変更コマンドを返す。移動していない場合には null を返す
     */
    async dragEnd(): Promise<PositionSetUpdateCommand | null> {
        if (!this.startPositions || !this.currentPositions) {
            // Example issue: https://github.com/levii/balus-app/issues/282
            console.warn(
                'DragManager.dragEnd() is called multiple times after single dragStart(). ' +
                    'It may cause an unpredictable problem.'
            );
            return null;
        }

        this.currentPositions = this.currentPositions.snapToLayout(ModelLayout);
        this.repository.saveMany(this.currentPositions).then();

        const { startPositions, currentPositions } = this;

        this.startPositions = null;
        this.currentPositions = null;

        // 座標が開始時から変化していなければ、コマンド生成せずに null を返す
        if (startPositions.isEqual(currentPositions)) return null;

        return new PositionSetUpdateCommand(startPositions, currentPositions, this.repository);
    }

    /**
     * ドラッグ中か否かを返す
     */
    isDragging(): boolean {
        return !!this.startPositions;
    }

    /**
     * ドラッグ中の要素IDリストを返す。
     * ドラッグ中でない場合には、空配列を返す
     */
    draggingIds(): string[] {
        return this.startPositions?.allIds() || [];
    }
}
