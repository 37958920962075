import { useEffect, useRef, useState, useCallback } from 'react';
import { StickyZoneFontSize, StickyZoneText } from '../../domain';
import { ForeignObjectAutosize } from '@view-model/models/common/components/ForeignObjectAutosize';
import { useD3DblClickCallback, useD3MouseDownCallback, useD3MouseUpCallback } from '@view-model/models/common/hooks';

type Props = {
    size: { width: number; height: number };
    isMyEditing: boolean;
    otherUserEditing: boolean;
    textSelectable: boolean;
    text: StickyZoneText;
    fontSize: StickyZoneFontSize;
    onEditStart(): void;
    onChange(text: StickyZoneText): void;
    onEditEnd(text: StickyZoneText): void;
};

export const StickyZoneTextarea: React.FC<Props> = ({
    size,
    isMyEditing,
    otherUserEditing,
    textSelectable,
    text,
    fontSize,
    onChange,
    onEditStart,
    onEditEnd,
}: Props) => {
    const textRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const selectionRef = useRef<{ start: number; end: number } | null>(null);
    const { width } = size;

    const [textareaHeight, setTextareaHeight] = useState<string>('auto');
    const [editingText, setEditingText] = useState<string>('');

    // テキスト要素のダブルクリックイベントを紐付ける
    useD3DblClickCallback(
        textRef,
        () => {
            onEditStart();
        },
        true
    );

    // 通常時(非編集時)のテキスト領域のクリック、ドラッグイベントを紐付ける
    // ゾーンのテキスト表示時にドラッグでテキスト選択できるように、(ゾーン自体が移動しないように)イベント伝搬を止める
    useD3MouseDownCallback(textRef, () => void 0, true);
    useD3MouseUpCallback(
        textRef,
        () => {
            if (!textSelectable) return;
            // ユーザがテキスト上のクリック or ドラッグで選択していれば、その情報を ref に記録しておく
            const selection = window.getSelection();
            if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                selectionRef.current = {
                    start: range.startOffset,
                    end: range.endOffset,
                };
            } else {
                selectionRef.current = null;
            }
            onEditStart();
        },
        true
    );

    // 編集時のテキストエリアでのキー入力、マウス操作イベントを紐付ける
    useEffect(() => {
        const current = textareaRef.current;
        if (!current) return;

        current.addEventListener('keydown', handleKeyDown);
        current.addEventListener('mousedown', handleMouseDown);

        return () => {
            current.removeEventListener('keydown', handleKeyDown);
            current.removeEventListener('mousedown', handleMouseDown);
        };
    }, [textareaRef]);

    useEffect(() => {
        setEditingText(text.value);
    }, [isMyEditing, text]);

    function handleKeyDown(event: KeyboardEvent): void {
        const withMetaKey = event.ctrlKey || event.metaKey;
        if (event.key == 'Enter' && withMetaKey && textareaRef.current) {
            textareaRef.current.blur();
        }
    }

    function handleMouseDown(event: MouseEvent): void {
        // テキスト選択できるようにイベント伝搬を止める
        event.stopPropagation();
    }

    function adjustTextareaHeight(): void {
        if (!textareaRef.current) return;
        setTextareaHeight(`${textareaRef.current.scrollHeight}px`);
    }

    function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>): void {
        const value = event.target.value;
        const newText = new StickyZoneText(value);
        setEditingText(value);
        onChange(newText);
        adjustTextareaHeight();
    }

    const handleFocus = useCallback(() => {
        adjustTextareaHeight();
        const target = textareaRef.current;

        if (selectionRef.current?.start && selectionRef.current?.end) {
            const { start, end } = selectionRef.current;
            target?.focus();
            target?.setSelectionRange(start, end);
            selectionRef.current = null;
        } else {
            // 範囲選択情報が無ければ、末尾にカーソルをあてる
            if (target) {
                const length = target.value.length;
                target.focus();
                target.setSelectionRange(length, length);
            }
        }
    }, []);

    function handleBlur(): void {
        const newText = new StickyZoneText(editingText);
        onEditEnd(newText);
    }

    useEffect(() => {
        if (isMyEditing) {
            handleFocus();
        }
    }, [isMyEditing, handleFocus]);

    return (
        <ForeignObjectAutosize>
            <div style={{ width: width, fontSize: StickyZoneFontSize.getFontSize(fontSize) }}>
                <div
                    className="size-full items-start justify-center"
                    style={{
                        display: !isMyEditing ? 'none' : 'flex',
                    }}
                >
                    <textarea
                        ref={textareaRef}
                        className="mx-6 mb-6 mt-10 resize-none border-none bg-transparent text-center align-middle font-bold outline-none"
                        value={editingText}
                        placeholder={'タイトル | Title'}
                        rows={1}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        style={{ height: textareaHeight, width: 'calc(100% - 48px)' }}
                    />
                </div>
                <div
                    ref={textRef}
                    className="mx-6 mb-6 mt-10 items-start justify-center whitespace-break-spaces break-all text-center font-bold"
                    style={{
                        width: 'calc(100% - 48px)',
                        display: isMyEditing ? 'none' : 'flex',
                        cursor: textSelectable ? (!otherUserEditing ? 'text' : 'not-allowed') : 'default',
                    }}
                >
                    {textSelectable ? text.value || 'タイトル | Title' : text.value}
                </div>
            </div>
        </ForeignObjectAutosize>
    );
};
