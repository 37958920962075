import { ModelCommentThread } from './ModelCommentThread';
import { ModelCommentThreadId, ModelId, ViewModelId } from '@schema-common/base';
import { ModelCommentThreadCollection } from './ModelCommentThreadCollection';
import { RecordRepository, RefBuilder, Reference, RTDBPath } from '@framework/repository';
import { ModelCommentThreadJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/model-contents/{modelId}/model-comment-threads/{modelCommentThreadId}/ModelCommentThreadJSON';

export class ModelCommentThreadRepository {
    private readonly recordRepository: RecordRepository<ModelCommentThreadJSON, ModelCommentThread>;

    constructor(
        private readonly viewModelId: ViewModelId,
        private readonly modelId: ModelId
    ) {
        this.recordRepository = new RecordRepository(
            ModelCommentThread,
            RTDBPath.Comment.threadsPath(viewModelId, modelId)
        );
    }

    private threadsRef(): Reference {
        return RefBuilder.ref(RTDBPath.Comment.threadsPath(this.viewModelId, this.modelId));
    }

    private threadRef(id: ModelCommentThreadId): Reference {
        return RefBuilder.ref(RTDBPath.Comment.threadPath(this.viewModelId, this.modelId, id));
    }

    async deleteMany(ids: ModelCommentThreadId[]): Promise<void> {
        if (ids.length === 0) {
            return;
        }

        const updates: Record<string, null> = {};
        ids.forEach((id) => {
            updates[id] = null;
        });

        await this.threadsRef().update(updates);
    }

    async deleteOne(id: ModelCommentThreadId): Promise<void> {
        await this.deleteMany([id]);
    }

    async getMany(ids: ModelCommentThreadId[]): Promise<ModelCommentThread[]> {
        const threads = await Promise.all(ids.map((id) => this.getOne(id)));
        return threads.filter((thread): thread is ModelCommentThread => !!thread);
    }

    async getOne(id: ModelCommentThreadId): Promise<ModelCommentThread | undefined> {
        const snapshot = await this.threadRef(id).once('value');

        const j = snapshot.val() as ModelCommentThreadJSON;
        if (!j) return;

        return ModelCommentThread.load(j);
    }

    async load(): Promise<ModelCommentThreadCollection> {
        const snapshot = await this.threadsRef().once('value');
        const threads: ModelCommentThread[] = [];
        snapshot.forEach((data) => {
            const j = data.val() as ModelCommentThreadJSON;
            threads.push(ModelCommentThread.load(j));
        });

        return new ModelCommentThreadCollection(threads);
    }

    async saveMany(commentThreads: ModelCommentThread[]): Promise<void> {
        if (commentThreads.length === 0) {
            return;
        }

        const updates: Record<string, ModelCommentThreadJSON> = {};
        commentThreads.forEach((thread) => {
            updates[thread.id] = thread.dump();
        });

        await this.threadsRef().update(updates);
    }

    async saveOne(commentThread: ModelCommentThread): Promise<void> {
        await this.saveMany([commentThread]);
    }

    addListener(
        onAdded: (value: ModelCommentThread) => void,
        onChanged: (value: ModelCommentThread) => void,
        onRemoved: (value: ModelCommentThread) => void
    ): void {
        this.recordRepository.addListener(onAdded, onChanged, onRemoved);
    }

    removeListener(): void {
        this.recordRepository.removeListener();
    }
}
