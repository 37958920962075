import { ViewEntity } from '@view-model/domain/view';
import { StickyModelContents, StickyModelContentsJSON } from '@view-model/domain/model';
import { ClipboardPayload } from '@view-model/models/framework/clipboard';
import { ViewJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/views/{viewId}/ViewJSON';
import { nanoid } from 'nanoid';

// これまでViewClipboard Payloadを流用していたが、ViewClipboard Payloadは不要になった不要になった。
// そのため、ViewClipboard Payloadの中身をMLAPIPayloadに移植している。
export type MLAPIPayload = ClipboardPayload & {
    target: 'ViewModel';
    contentType: 'View';
    id: string;
    sourceViewModelId: string; // コピー元のビューモデルID
    view: ViewJSON;
    modelContents: StickyModelContentsJSON;
};

export namespace MLAPIPayload {
    // ML の API の開発の容易さのためにも Clipboard と共通のインターフェースを利用する
    export function fromViewAndModelContents(
        sourceViewModelId: string,
        view: ViewEntity,
        modelContents: StickyModelContents
    ): MLAPIPayload {
        return {
            target: 'ViewModel',
            contentType: 'View',
            id: `${nanoid()}`,
            sourceViewModelId,
            view: view.dump(),
            modelContents: modelContents.dump(),
        };
    }
}
