import { useCallback } from 'react';
import { FlatButton } from '@framework/ui/atoms';
import { classNames } from '@framework/utils';

type TabName = 'invitations' | 'members';
export type MenuName = 'invitations' | 'invitation' | 'members' | 'remove';

type Props = {
    current: TabName;
    showDeleteButton: boolean;
    onClickMenu(menu: MenuName): void;
};

export const GroupMemberEditTab: React.FC<Props> = ({ current, showDeleteButton, onClickMenu }: Props) => {
    const handleClickTabMenu = useCallback(
        (tabName: TabName) => {
            onClickMenu(tabName);
        },
        [onClickMenu]
    );
    const handleClickRemoveButton = useCallback(() => {
        onClickMenu('remove');
    }, [onClickMenu]);

    return (
        <div className="flex justify-between border-b pl-4 pr-8">
            <ul className="flex">
                <li className="mr-1">
                    <TabButton name="invitations" current={current} onClick={handleClickTabMenu}>
                        招待
                    </TabButton>
                </li>
                <li className="mr-1">
                    <TabButton name="members" current={current} onClick={handleClickTabMenu}>
                        メンバー
                    </TabButton>
                </li>
            </ul>
            {showDeleteButton && (
                <FlatButton className="my-auto focus:outline-none" size="sm" onClick={handleClickRemoveButton}>
                    ユーザーの削除
                </FlatButton>
            )}
        </div>
    );
};

type TabProps = {
    name: TabName;
    current: TabName;
    children: React.ReactNode;
    onClick(name: TabName): void;
};

const TabButton: React.FC<TabProps> = ({ name, current, children, onClick }: TabProps) => {
    const isActive = name === current;
    const handleClick = useCallback(() => {
        onClick(name);
    }, [onClick, name]);

    return (
        <button
            onClick={handleClick}
            className={classNames(
                'inline-block bg-white px-4 py-2 focus:outline-none',
                isActive ? 'font-bold text-brand' : 'font-bold text-gray-500', // font
                isActive ? 'border-b-4 border-brand' : '' // border
            )}
            disabled={isActive} // アクティブなタブのボタンはクリックできなくて良い
        >
            {children}
        </button>
    );
};
