import { RTDBPath } from '@framework/repository';
import { Channel } from '@framework/notification';
import { HuddleMessage } from './message';
import { HuddleMessageNotificationJSON } from '@schema-app/workspace-contents/{workspaceKey}/notifications/view-model/{viewModelId}/huddle/{id}/HuddleMessageNotificationJSON';
import { ViewModelId } from '@schema-common/base';

export class ViewModelNotification {
    constructor(private readonly viewModelId: ViewModelId) {}

    huddleChannel(): Channel<HuddleMessageNotificationJSON['payload'], HuddleMessage> {
        return new Channel(RTDBPath.ViewModelViewer.huddlePath(this.viewModelId), HuddleMessage);
    }
}
