import { NodeMenuId } from './NodeMenuId';
import { NodeFontSize, NodeStyle } from '../../domain';
import { FontSize, FontSizeMenu } from '@model-framework/text';
import { ColorMenu } from '@model-framework/color';
import { StickyNodeGroupingMenuButton, TrashMenuButton, HyperLinkMenuButton } from '@model-framework/menu';
import { StickyNodeDescriptionMenuButton } from '@view-model/models/sticky/StickyNodeDescription';
import { ThemeColor } from '@view-model/models/common/color';
import { AnalysisMenuButton } from '@model-framework/menu/AnalysisMenuButton';
import { NodeReaction, NodeReactionMenu, ReactionType } from '@view-model/models/sticky/NodeReaction';
import { UserId } from '@schema-common/base';

type Props = {
    menuId: NodeMenuId;
    menuSize: number;
    currentStyle: NodeStyle;
    isOpen: boolean;
    url: string | null;
    reaction: NodeReaction | null | undefined;
    onMenuOpen(menu: NodeMenuId): void;
    onMenuClose(): void;
    onFontSizeSelected(fontSize: FontSize): void;
    onThemeColorSelected(themeColor: ThemeColor): void;
    onDeleteNode(): void;
    onToggleNodeDescription(): void;
    onGroupSelectedNode(): void;
    onSaveHyperLink(url: string | null): void;
    onAnalysisSelected(): void;
    onReactionSelected(reactionName: ReactionType, userId: UserId, userName: string): void;
};

export const NodeMenuContent: React.FC<Props> = ({
    menuId,
    menuSize,
    currentStyle,
    isOpen,
    url,
    reaction,
    onMenuOpen,
    onMenuClose,
    onThemeColorSelected,
    onFontSizeSelected,
    onDeleteNode,
    onToggleNodeDescription,
    onGroupSelectedNode,
    onSaveHyperLink,
    onAnalysisSelected,
    onReactionSelected,
}: Props) => {
    return (
        <>
            {menuId === NodeMenuId.FontSize && (
                <FontSizeMenu
                    currentFontSize={currentStyle.fontSize}
                    menuSize={menuSize}
                    items={NodeFontSize.values()}
                    isOpen={isOpen}
                    onMenuClicked={() => {
                        isOpen ? onMenuClose() : onMenuOpen(menuId);
                    }}
                    onSelected={onFontSizeSelected}
                    text="テキストサイズ | Text size"
                />
            )}
            {menuId === NodeMenuId.ThemeColor && (
                <ColorMenu
                    currentColorName={currentStyle.themeColor}
                    menuSize={menuSize}
                    items={ThemeColor.menuItems()}
                    numColumns={4}
                    isOpen={isOpen}
                    text="カラー | Color"
                    onMenuClicked={() => {
                        isOpen ? onMenuClose() : onMenuOpen(menuId);
                    }}
                    onSelected={onThemeColorSelected}
                />
            )}
            {menuId === NodeMenuId.HyperLink && (
                <HyperLinkMenuButton
                    menuSize={menuSize}
                    isOpen={isOpen}
                    initialURL={url}
                    onMenuClicked={() => {
                        isOpen ? onMenuClose() : onMenuOpen(menuId);
                    }}
                    onClose={() => onMenuClose()}
                    onSave={onSaveHyperLink}
                    text="ハイパーリンク | Hyperlink"
                />
            )}
            {menuId === NodeMenuId.Reaction && (
                <NodeReactionMenu
                    menuSize={menuSize}
                    isOpen={isOpen}
                    reaction={reaction}
                    onMenuClicked={() => {
                        isOpen ? onMenuClose() : onMenuOpen(menuId);
                    }}
                    onReactionSelected={onReactionSelected}
                    text="リアクション | Reaction"
                />
            )}
            {menuId === NodeMenuId.DeleteNode && (
                <TrashMenuButton menuSize={menuSize} onClick={onDeleteNode} text="削除 | Delete" />
            )}
            {menuId === NodeMenuId.NodeDescription && (
                <StickyNodeDescriptionMenuButton
                    menuSize={menuSize}
                    onClick={onToggleNodeDescription}
                    text="補足説明 | Note"
                />
            )}
            {menuId === NodeMenuId.Analysis && (
                <AnalysisMenuButton
                    menuSize={menuSize}
                    onClick={() => {
                        // 他のメニューが開いていた場合閉じる
                        onMenuClose();
                        onAnalysisSelected();
                    }}
                    text="似たノードを見つける (α) | Find similar nodes (α)"
                />
            )}
            {menuId === NodeMenuId.Grouping && (
                <StickyNodeGroupingMenuButton
                    menuSize={menuSize}
                    onClick={() => {
                        // 他のメニューが開いていた場合閉じる
                        onMenuClose();
                        onGroupSelectedNode();
                    }}
                    text="グループ化 | Group"
                />
            )}
        </>
    );
};
