import { useEffect, useState } from 'react';
import { usePageViewLogger } from '@framework/action-log';
import { useCurrentUserId } from '@framework/auth';
import { LoginModal } from '@framework/ui/elements/LoginModal';
import { PageTitleManager } from '@user/pages/PageTitle/PageTitleManager';
import { ViewModelPageContent } from '@user/pages/ViewModelPage/ViewModelPageContent';
import { useViewModelPermissions } from '@view-model/application/context';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { Rect } from '@view-model/models/common/basic';
import { LeavingPageContext, useShowPopupBeforeLeavingPage } from '@view-model/models/common/hooks';
import { Workspace } from '@workspace/domain/workspace/Workspace';
import { ConnectionStatusView } from './internal/ConnectionStatusView';
import { TrashedMessageModal } from './internal/TrashedMessageModal';

const useViewModelPageView = (workspace: Workspace, viewModel: ViewModelEntity | null): void => {
    const pageViewLogger = usePageViewLogger('ViewModelPage');

    useEffect(() => {
        if (!viewModel) return;

        pageViewLogger.landing({
            workspaceId: workspace.id,
            workspaceName: workspace.name.value,
            viewModelId: viewModel.id,
            viewModelName: viewModel.name.value,
        });
        return () => pageViewLogger.leaving();
    }, [workspace, viewModel, pageViewLogger]);
};

type Props = {
    workspace: Workspace;
    viewModel: ViewModelEntity;
};

export const ViewModelPage: React.FC<Props> = ({ workspace, viewModel }: Props) => {
    const currentUserId = useCurrentUserId();
    const [otherUserClientViewBox, setOtherUserClientViewBox] = useState<Rect | null>(null);
    const viewModelPermissions = useViewModelPermissions(currentUserId, workspace, viewModel);

    useViewModelPageView(workspace, viewModel);
    const { popupAvailableRef } = useShowPopupBeforeLeavingPage();

    useEffect(() => {
        if (!viewModel?.name) return;

        const pageTitleManager = new PageTitleManager();
        pageTitleManager.setViewModelPageTitle(viewModel.name);

        return () => {
            pageTitleManager.setDefaultPageTitle();
        };
    }, [viewModel?.name]);

    if (!currentUserId) {
        return (
            <LoginModal
                isOpen={true}
                onClose={() => void 0}
                title={'Balusを利用する | Use Balus'}
                shouldCloseOnOverlayClick={true}
            />
        );
    }

    return (
        <div>
            <LeavingPageContext.Provider value={popupAvailableRef}>
                <ViewModelPageContent
                    key={viewModel.id} // ビューモデルが切り替わった時はコンポーネントを初期化する
                    workspace={workspace}
                    viewModel={viewModel}
                    viewModelPermissions={viewModelPermissions}
                    otherUserViewBox={otherUserClientViewBox}
                    setOtherUserClientViewBox={setOtherUserClientViewBox}
                />
            </LeavingPageContext.Provider>

            {/* ビューモデルが削除されているときの表示 */}
            {viewModel.isTrashed() && (
                <TrashedMessageModal workspaceId={viewModel.workspaceId} viewModelId={viewModel.id} />
            )}

            {/* Realtime Database との接続が切れたときに、ユーザに通知する */}
            <ConnectionStatusView />
        </div>
    );
};
