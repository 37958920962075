import { memo, useMemo } from 'react';
import { DescriptionPanelCollectionContents } from './domain';
import { DescriptionPanelView } from './components';
import { SelectedItemSet } from '@view-model/models/common/basic';
import { ModelElementId } from '@view-model/domain/key';
import { DescriptionPanelId, ModelId, ViewModelId } from '@schema-common/base';

type Props = {
    viewModelId: ViewModelId;
    modelId: ModelId;
    panelContents: DescriptionPanelCollectionContents;
    selectedItems: SelectedItemSet<ModelElementId>;
    readonly: boolean;
    onClick(id: DescriptionPanelId): void;
    onDragStart(id: DescriptionPanelId): void;
    onDrag(dx: number, dy: number): void;
    onDragEnd(): void;
};

export const DescriptionPanelCollectionView = memo(
    ({
        viewModelId,
        modelId,
        panelContents,
        selectedItems,
        readonly,
        onClick,
        onDragStart,
        onDrag,
        onDragEnd,
    }: Props) => {
        const orderedPanelEntries = useMemo(() => {
            return panelContents.orderedEntries(selectedItems);
        }, [panelContents, selectedItems]);

        return (
            <>
                {orderedPanelEntries.map(([panel, position]) => {
                    const isSelected = selectedItems.isSelected(panel.id);

                    return (
                        <DescriptionPanelView
                            key={panel.id}
                            viewModelId={viewModelId}
                            modelId={modelId}
                            id={panel.id}
                            position={position}
                            isSelected={isSelected}
                            readonly={readonly}
                            onClick={onClick}
                            onDragStart={onDragStart}
                            onDrag={onDrag}
                            onDragEnd={onDragEnd}
                        />
                    );
                })}
            </>
        );
    }
);
DescriptionPanelCollectionView.displayName = 'DescriptionPanelCollectionView';
