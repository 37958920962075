type StickyZoneSizeJSON = {
    width: number;
    height: number;
};

export class StickyZoneSize {
    readonly width: number;
    readonly height: number;

    private static DEFAULT_WIDTH = 256 * 5;
    private static DEFAULT_HEIGHT = 256 * 3;

    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;

        if (this.width <= 0 || this.height <= 0) {
            throw new Error(`Invalid StickyZoneSize (width=${width}, height=${height})`);
        }
    }

    dump(): StickyZoneSizeJSON {
        return { width: this.width, height: this.height };
    }

    static load({ width, height }: StickyZoneSizeJSON): StickyZoneSize {
        return new this(width, height);
    }

    static buildNew(): StickyZoneSize {
        return new this(this.DEFAULT_WIDTH, this.DEFAULT_HEIGHT);
    }

    add(dWidth: number, dHeight: number): StickyZoneSize {
        return new StickyZoneSize(this.width + dWidth, this.height + dHeight);
    }

    isEqual(other: StickyZoneSize): boolean {
        return other instanceof StickyZoneSize && this.width === other.width && this.height === other.height;
    }
}
