import { useEffect, useMemo, useState } from 'react';
import { Key } from '@framework/domain';
import { useEditingUserFromAuthContext } from './useEditingUserFromAuthContext';
import { EditingUser } from './domain';
import { useOtherUserEditingToast } from './useOtherUserEditingToast';
import { useHandler } from '@framework/hooks/useHandler';
import { ViewModelId } from '@schema-common/base';
import { EditingUserRepository } from './infrastructure';
import { useNodeEditingUser } from '../../../sticky/editing-users/useNodeEditingUser';

/**
 * EditingUser を利用した排他的なテキスト編集状態の管理フック。
 *
 * @param viewModelId 操作対象のビューモデルID
 * @param editLockKey 排他的にロックする対象を識別するキー。
 * @param text 編集対象のテキスト。空文字かつ編集中ユーザーが自分自身の場合は編集状態に遷移する（editing=trueを返す）
 * @param readonly 読み取り専用フラグ。trueの場合はonStartEdit()が呼ばれても編集状態に遷移しない（常にediting=falseを返す）。
 *
 * @returns {Object} state フックの戻り値
 * @returns {boolean} state.editing 編集中かどうかの状態。
 * @returns {function(): void} state.startEdit 編集開始をトリガーする関数。
 * @returns {function(): void} state.endEdit 編集終了をトリガーする関数。
 * @returns {EditingUser|null} state.currentEditingUser 現在編集中のユーザ
 */
export const useExclusiveTextEditing = (
    viewModelId: ViewModelId,
    editLockKey: Key,
    text: string | null,
    readonly: boolean
): { editing: boolean; startEdit(): void; endEdit(): void; currentEditingUser: EditingUser | null } => {
    const authEditingUser = useEditingUserFromAuthContext();
    const [state, setState] = useState<{
        editing: boolean;
    }>({
        editing: false,
    });

    const editingUserRepository = useMemo(() => new EditingUserRepository(viewModelId), [viewModelId]);
    const notifyOtherUserEditingToast = useOtherUserEditingToast();
    const currentEditingUser = useNodeEditingUser(editLockKey);

    // ローカルの状態とRTDBを比較して編集中かどうかを判定する
    const editing = state.editing || !!(authEditingUser && currentEditingUser?.isEqual(authEditingUser));

    // このフックで管理している state が編集中だとしても、編集中ユーザーが他人だった場合には、編集ロックの取得失敗として扱う
    useEffect(() => {
        if (!authEditingUser) return;

        if (state.editing && currentEditingUser && !currentEditingUser.isEqual(authEditingUser)) {
            setState({ editing: false });
            notifyOtherUserEditingToast();
        }
    }, [authEditingUser, currentEditingUser, state.editing, notifyOtherUserEditingToast]);

    // テキスト編集終了トリガー
    const endEdit = useHandler(async () => {
        await editingUserRepository.delete(editLockKey);
        setState({ editing: false });
    });

    // テキスト編集開始トリガー
    const startEdit = useHandler(() => {
        if (readonly || editing) return;

        // 他ユーザが編集ロック取得中
        if (currentEditingUser) {
            notifyOtherUserEditingToast();
            return;
        }

        // 編集開始
        if (!authEditingUser) return;

        // 編集中ユーザ情報の保存完了を待たずに state 更新する。
        // (保存完了を待ってから state 更新すると UI の反応が鈍いため)
        editingUserRepository.save(editLockKey, authEditingUser).then();
        setState({ editing: true });
    });

    return { editing, startEdit, endEdit, currentEditingUser };
};
