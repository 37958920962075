import { CommandManager } from '@model-framework/command';
import { LinkableTargetKey, LinkKey } from '@view-model/domain/key';
import { LinkEntityOperation } from '../adapter';
import { LinkEntity, LinkName, LinkStyle, LinkMultiplicity } from '../domain';
import { LinkCreateCommand } from '../command';
import { ViewModelEntity } from '@view-model/domain/view-model';

export class StickyLinkCreationOperation {
    constructor(
        private readonly viewModel: ViewModelEntity,
        private readonly linkEntityOperation: LinkEntityOperation,
        private readonly commandManager: CommandManager
    ) {}

    /**
     * 指定の要素間をつなぐリンクを作成し、作成したリンクのキーを返します。
     * 同一要素のキーが指定された場合は作成せずに undefined を返します。
     *
     * @param fromKey リンクの始点要素のkey
     * @param toKey リンクの終点要素のkey
     * @param style リンクのスタイル
     */
    createLink(fromKey: LinkableTargetKey, toKey: LinkableTargetKey, style: LinkStyle): LinkKey | undefined {
        if (fromKey.isEqual(toKey)) return;

        const link = new LinkEntity({
            key: LinkKey.buildNew(),
            from: fromKey,
            to: toKey,
            name: LinkName.empty(),
            bend: this.linkEntityOperation.newLinkBendBetween({
                fromKey,
                toKey,
                viewModel: this.viewModel,
            }),
            style,
            multiplicity: LinkMultiplicity.Disabled(),
        });
        const command = new LinkCreateCommand(this.viewModel, this.linkEntityOperation, link);
        this.commandManager.execute(command);

        return link.key;
    }
}
