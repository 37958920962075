import { FontSize } from './index';

const fontSizeList = [FontSize.S, FontSize.M, FontSize.L, 'XL'] as const;
export type DisplayFontSize = (typeof fontSizeList)[number];

export namespace DisplayFontSize {
    export const S = FontSize.S;
    export const M = FontSize.M;
    export const L = FontSize.L;
    export const XL = 'XL' as const;

    export function getFontSize(size: DisplayFontSize): string {
        if (size == DisplayFontSize.S) {
            return '16px';
        }
        if (size == DisplayFontSize.M) {
            return '28px';
        }
        if (size == DisplayFontSize.L) {
            return '40px';
        }
        if (size == DisplayFontSize.XL) {
            return '80px';
        }

        return '28px';
    }
}
