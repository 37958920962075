import {
    ClientSelectedItem,
    ClientSelectedItemCollection,
    ClientSelectedItemId,
} from '@model-framework/client-selected-item/domain';
import { Id } from '@framework/domain';
import { ClientSelectedItemJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-viewer-contents/{viewModelId}/client-selected-items/{modelId}/ClientSelectedItemJSON';
import { ClientId, ModelId, ViewModelId } from '@schema-common/base';
import { RefBuilder, Reference, RTDBPath } from '@framework/repository';

type UpdatesPayload = Record<string, ClientSelectedItemJSON | null>;

export class ClientSelectedItemRepository {
    private readonly ref: Reference;

    constructor(viewModelId: ViewModelId, modelId: ModelId) {
        this.ref = RefBuilder.ref(RTDBPath.ViewModelViewer.clientSelectedItemPath(viewModelId, modelId));
    }

    private async load(): Promise<ClientSelectedItemCollection> {
        const snapshot = await this.ref.once('value');
        const items: ClientSelectedItem[] = [];

        snapshot.forEach((data) => {
            const j = data.val() as ClientSelectedItemJSON;
            if (!j) return;
            items.push(ClientSelectedItem.load(j));
        });

        return new ClientSelectedItemCollection(items);
    }

    /**
     * 要素に対する選択情報を一括保存する
     * @param selectedItems
     */
    async saveMulti(selectedItems: ClientSelectedItemCollection): Promise<void> {
        if (selectedItems.length === 0) return;

        const updates: UpdatesPayload = {};
        selectedItems.forEach((selected) => {
            updates[selected.id] = selected.dumpForNew();
        });

        const { ref } = this;
        // 切断時に確実に値が削除されるように、先に削除オペレーションを登録してから、値の書き込みを行う
        selectedItems.forEach(({ id }) => ref.child(id).onDisconnect().remove());
        await ref.update(updates);
    }

    /**
     * 全ての選択情報を一括削除する
     * (モデルを削除したときに利用する)
     */
    async deleteAll(): Promise<void> {
        await this.ref.set(null);
    }

    /**
     * 指定したクライアントによる選択情報を一括削除する
     * (指定のクライアントがページを離脱するとき等に利用する)
     * @param clientId
     */
    async deleteAllByClientId(clientId: ClientId): Promise<void> {
        const items = await this.load();
        const deleteTargets = items.filterByClientId(clientId);
        if (deleteTargets.length === 0) return;

        const updates: UpdatesPayload = {};
        deleteTargets.allIds().forEach((id) => {
            updates[id] = null;
        });

        await this.ref.update(updates);
    }

    /**
     * 指定した対象要素に対する選択情報を一括削除する
     * (選択可能な要素を削除したときに利用する)
     * @param itemIds
     */
    async deleteAllByItemIds(itemIds: Id[]): Promise<void> {
        const items = await this.load();
        const deleteTargets = items.filterByItemIds(itemIds);
        if (deleteTargets.length === 0) return;

        const updates: UpdatesPayload = {};
        deleteTargets.allIds().forEach((id) => {
            updates[id] = null;
        });

        await this.ref.update(updates);
    }

    /**
     * 指定された id の選択情報を削除する
     * @param ids
     */
    async deleteMulti(ids: ClientSelectedItemId[]): Promise<void> {
        if (ids.length === 0) return;

        const updates: UpdatesPayload = {};
        ids.forEach((id) => {
            updates[id] = null;
        });

        await this.ref.update(updates);
    }
}
