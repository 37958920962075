import { ViewName } from '@view-model/domain/view';
import { useAtomValue } from 'jotai';
import { viewTitleBarAtom } from '../viewTitlaBarAtom';

const PLACE_HOLDER = 'タイトル | Title';

type Props = {
    name: ViewName;
};

export const ReadonlyViewTitle: React.FC<Props> = ({ name }: Props) => {
    const {
        title: { fontSize },
    } = useAtomValue(viewTitleBarAtom);

    return (
        <div className="pointer-events-none grow truncate text-center font-bold text-white" style={{ fontSize }}>
            {name.value ? name.value : PLACE_HOLDER}
        </div>
    );
};
