import { GroupMemberRole } from '../GroupEntity';
import { GroupMemberInvitationJSON } from '@schema-app/group-contents/{groupKey}/member-invitations/{groupMemberInvitationId}/GroupMemberInvitationJSON';

export class MemberInvitation {
    constructor(
        readonly email: string,
        readonly memberRole: GroupMemberRole,
        readonly message: string,
        readonly baseUrl: string
    ) {}

    dump(): GroupMemberInvitationJSON['invitation'] {
        const { email, memberRole, message, baseUrl } = this;
        return { email, memberRole, message, baseUrl };
    }

    static load(dump: GroupMemberInvitationJSON['invitation']): MemberInvitation {
        const { email, memberRole, message, baseUrl } = dump;
        return new this(email, memberRole, message, baseUrl);
    }
}
