import { ModelCommentThread } from './ModelCommentThread';
import { ModelCommentThreadId } from '@schema-common/base';
import { ModelCommentThreadJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/model-contents/{modelId}/model-comment-threads/{modelCommentThreadId}/ModelCommentThreadJSON';

type ModelCommentThreadCollectionJSON = ModelCommentThreadJSON[];

export class ModelCommentThreadCollection {
    constructor(private readonly commentThreads: ModelCommentThread[]) {}

    static load(dump: ModelCommentThreadCollectionJSON): ModelCommentThreadCollection {
        const threads = dump?.map((j) => ModelCommentThread.load(j));
        return new this(threads || []);
    }

    dump(): ModelCommentThreadCollectionJSON {
        return this.commentThreads.map((thread) => thread.dump());
    }

    allIds(): ModelCommentThreadId[] {
        return this.commentThreads.map(({ id }) => id);
    }

    list(): ModelCommentThread[] {
        return [...this.commentThreads];
    }

    count(): number {
        return this.commentThreads.length;
    }

    cloneNew(): [ModelCommentThreadCollection, Record<ModelCommentThreadId, ModelCommentThreadId>] {
        const keyMap: Record<ModelCommentThreadId, ModelCommentThreadId> = {};
        const threads: ModelCommentThread[] = [];

        this.commentThreads.forEach((thread) => {
            const newThread = thread.cloneNew();
            keyMap[thread.id] = newThread.id;
            threads.push(newThread);
        });

        return [new ModelCommentThreadCollection(threads), keyMap];
    }

    findById(threadId: ModelCommentThreadId): ModelCommentThread | undefined {
        return this.commentThreads.find(({ id }) => id === threadId);
    }

    isEqual(other: ModelCommentThreadCollection): boolean {
        return this.count() === other.count() && this.list().every(({ id }) => other.findById(id));
    }

    filter(cb: (commentThread: ModelCommentThread) => boolean): ModelCommentThreadCollection {
        return new ModelCommentThreadCollection(this.commentThreads.filter(cb));
    }

    isEmpty(): boolean {
        return this.commentThreads.length === 0;
    }

    map<T>(fn: (value: ModelCommentThread, index: number) => T): T[] {
        return this.commentThreads.map(fn);
    }
}
