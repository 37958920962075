import { LinkJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/model-contents/{modelId}/links/{linkId}/LinkJSON';

export class LinkName {
    readonly value: string;

    constructor(value: string) {
        this.value = value;
    }

    static load(dump: LinkJSON['name']): LinkName {
        return new LinkName(dump);
    }

    static empty(): LinkName {
        return new LinkName('');
    }

    trim(): LinkName {
        return new LinkName(this.value.trim());
    }

    dump(): LinkJSON['name'] {
        return this.value;
    }

    isEquals(other: LinkName): boolean {
        return other instanceof LinkName && other.value == this.value;
    }

    toString(): string {
        return this.value;
    }
}
