import { Component, createRef } from 'react';
import { HandleD3Drag } from '@view-model/models/common/d3/HandleD3Drag';

type OneWayResizerProps = {
    offsetX: number;
    offsetY: number;
    handleResize: (dx: number, dy: number, dWidth: number, dHeight: number) => void;
    handleResizeStart: () => void;
    handleResizeEnd: () => void;
    cursor: string;
};

type OneWayResizeState = unknown;
type EventProps = { x: number; y: number; dx: number; dy: number };

abstract class AbstractResizeView<Props, State> extends Component<
    OneWayResizerProps & Props,
    OneWayResizeState & State
> {
    abstract myRef: React.RefObject<SVGGElement>;

    constructor(props: OneWayResizerProps & Props) {
        super(props);
    }

    componentDidMount(): void {
        const handleD3Drag = new HandleD3Drag(this.myRef);
        handleD3Drag.onMounted(this.onDrag.bind(this), this.onDragStart.bind(this), this.onDragEnd.bind(this));
    }

    componentWillUnmount(): void {
        const handleD3Drag = new HandleD3Drag(this.myRef);
        handleD3Drag.onWillUnmounted();
    }

    public abstract onDrag(event: EventProps): void;

    private onDragStart() {
        this.props.handleResizeStart();
    }

    private onDragEnd() {
        this.props.handleResizeEnd();
    }
}

type RectResizerProps = {
    width: number;
    height: number;
    containerWidth: number;
    containerHeight: number;
};

type RectResizerState = unknown;

abstract class AbstractRectResizeView extends AbstractResizeView<RectResizerProps, RectResizerState> {
    myRef: React.RefObject<SVGRectElement> = createRef<SVGRectElement>();
    render(): React.ReactNode {
        const { width, height, offsetX, offsetY, containerWidth, containerHeight } = this.props;

        return (
            <>
                {/* 色付きの矩形(直線)領域 */}
                <rect
                    className="fill-brand"
                    width={width}
                    height={height}
                    transform={`translate(${offsetX - width / 2}, ${offsetY - height / 2})`}
                />
                {/* 透明の Dragger 領域を上にかぶせる */}
                <rect
                    fill="transparent"
                    ref={this.myRef}
                    width={containerWidth}
                    height={containerHeight}
                    transform={`translate(${offsetX - containerWidth / 2}, ${offsetY - containerHeight / 2})`}
                    cursor={this.props.cursor}
                />
            </>
        );
    }
}

type CircleResizerProps = {
    r: number;
};

type CircleResizerState = unknown;

abstract class AbstractCircleResizeView extends AbstractResizeView<CircleResizerProps, CircleResizerState> {
    myRef: React.RefObject<SVGCircleElement> = createRef<SVGCircleElement>();
    render(): React.ReactNode {
        return (
            <circle
                ref={this.myRef}
                className="fill-brand"
                r={this.props.r}
                transform={`translate(${this.props.offsetX}, ${this.props.offsetY})`}
                cursor={this.props.cursor}
            />
        );
    }
}

export class RightResizeView extends AbstractRectResizeView {
    onDrag({ dx }: EventProps): void {
        this.props.handleResize(0, dx, 0, 0);
    }
}

export class LeftResizeView extends AbstractRectResizeView {
    onDrag({ x }: EventProps): void {
        this.props.handleResize(0, 0, 0, x);
    }
}

export class BottomResizeView extends AbstractRectResizeView {
    onDrag({ dy }: EventProps): void {
        this.props.handleResize(0, 0, dy, 0);
    }
}

export class TopResizeView extends AbstractRectResizeView {
    onDrag({ y }: EventProps): void {
        this.props.handleResize(y, 0, 0, 0);
    }
}

export class RightTopResizeView extends AbstractCircleResizeView {
    onDrag({ dx, y }: EventProps): void {
        this.props.handleResize(y, dx, 0, 0);
    }
}

export class RightBottomResizeView extends AbstractCircleResizeView {
    onDrag({ dx, dy }: EventProps): void {
        this.props.handleResize(0, dx, dy, 0);
    }
}

export class LeftBottomResizeView extends AbstractCircleResizeView {
    onDrag({ x, dy }: EventProps): void {
        this.props.handleResize(0, 0, dy, x);
    }
}

export class LeftTopResizeView extends AbstractCircleResizeView {
    onDrag({ x, y }: EventProps): void {
        this.props.handleResize(y, 0, 0, x);
    }
}
