import { UserKey } from '@user/domain';
import { ClientMouseCursorJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-viewer-contents/{viewModelId}/client-mouse-cursors/{cursorId}/ClientMouseCursorJSON';
import { ClientId } from '@schema-common/base';
import { UnixTimestampMilliSeconds } from '@schema-common/timestamp';

export class ClientMouseCursor {
    public readonly id: ClientId;
    public readonly x: number;
    public readonly y: number;
    public readonly userKey: UserKey;
    public readonly userName: string;
    public readonly userImageURL: string | null;
    public readonly updatedAt: UnixTimestampMilliSeconds | undefined;

    constructor(
        id: ClientId,
        x: number,
        y: number,
        userKey: UserKey,
        userName: string,
        userImageURL: string | null,
        updatedAt?: number
    ) {
        this.id = id;
        this.x = x;
        this.y = y;
        this.userKey = userKey;
        this.userName = userName;
        this.userImageURL = userImageURL;
        this.updatedAt = updatedAt;
    }

    dump(): ClientMouseCursorJSON {
        return {
            id: this.id,
            x: this.x,
            y: this.y,
            userKey: this.userKey.dump(),
            userName: this.userName,
            userImageURL: this.userImageURL || null,
            updatedAt: this.updatedAt,
        };
    }

    static load(dump: ClientMouseCursorJSON): ClientMouseCursor {
        return new ClientMouseCursor(
            dump.id,
            dump.x,
            dump.y,
            UserKey.load(dump.userKey),
            dump.userName,
            dump.userImageURL,
            dump.updatedAt
        );
    }
}
