import { ModelKey, ViewKey } from '@view-model/domain/key';
import { ModelId, ViewId } from '@schema-common/base';
import { ViewName, ViewSetting } from '@view-model/domain/view';
import { Model } from '@framework/domain';
import { Point, Rect, Size } from '@view-model/models/common/basic';
import { Timestamp } from '@framework/Timestamp';
import { ViewJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/views/{viewId}/ViewJSON';

interface IView {
    key: ViewKey;
    modelKey: ModelKey;
    name: ViewName;
    size: Size;
    setting: ViewSetting;
    order: number;
    createdAt: Timestamp;
}

type ViewCreateParameters = {
    modelKey: ModelKey;
    name: ViewName;
    size: Size;
    setting: ViewSetting;
    order?: number;
    createdAt?: Timestamp;
};

export class ViewEntity extends Model<ViewKey> {
    public readonly key: ViewKey;
    public readonly modelKey: ModelKey;
    private readonly _name: ViewName;
    private readonly _size: Size;
    private readonly _setting: ViewSetting;
    private readonly _order: number;
    private readonly _createdAt: Timestamp;

    public constructor(attributes: IView) {
        super();
        this.key = attributes.key;
        this.modelKey = attributes.modelKey;
        this._name = attributes.name;
        this._size = attributes.size;
        this._setting = attributes.setting;
        this._order = attributes.order;
        this._createdAt = attributes.createdAt;
    }

    public static buildNew(attributes: ViewCreateParameters): ViewEntity {
        return new ViewEntity({
            key: ViewKey.buildNew(),
            modelKey: attributes.modelKey,
            name: attributes.name,
            size: attributes.size,
            setting: attributes.setting,
            order: attributes.order || Timestamp.now().toUnixTimestamp(),
            createdAt: attributes.createdAt || Timestamp.now(),
        });
    }

    public dump(): ViewJSON {
        return {
            key: this.key.toString(),
            modelKey: this.modelKey.toString(),
            name: this.name.value,
            size: this.size.dump(),
            setting: this.setting.dump(),
            order: this.order,
            createdAt: this.createdAt.toUnixTimestamp(),
        };
    }

    public static load(j: ViewJSON): ViewEntity {
        return new ViewEntity({
            key: new ViewKey(j.key),
            modelKey: new ModelKey(j.modelKey),
            name: ViewName.load(j.name),
            size: Size.load(j.size),
            setting: j.setting ? ViewSetting.load(j.setting) : ViewSetting.buildNew(),
            order: j.order,
            createdAt: new Timestamp(j.createdAt),
        });
    }

    get id(): ViewId {
        return this.key.id as ViewId;
    }

    get modelId(): ModelId {
        return this.modelKey.modelID;
    }

    public clone(): ViewEntity {
        return ViewEntity.load(this.dump());
    }

    public cloneNew(modelKeyMap: Record<string, ModelKey>): ViewEntity {
        return ViewEntity.load(
            Object.assign(this.dump(), {
                key: ViewKey.buildNew().toString(),
                modelKey: modelKeyMap[this.modelKey.toString()].toString(),
                createdAt: Timestamp.now().toISOString(),
            })
        );
    }

    public isEqual(other: ViewEntity): boolean {
        return (
            other instanceof ViewEntity &&
            this.key.isEqual(other.key) &&
            this.name.isEquals(other.name) &&
            this.size.isEqual(other.size) &&
            this.setting.isEquals(other.setting) &&
            this.createdAt.isEqual(other.createdAt)
        );
    }

    public get name(): ViewName {
        return this._name;
    }

    public get size(): Size {
        return this._size;
    }

    public get setting(): ViewSetting {
        return this._setting;
    }

    public get order(): number {
        return this._order;
    }

    public get createdAt(): Timestamp {
        return this._createdAt;
    }

    public getRect(position: { x: number; y: number }): Rect {
        const point = new Point(position.x, position.y);
        return new Rect(point, this._size);
    }

    withName(name: ViewName): ViewEntity {
        return ViewEntity.load({ ...this.dump(), name: name.value });
    }

    withSize(size: Size): ViewEntity {
        return ViewEntity.load({ ...this.dump(), size: size.dump() });
    }

    withOrder(order: number): ViewEntity {
        return ViewEntity.load({ ...this.dump(), order });
    }

    private _comparisonValue(): string {
        const order = this.order;
        const createdAt = this.createdAt;
        return `${createdAt.valueOf()} ${order} `;
    }

    public compareTo(other: ViewEntity): number {
        const myComparison = this._comparisonValue();
        const otherComparison = other._comparisonValue();

        if (myComparison > otherComparison) return +1;
        if (myComparison < otherComparison) return -1;
        return 0;
    }
}
