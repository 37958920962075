import { ViewName, ViewEntity, ViewSetting } from '@view-model/domain/view';
import { ViewPlacement } from './ViewPlacement';
import { Point, Rect, Size } from '@view-model/models/common/basic';
import { StickyModel, StickyModelContents } from '@view-model/domain/model';
import { CommandManager, CompositeCommand, CreateCommand } from '@model-framework/command';
import { ModelCascadeRepository } from '@view-model/infrastructure/view-model/cascade/ModelCascadeRepository';
import { ModelCreateCommand } from '@view-model/command/model/ModelCreateCommand';
import { PositionSet, PositionSetCreateCommand } from '@view-model/models/common/PositionSet';
import { ViewId, ModelId, GroupId, UserId, ViewModelId } from '@schema-common/base';
import { ViewsClipboardOperator, ViewsClipboardPayload } from '@view-model/application/clipboard';
import { ViewModelOperationLogSender } from '@view-model/models/framework/action-log';
import { RTDBPath, ObjectRepository } from '@framework/repository';

// Frame GridContainerのグリッドサイズ
const VIEW_GRID_SIZE = 512;

type CreateResult = {
    viewId: ViewId;
    modelId: ModelId;
    modelType: string;
};

export class ViewModelPageOperation {
    private readonly defaultViewPlacement = new ViewPlacement(VIEW_GRID_SIZE, 7, 5);

    constructor(
        private readonly groupId: GroupId,
        private readonly currentUserId: UserId,
        private readonly commandManager: CommandManager,
        private readonly viewModelId: ViewModelId,
        private readonly logSender: ViewModelOperationLogSender
    ) {}

    addStickyView(area: Rect): CreateResult {
        const model = StickyModel.buildNew();

        const viewId = this.addView(area, StickyModelContents.buildEmpty(model.key, model.version));

        return {
            viewId,
            modelId: model.id,
            modelType: model.type,
        };
    }

    private addView(area: Rect, model: StickyModelContents, placement?: ViewPlacement): ViewId {
        if (!placement) {
            placement = this.defaultViewPlacement;
        }
        const position = placement.chooseViewPosition(area);

        const view = ViewEntity.buildNew({
            name: new ViewName(''),
            modelKey: model.key,
            size: new Size(placement.viewWidth, placement.viewHeight),
            setting: ViewSetting.buildNew(),
        });

        const repository = new ObjectRepository(ViewEntity, RTDBPath.View.viewPath(this.viewModelId, view.id));
        const viewCreateCommand = new CreateCommand(view, repository);

        const viewPositionCreateCommand = new PositionSetCreateCommand(
            new PositionSet({ [view.id]: position }),
            RTDBPath.View.positionsPath(this.viewModelId)
        );

        const modelCascadeRepository = new ModelCascadeRepository(this.viewModelId);
        const modelCreateCommand = new ModelCreateCommand(modelCascadeRepository, model);

        const command = new CompositeCommand(modelCreateCommand, viewCreateCommand, viewPositionCreateCommand);
        this.commandManager.execute(command);

        return view.id;
    }

    async pasteViews(
        clipboard: ViewsClipboardPayload,
        visibleAreaCenterPoint: Point
    ): Promise<[Set<ViewId>, string | null]> {
        const { groupId, currentUserId, viewModelId, commandManager, logSender } = this;
        return ViewsClipboardOperator.paste(
            clipboard,
            visibleAreaCenterPoint,
            groupId,
            viewModelId,
            currentUserId,
            commandManager,
            logSender
        );
    }
}
