import { WorkspaceKeysJSON } from '@schema-app/users/{userKey}/group-workspace-keys/{groupKey}/WorkspaceKeysJSON';
import { WorkspaceKeyString } from '@schema-common/base';

/**
 * RTDB上の以下のようなキー集合を扱うためのオブジェクトです。
 * ```
 * {
 *   "Workspace:abc123": "N/A",
 *   "Workspace:def456": "N/A",
 * }
 * ```
 *
 * アクセス権限を考慮しながらスケールアウトするためのデータ構造として、
 * RTDBでは上記のような識別子の非正規化されたインデックスを利用します。
 */
export class WorkspaceKeys {
    constructor(private readonly keyStrings: Readonly<WorkspaceKeyString[]>) {}

    dump(): WorkspaceKeysJSON {
        return this.keyStrings.reduce((acc: WorkspaceKeysJSON, keyString) => {
            acc[keyString] = 'N/A';
            return acc;
        }, {});
    }

    static load(dump: WorkspaceKeysJSON | null): WorkspaceKeys {
        return new this(Object.keys(dump || {}));
    }

    ids(): string[] {
        return this.keyStrings.map((keyString) => keyString.replace('Workspace:', ''));
    }
}
