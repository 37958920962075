import { useCallback, useMemo } from 'react';
import { useUserPublicProfiles } from '@user/PublicProfile';
import { UserIcon } from '@framework/ui/atoms/UserIcon';
import { Loading } from '@framework/ui/atoms/Loading';
import { GroupMemberRole, GroupMemberRoles } from '@group/domain';

type Props = {
    currentUserId: string;
    memberRoles: GroupMemberRoles;
    selectedUserIds: string[];
    onChange(userIds: string[]): void;
};

export const MemberRemoveList: React.FC<Props> = ({ currentUserId, memberRoles, selectedUserIds, onChange }: Props) => {
    const userIds = useMemo(() => {
        return memberRoles.userIds().filter((id) => id !== currentUserId);
    }, [currentUserId, memberRoles]);
    const profiles = useUserPublicProfiles(userIds);

    const handleChange = useCallback(
        (userId: string, checked: boolean) => {
            if (checked) {
                onChange([...selectedUserIds, userId]);
            } else {
                onChange(selectedUserIds.filter((id) => id !== userId));
            }
        },
        [onChange, selectedUserIds]
    );

    if (!profiles) {
        return (
            <div className="py-4">
                <Loading />
            </div>
        );
    }

    return (
        <ul className="p-2">
            {profiles.map(({ id: userId, name, imageUrl }) => {
                const role = memberRoles.getRoleOf(userId);
                if (!role) return null;

                return (
                    <MemberItem
                        key={userId}
                        userId={userId}
                        name={name}
                        imageUrl={imageUrl}
                        role={role}
                        checked={selectedUserIds.includes(userId)}
                        onChange={handleChange}
                    />
                );
            })}
        </ul>
    );
};

type ItemProps = {
    userId: string;
    name: string;
    imageUrl: string | null;
    role: GroupMemberRole;
    checked: boolean;
    onChange(userId: string, checked: boolean): void;
};

const MemberItem: React.FC<ItemProps> = ({ userId, name, imageUrl, role, checked, onChange }: ItemProps) => {
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(userId, event.target.checked);
        },
        [onChange, userId]
    );

    return (
        <li className="flex flex-row items-center p-2 hover:bg-gray-200">
            <label className="flex grow cursor-pointer flex-row items-center truncate">
                <input
                    type="checkbox"
                    className="form-checkbox ml-1 mr-2 size-4 cursor-pointer border-brand"
                    checked={checked}
                    onChange={handleChange}
                />
                <UserIcon name={name} id={userId} imageUrl={imageUrl} size="medium" />
                <span className="grow truncate px-2">{name}</span>
            </label>
            <span className="w-24 shrink-0 rounded-sm bg-gray-300 text-center">
                {GroupMemberRole.getLocalString(role)}
            </span>
        </li>
    );
};
