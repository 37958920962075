import { ModelKey } from '@view-model/domain/key';
import { ModelCommentThreadCollection } from './ModelCommentThreadCollection';
import {
    IPositionSetRepository,
    PositionSet,
    PositionSetJSON,
    PositionSetUpdateCommand,
} from '@view-model/models/common/PositionSet';
import { ModelCommentThread } from './ModelCommentThread';
import { ModelCommentThreadId } from '@schema-common/base';
import { Id } from '@framework/domain';
import { ICommand } from '@model-framework/command';
import { ModelCommentsDeleteCommand, ModelCommentThreadRepository } from '@view-model/models/sticky/ModelComment';
import { assertIsDefined, Point } from '@view-model/models/common/basic';
import { ModelCommentThreadJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/model-contents/{modelId}/model-comment-threads/{modelCommentThreadId}/ModelCommentThreadJSON';

export type ModelCommentContentsJSON = {
    modelKey: string;
    threads: ModelCommentThreadJSON[];
    positions: PositionSetJSON;
};

export class ModelCommentContents {
    constructor(
        public readonly modelKey: ModelKey,
        public readonly threads: ModelCommentThreadCollection,
        public readonly positions: PositionSet
    ) {
        // 位置情報が存在するコメントスレッドのみを扱う
        this.threads = threads.filter((thread) => {
            return !!positions.find(thread.id);
        });
    }

    dump(): ModelCommentContentsJSON {
        const { modelKey, threads, positions } = this;
        return {
            modelKey: modelKey.toString(),
            threads: threads.dump(),
            positions: positions.dump(),
        };
    }

    static load(dump: ModelCommentContentsJSON): ModelCommentContents {
        const { modelKey, threads, positions } = dump;
        return new this(
            new ModelKey(modelKey),
            ModelCommentThreadCollection.load(threads),
            PositionSet.load(positions)
        );
    }

    static buildEmpty(modelKey: ModelKey): ModelCommentContents {
        return new this(modelKey, new ModelCommentThreadCollection([]), new PositionSet());
    }

    cloneNew(newModelKey: ModelKey): ModelCommentContents {
        const [newThreadCollection, newKeyMap] = this.threads.cloneNew();
        return new ModelCommentContents(newModelKey, newThreadCollection, this.positions.cloneNew(newKeyMap));
    }

    allIds(): ModelCommentThreadId[] {
        return this.threads.allIds();
    }

    isEqual(other: ModelCommentContents): boolean {
        return (
            this.modelKey.isEqual(other.modelKey) &&
            this.threads.isEqual(other.threads) &&
            this.positions.isEqual(other.positions)
        );
    }

    filter(cb: (commentThread: ModelCommentThread) => boolean): ModelCommentContents {
        const threads = this.threads.filter(cb);
        return new ModelCommentContents(this.modelKey, threads, this.positions.subset(threads.allIds()));
    }

    filterByIds(ids: Id[]): ModelCommentContents {
        return this.filter(({ id }) => ids.includes(id));
    }

    count(): number {
        return this.threads.count();
    }

    isEmpty(): boolean {
        return this.threads.isEmpty();
    }

    move(dx: number, dy: number): ModelCommentContents {
        return new ModelCommentContents(this.modelKey, this.threads, this.positions.moveAll(dx, dy));
    }

    buildMoveCommand(dx: number, dy: number, repository: IPositionSetRepository): ICommand | undefined {
        const { positions } = this;

        if (positions.isEmpty()) return;

        return new PositionSetUpdateCommand(positions, positions.moveAll(dx, dy), repository);
    }

    buildDeleteCommand(
        modelCommentThreadRepository: ModelCommentThreadRepository,
        commentPositionSetRepository: IPositionSetRepository
    ): ICommand | null {
        if (this.isEmpty()) return null;

        return new ModelCommentsDeleteCommand(this, modelCommentThreadRepository, commentPositionSetRepository);
    }

    contents(): { threads: ModelCommentThreadCollection; positions: PositionSet } {
        return { threads: this.threads, positions: this.positions };
    }

    entries(): [ModelCommentThread, Point][] {
        return this.threads.map((thread) => {
            const position = this.positions.find(thread.id);
            assertIsDefined(position);
            return [thread, position as Point];
        });
    }

    map<T>(fn: (value: [ModelCommentThread, Point], index: number) => T): T[] {
        return this.entries().map(fn);
    }

    forEach(fn: (value: [ModelCommentThread, Point], index: number) => void): void {
        return this.entries().forEach(fn);
    }

    getPosition(id: ModelCommentThreadId): Point | undefined {
        return this.positions.find(id);
    }
}
