import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faChartBar, faCog, faExpand, faFileSignature, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useGroupAnalysisSettingAvailability } from '@group/hooks/useGroupAnalysisSettingAvailability';
import { GroupId, ViewId, ViewModelId } from '@schema-common/base';
import { useNotifyHuddleMessageHandler } from '@view-model/application/notification';
import { ViewSetting } from '@view-model/domain/view';
import { Rect, Size } from '@view-model/models/common/basic';
import { ForeignObjectAutosize2 } from '@view-model/models/common/components/ForeignObjectAutosize';
import { useViewOperation } from '@view-model/ui/components/View/ViewContext';
import { ViewSettingModal } from '@view-model/ui/components/View/ViewSettingModal';
import { MenuButton } from '@view-model/ui/components/View/ViewTitleMenu/MenuButton';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AssetUploadModal } from '../AssetUploadModal';
import { useAtomValue } from 'jotai';
import { contentTransformAtom } from '@user/pages/ViewModelPage/contentTransformAtom';

type Props = {
    isOpen: boolean;
    viewRect: Rect;
    viewModelId: ViewModelId;
    viewId: ViewId;
    groupId: GroupId;
    showNodeCreatedUser: boolean;
    onDelete(): void;
    onFocusView(): void;
    onAnalysisSelect(): void;
    onClose(): void;
};

const TOP_MARGIN = -16;
const RIGHT_MARGIN = 64;

export const ViewPopupMenu: React.FC<Props> = (props: Props) => {
    const {
        isOpen,
        viewRect,
        viewModelId,
        viewId,
        groupId,
        showNodeCreatedUser,
        onDelete,
        onFocusView,
        onAnalysisSelect,
    } = props;
    const [popupMenuSize, setPopupMenuSize] = useState<Size>(Size.empty);
    const popupMenuPosition = viewRect.topRight().addXY(-popupMenuSize.width - RIGHT_MARGIN, 0);

    // ビューの設定
    const [showSettingModal, setShowSettingModal] = useState(false);
    const rootNode = useRef<HTMLElement>(document.querySelector('#root'));
    const handleOpenSettingModal = useCallback(() => {
        setShowSettingModal(true);
    }, []);
    const handleCloseSettingModal = useCallback(() => setShowSettingModal(false), []);

    const viewAdapter = useViewOperation();
    const handleShowNodeCreatedUserChanged = useCallback(
        async (checked: boolean) => {
            const setting = new ViewSetting(checked);
            await viewAdapter.handleSettingChanged(setting);
        },
        [viewAdapter]
    );

    // 強制的に参加者を集める
    const notifyHuddle = useNotifyHuddleMessageHandler(viewModelId, viewId);
    const handleHuddle = useCallback(() => {
        notifyHuddle();
    }, [notifyHuddle]);

    // 機械学習APIで分析（表示可否がグループごとに設定）
    const showAnalysisSelect = useGroupAnalysisSettingAvailability(groupId);

    const propsRef = useRef<Props>(props);
    propsRef.current = props;

    useEffect(() => {
        // ポップアップメニューを表示した場合、どこかをクリックしたら閉じるようにする（メニューをクリックした場合も含めて）
        if (rootNode.current) {
            const node = rootNode.current;
            const handleClick = () => {
                if (propsRef.current.isOpen) {
                    propsRef.current.onClose();
                }
            };
            node.addEventListener('click', handleClick);

            return () => {
                node.removeEventListener('click', handleClick);
            };
        }
    }, []);

    // 画像アップロード
    const [showMediaUploadModal, setShowMediaUploadModal] = useState(false);
    const handleImageUpload = useCallback(() => setShowMediaUploadModal(true), []);
    const closeImageUpload = useCallback(() => setShowMediaUploadModal(false), []);

    const contentTransform = useAtomValue(contentTransformAtom);
    const scale = (1 / contentTransform.k) * 0.3; // メニューを常に同じ大きさで表示するために補正する
    const matrix = new DOMMatrix()
        .translateSelf(popupMenuPosition.x, popupMenuPosition.y)
        .scaleSelf(scale, scale, 1, popupMenuSize.width, 0)
        .translateSelf(0, TOP_MARGIN);

    return (
        <>
            {isOpen && (
                <ForeignObjectAutosize2 transform={matrix.toString()} onSizeChange={setPopupMenuSize}>
                    <div
                        className="flex flex-col rounded-2xl bg-white py-4 text-5xl shadow-lg"
                        style={{
                            visibility: popupMenuSize.isEmpty() ? 'hidden' : 'visible',
                        }}
                    >
                        <MenuButton icon={faCog} title="設定 | Settings" onClick={handleOpenSettingModal} />
                        <MenuButton icon={faExpand} title="中央に表示 | Centered" onClick={onFocusView} />
                        <MenuButton icon={faUsers} title="参加者を集める | Gathering" onClick={handleHuddle} />
                        {showAnalysisSelect && (
                            <MenuButton icon={faChartBar} title="分析(α) | Analyze" onClick={onAnalysisSelect} />
                        )}
                        <MenuButton
                            icon={faFileSignature}
                            title="画像アップロード | Upload image"
                            onClick={handleImageUpload}
                        />
                        <MenuButton icon={faTrashAlt} title="削除 | Delete" onClick={onDelete} color="red" />
                    </div>
                </ForeignObjectAutosize2>
            )}

            <ViewSettingModal
                isOpen={showSettingModal}
                showNodeCreatedUser={showNodeCreatedUser}
                onClose={handleCloseSettingModal}
                onShowNodeCreatedUserChanged={handleShowNodeCreatedUserChanged}
            />

            {showMediaUploadModal && (
                <AssetUploadModal
                    groupId={groupId}
                    viewModelId={viewModelId}
                    viewRect={viewRect}
                    onClose={closeImageUpload}
                />
            )}
        </>
    );
};
