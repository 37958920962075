import { RTDBPath } from '@framework/repository';
import { CommandManager, CompositeCommand, ICommand } from '@model-framework/command';
import { DisplayOrderDeleteCommand, DisplayOrderTree, DraggingElements } from '@model-framework/display-order';
import { DisplayOrderRepository } from '@model-framework/display-order/infrastructure';
import { LinkColor, LinkLineStyle, LinkMarkStyle } from '@model-framework/link';
import { FontSize } from '@model-framework/text';
import { DescriptionPanelId, GroupId, NodeId, StickyZoneId, UserId } from '@schema-common/base';
import { UserKey } from '@user/domain';
import { SelectedItemsOperation } from '@view-model/adapter';
import {
    ApplicationClipboardPayload,
    ImageClipboardPayload,
    StickyModelClipboardPayload,
    TSVClipboardPayload,
    TextClipboardPayload,
    isImageClipboardPayload,
    isStickyModelClipboardPayload,
    isTSVClipboardPayload,
    isTextClipboardPayload,
} from '@view-model/application/clipboard';
import { ElementsMoveCommand } from '@view-model/command/basic-model/ElementsMoveCommand/ElementsMoveCommand';
import { LinkKey, LinkableTargetKey, ModelElementId, StickyZoneKey } from '@view-model/domain/key';
import {
    ModelElementPositionMap,
    StickyModel,
    StickyModelContents,
    StickyModelElementSelector,
} from '@view-model/domain/model';
import { ViewModelAsset, ViewModelAssetCollectionForClone, ViewModelEntity } from '@view-model/domain/view-model';
import { StickyModelElementPositionMapRepository } from '@view-model/infrastructure/basic-model/StickyModelElementPositionMapRepository';
import { IPositionSetRepository, PositionSet, PositionSetRepository } from '@view-model/models/common/PositionSet';
import { Point, Rect, SelectedItemSet, SizeSet } from '@view-model/models/common/basic';
import { ThemeColor } from '@view-model/models/common/color';
import { Position } from '@view-model/models/common/types/ui';
import { DescriptionPanelCollectionContents } from '@view-model/models/sticky/DescriptionPanel';
import { DescriptionPanelCreationOperation } from '@view-model/models/sticky/DescriptionPanel/adapter';
import {
    CreatingModelComment,
    ModelCommentOperation,
    ModelCommentContents,
    ModelCommentThreadRepository,
    ModelCommentsCreateCommand,
} from '@view-model/models/sticky/ModelComment';
import {
    LinkCollection,
    LinkEntityOperation,
    LinkRepository,
    LinkStyleSet,
    LinkStyleUpdateMultiCommand,
    StickyLinkCreationOperation,
} from '@view-model/models/sticky/StickyLink';
import { LinkMultiDeleteCommand, LinkReverseCommand } from '@view-model/models/sticky/StickyLink/command';
import { StickyNodeDescriptionSet } from '@view-model/models/sticky/StickyNodeDescription';
import {
    NodeCollection,
    NodeEntityOperation,
    NodeRepository,
    NodeStyleSet,
    NodeStyleUpdateMultiCommand,
    StickyNode,
    StickyNodeCreationOperation,
} from '@view-model/models/sticky/StickyNodeView';
import {
    StickyZone,
    StickyZoneCollection,
    StickyZoneCreationOperation,
    StickyZoneEntityOperation,
    StickyZoneRepository,
    StickyZoneStyleSet,
    StickyZoneStyleUpdateMultiCommand,
} from '@view-model/models/sticky/StickyZoneView';
import { ModelLayout } from '@view-model/models/sticky/layout';
import { NewElementStyleProvider } from '@view-model/models/sticky/user-context';
import { FixedCellSizeTextTable, PastePositionAdjuster } from '@view-model/ui/components/Model';
import copy from 'copy-to-clipboard';
import { StickyLinkReplacementOperation } from '../models/sticky/StickyLink/adapter';
import { MultiSelectionMode } from '@user/pages/ViewModelPage';

const MaxPasteTSVCount = 100;
const MaxPasteTextLength = 100;

type SelectedIdSet = SelectedItemSet<ModelElementId>;

export class StickyModelContentsOperation {
    // NOTE: 外から更新できるようにしている
    public panelContents: DescriptionPanelCollectionContents = DescriptionPanelCollectionContents.buildEmpty();
    public commentContents: ModelCommentContents;
    public nodeDescriptionSet: StickyNodeDescriptionSet;
    public displayOrderTree: DisplayOrderTree = new DisplayOrderTree();

    // 内部に状態を持つのを避けたいが一旦ここに選択状態を持たせる
    private selectedItems: SelectedIdSet = SelectedItemSet.from([]);

    private readonly modelCommentOperation: ModelCommentOperation;
    private readonly modelCommentThreadRepository: ModelCommentThreadRepository;
    private readonly commentPositionSetRepository: IPositionSetRepository;
    private readonly descriptionPanelPositionSetRepository: IPositionSetRepository;
    private readonly selectedItemsOperation: SelectedItemsOperation;
    private readonly linkCreationOperation: StickyLinkCreationOperation;
    private readonly linkReplacementOperation: StickyLinkReplacementOperation;
    private readonly zoneCreationOperation: StickyZoneCreationOperation;
    private readonly pastePositionAdjuster: PastePositionAdjuster;
    private readonly nodeCreationOperation: StickyNodeCreationOperation;

    constructor(
        private readonly groupId: GroupId,
        private readonly viewModel: ViewModelEntity,
        private readonly model: StickyModel,
        private readonly currentUserId: UserId,
        private readonly nodeRepository: NodeRepository,
        private readonly linkRepository: LinkRepository,
        private readonly zoneRepository: StickyZoneRepository,
        private readonly commandManager: CommandManager,
        private readonly nodeEntityOperation: NodeEntityOperation,
        private readonly linkEntityOperation: LinkEntityOperation,
        private readonly zoneEntityOperation: StickyZoneEntityOperation,
        private readonly positionMapRepository: StickyModelElementPositionMapRepository,
        private readonly displayOrderRepository: DisplayOrderRepository,
        private readonly styleProvider: NewElementStyleProvider
    ) {
        this.commentContents = ModelCommentContents.buildEmpty(model.key);
        this.selectedItemsOperation = new SelectedItemsOperation(this.viewModel.id, this.model.id);
        this.linkCreationOperation = new StickyLinkCreationOperation(
            this.viewModel,
            this.linkEntityOperation,
            this.commandManager
        );
        this.linkReplacementOperation = new StickyLinkReplacementOperation(
            this.viewModel,
            this.linkEntityOperation,
            this.commandManager
        );
        this.zoneCreationOperation = new StickyZoneCreationOperation(
            this.viewModel.id,
            this.model,
            this.currentUserId,
            this.zoneEntityOperation,
            this.commandManager,
            this.displayOrderRepository
        );

        this.modelCommentThreadRepository = new ModelCommentThreadRepository(this.viewModel.id, this.model.id);

        this.commentPositionSetRepository = new PositionSetRepository(
            RTDBPath.Comment.positionsPath(this.viewModel.id, this.model.id)
        );

        this.modelCommentOperation = new ModelCommentOperation(
            this.modelCommentThreadRepository,
            this.commentPositionSetRepository,
            this.commandManager
        );

        this.descriptionPanelPositionSetRepository = new PositionSetRepository(
            RTDBPath.DescriptionPanel.positionsPath(this.viewModel.id, this.model.id)
        );

        this.nodeDescriptionSet = StickyNodeDescriptionSet.fromArray([]);

        this.nodeCreationOperation = new StickyNodeCreationOperation(
            this.currentUserId,
            this.nodeEntityOperation,
            this.commandManager,
            this.displayOrderRepository
        );

        const { width: nodeWidth, height: nodeHeight } = StickyNode.size();
        this.pastePositionAdjuster = new PastePositionAdjuster(nodeWidth / 2, nodeHeight / 2);
    }

    deleteMySelection(): void {
        this.selectedItemsOperation.deleteMySelection();
        this.selectedItems = SelectedItemSet.from([]);
    }

    /**
     * 指定の要素間を接続するリンクを作成する
     * @param fromKey
     * @param toKey
     */
    createLink(fromKey: LinkableTargetKey, toKey: LinkableTargetKey): LinkKey | undefined {
        return this.linkCreationOperation.createLink(fromKey, toKey, this.styleProvider.linkStyle());
    }

    replaceLink(
        linkKey: LinkKey,
        currentKeys: { sourceKey: LinkableTargetKey; targetKey: LinkableTargetKey },
        newKeys: { sourceKey: LinkableTargetKey; targetKey: LinkableTargetKey }
    ): void {
        return this.linkReplacementOperation.replaceLink(linkKey, currentKeys, newKeys);
    }

    private createElements(modelContents: StickyModelContents, parentZoneId?: StickyZoneId): void {
        const elementsCreateCommand = modelContents.buildElementsCreateCommand(
            this.viewModel,
            this.model,
            this.nodeEntityOperation,
            this.linkEntityOperation,
            this.zoneEntityOperation,
            this.displayOrderRepository,
            parentZoneId
        );

        const commentCreateCommand = new ModelCommentsCreateCommand(
            this.commentContents,
            this.modelCommentThreadRepository,
            this.commentPositionSetRepository
        );

        const descriptionPanelsCreateCommand = modelContents.buildDescriptionPanelsCreateCommand(
            this.viewModel.id,
            this.model.id
        );

        this.commandManager.execute(
            new CompositeCommand(elementsCreateCommand, commentCreateCommand, descriptionPanelsCreateCommand)
        );
    }

    async deleteSelectedElements(): Promise<void> {
        const nodes = this.selectedNodes();
        const zones = this.selectedZones();

        const nodesDeleteCommand = await nodes.buildDeleteCommand(this.nodeRepository);
        const zoneDeleteCommand = await zones.buildDeleteCommand(this.viewModel.id, this.model.id);

        const displayOrderDeleteCommand = DisplayOrderDeleteCommand.build(
            nodes.ids(),
            zones.ids(),
            this.displayOrderRepository
        );

        const linkDeleteCommand = this.selectedLinks()
            .addList(LinkEntityOperation.findLinksByNodeKeys(nodes.keys(), this.viewModel))
            .addList(LinkEntityOperation.findLinksByZoneKeys(zones.keys(), this.viewModel))
            .buildDeleteCommand(this.viewModel, this.linkEntityOperation);

        const commentDeleteCommand = this.selectedComments().buildDeleteCommand(
            this.modelCommentThreadRepository,
            this.commentPositionSetRepository
        );

        const descriptionPanelDeleteCommand = await this.selectedDescriptionPanel().buildDeleteCommand(
            this.viewModel.id,
            this.model.id
        );

        const command = CompositeCommand.composeOptionalCommands(
            nodesDeleteCommand,
            zoneDeleteCommand,
            linkDeleteCommand,
            displayOrderDeleteCommand,
            commentDeleteCommand,
            descriptionPanelDeleteCommand
        );

        if (command) {
            this.commandManager.execute(command);
            this.deselectAll();
        }
    }

    /**
     * 適切な位置にゾーンを追加し、そのゾーンを選択する
     *
     *  * 選択されている要素があれば、それの外接領域の左上からオフセットした位置に作成
     *  * 選択要素がなければ、ビューの中のランダムな位置に作成
     */
    createZone(position: Point): StickyZoneId {
        const zoneId = this.zoneCreationOperation.createZone(position, this.styleProvider.zoneStyle());
        this.selectOnly(zoneId);

        return zoneId;
    }

    private selectedComments(): ModelCommentContents {
        return this.commentContents.filterByIds(this.selectedItems.getSelectedItems());
    }

    private selectedDescriptionPanel(): DescriptionPanelCollectionContents {
        return this.panelContents.filterByIds(this.selectedItems.getSelectedItems());
    }

    private buildMoveNodeZoneCommand(dx: number, dy: number): ICommand | undefined {
        const positions = this.selectedModelElementPositionMap();

        if (positions.isEmpty()) return;

        return new ElementsMoveCommand(positions, positions.movePosition(dx, dy), this.positionMapRepository);
    }

    /**
     * 選択済みの要素を dx, dy だけ移動させる
     *
     * @param dx
     * @param dy
     */
    moveSelectedElements(dx: number, dy: number): void {
        const elementsMoveCommand = this.buildMoveNodeZoneCommand(dx, dy);
        const modelCommentCommand = this.selectedComments().buildMoveCommand(dx, dy, this.commentPositionSetRepository);
        const descriptionPanelsMoveCommand = this.selectedDescriptionPanel().buildMoveCommand(
            dx,
            dy,
            this.descriptionPanelPositionSetRepository
        );

        const command = CompositeCommand.composeOptionalCommands(
            elementsMoveCommand,
            modelCommentCommand,
            descriptionPanelsMoveCommand
        );

        if (command) {
            this.commandManager.execute(command);
        }
    }

    /**
     * 矩形領域に交差する/含まれるモデル要素を選択する
     */
    selectElementsByRect({
        selectionRect,
        zoneId,
        commentSizeSet,
    }: {
        selectionRect: Rect;
        zoneId?: StickyZoneId;
        commentSizeSet: SizeSet;
    }): void {
        const { displayOrderTree } = this;
        let selectedIds = this.getElementIdsByRect({
            rect: selectionRect,
            panelContents: this.panelContents,
            commentSizeSet,
        });
        if (zoneId) {
            // ゾーンの領域内から矩形領域の選択を開始したときにはそのゾーンを選択対象から除外する
            // ref: https://docs.google.com/presentation/d/1HPSNsBQj5gsRvoLocKoOSyx2Uc-TGDJDU6XWiwXgtKs/edit
            const removedZoneIds = displayOrderTree.ancestors(zoneId);
            removedZoneIds.push(zoneId);
            selectedIds = selectedIds.filter((id) => !removedZoneIds.includes(id));
        }
        this.addToSelectionMany(selectedIds);
    }

    /**
     * ドラッグして拡縮ゾーンの矩形領域に新たに含まれる/外れる Node と Zone の Id 一覧を返す
     */
    getChangeParentElementIdsByNewZoneRect(
        selectionRect: Rect,
        zoneId: StickyZoneId
    ): { removedElementIds: (NodeId | StickyZoneId)[]; addedElementIds: (NodeId | StickyZoneId)[] } {
        const allNodes = this.model.nodeEntities();
        const allNodePositions = new NodeCollection(allNodes).positionSet();
        const allLinks = this.model.linkEntities();
        const allZones = this.model.zones.models;
        const allZonePositions = this.model.zonePositions;
        const shapeMap = this.model.nodeZoneShapeMap();

        const selector = new StickyModelElementSelector(
            allNodes,
            allNodePositions,
            allLinks,
            allZones,
            allZonePositions,
            shapeMap
        );

        const { nodes, zones } = selector.selectByRect(selectionRect);

        // includeRect の判定に必要なので、elements の rect と id のペアを作成
        const nodeRects = nodes.map((node) => ({
            id: node.id,
            rect: new Rect(node.position, node.size()),
        }));
        const zoneRects = zones.map((zone) => ({
            id: zone.id,
            rect: new Rect(allZonePositions.find(zone.id) as Point, zone.size),
        }));

        // selectByRect で取得した elements は矩形と交差しているものをすべて取り出すので、厳密に矩形に含まれるようになる elements だけに絞り込む
        const nodesIncludedByRect = nodeRects.filter((nodeRect) => selectionRect.includeRect(nodeRect.rect));
        const zonesIncludedByRect = zoneRects.filter((zoneRect) => selectionRect.includeRect(zoneRect.rect));

        let selectedElementIds = [
            ...nodesIncludedByRect.map((node) => node.id),
            ...zonesIncludedByRect.map((zone) => zone.id),
        ];

        // 新しいゾーンの矩形から外れた element の Id
        const removedElementIds = this.displayOrderTree
            .addZoneDescendantIds([zoneId])
            .filter((id) => !selectedElementIds.includes(id))
            // 親として指定ゾーン以外を持つ elements を除去
            .filter((id) => this.displayOrderTree.ancestors(id).filter((id) => id !== zoneId).length === 0);

        // 親を持たない elements だけを取り出す
        selectedElementIds = selectedElementIds.filter((id) => !this.displayOrderTree.ancestors(id).length);
        // 新しいゾーンの矩形に追加された element の Id
        const addedElementIds = selectedElementIds.filter((id) => id != zoneId);

        return { removedElementIds, addedElementIds };
    }

    /**
     * 矩形領域に交差する/含まれるモデル要素の Id 一覧を返す
     */
    private getElementIdsByRect({
        rect,
        panelContents,
        commentSizeSet,
    }: {
        rect: Rect;
        panelContents: DescriptionPanelCollectionContents;
        commentSizeSet: SizeSet;
    }): ModelElementId[] {
        return [
            ...this.selectIdsByRect(rect),
            ...ModelCommentOperation.getModelCommentThreadIdsByRect({
                rect,
                commentContents: this.commentContents,
                commentSizeSet,
            }),
            ...panelContents.filterByRectSelection(rect).allIds(),
        ];
    }

    private selectIdsByRect(rect: Rect): ModelElementId[] {
        const nodes = this.model.nodeEntities();
        const nodePositions = new NodeCollection(nodes).positionSet();
        const links = this.model.linkEntities();
        const zones = this.model.zones.models;
        const zonePositions = this.model.zonePositions;
        const shapeMap = this.model.nodeZoneShapeMap();

        const selector = new StickyModelElementSelector(nodes, nodePositions, links, zones, zonePositions, shapeMap);
        return selector.selectIdsByRect(rect);
    }

    /**
     * 選択中の要素から構成される StickyModelContents を返します。
     *
     * モデルとして不整合になるようなリンク（例えばリンクだけ選択されている場合のリンク）はStickyModelContentsから
     * 除外され、別途コレクションとして返されます。
     */
    private buildSelectedContents(): { contents: StickyModelContents; excludedLinks: LinkCollection } {
        const { model, displayOrderTree } = this;

        return StickyModelContents.fromFragments(
            model.key,
            model.version,
            this.selectedNodes(),
            this.selectedLinks(),
            this.selectedZones(),
            this.selectedZonePositions(),
            this.selectedComments(),
            this.selectedDescriptionPanel(),
            this.selectedNodeDescriptions(),
            displayOrderTree
        );
    }

    /**
     * 選択中の要素のID配列を返します
     */
    getSelectedIds(): ModelElementId[] {
        return this.selectedItems.getSelectedItems();
    }

    /**
     * 選択中の要素があるかどうかを返します。
     */
    hasSelectedElements(): boolean {
        return this.selectedItems.getSelectedItems().length > 0;
    }

    /**
     * 指定した位置に存在するモデル要素のうち一番手前にあるものを返す
     *
     * @param targetPosition
     */
    findForegroundElementByPosition(targetPosition: Position): StickyNode | StickyZone | null {
        return this.model.findForegroundElementByPosition(Point.fromPosition(targetPosition), this.displayOrderTree);
    }

    /**
     * クリックした位置にノードを作成します。
     * @param clickedPosition
     */
    createNodeAtClickedPosition(clickedPosition: Point): NodeId {
        // クリックした位置を中心としてノードを配置する
        const position = StickyNode.getPositionFromCenter(clickedPosition);
        return this.createNode(Point.fromPosition(position));
    }

    /**
     * ゾーン内のクリックされた位置にノードを追加します。同時にゾーンの親子関係も作ります。
     * @param clickedPosition
     * @param zoneKey
     */
    createNodeToZone(clickedPosition: Point, zoneKey: StickyZoneKey): NodeId {
        const nodeId = this.nodeCreationOperation.createNodeWithCenterPositionAndAddToZone(
            this.viewModel,
            clickedPosition,
            this.styleProvider.nodeStyle(),
            zoneKey
        );
        this.selectOnly(nodeId);

        return nodeId;
    }

    /**
     * 指定された位置にノードを作成し、そのノードを選択した上でそのIDを返します。
     * @param position
     * @private
     */
    createNode(position: Point): NodeId {
        const nodeId = this.nodeCreationOperation.createNode(this.viewModel, position, this.styleProvider.nodeStyle());
        this.selectOnly(nodeId);

        return nodeId;
    }

    // ============================================================
    // 要素の選択状態
    // ============================================================

    /**
     * 全ての選択を解除する
     */
    deselectAll(): void {
        this.selectedItemsOperation.deselectAll(this.selectedItems);
        this.selectedItems = SelectedItemSet.from([]);
    }

    /**
     * 指定されたアイテムの選択状態を逆転する (選択済みであれば選択解除、未選択であれば選択する)
     * @param id
     */
    toggleSelection(id: ModelElementId): void {
        if (this.selectedItems.getSelectedItems().includes(id)) {
            this.selectedItemsOperation.deselect(id, this.selectedItems);
            this.selectedItems = this.selectedItems.deselect(id);
        } else {
            this.selectedItemsOperation.addToSelection(id, this.selectedItems);
            this.selectedItems = this.selectedItems.addToSelection(id);
        }
    }

    /**
     * 既存のアイテムの選択を全て解除して、渡されたアイテムを選択する
     * @param id
     */
    selectOnly(id: ModelElementId): void {
        this.selectedItemsOperation.selectOnly(id, this.selectedItems);
        this.selectedItems = SelectedItemSet.from([id]);
    }

    /**
     * アイテムを追加で選択する
     * @param id
     */
    addToSelection(id: ModelElementId): void {
        this.selectedItemsOperation.addToSelection(id, this.selectedItems);
        this.selectedItems = this.selectedItems.addToSelection(id);
    }

    /**
     * 複数のアイテムを追加で選択する
     * @param ids
     */
    addToSelectionMany(ids: ModelElementId[]): void {
        this.selectedItemsOperation.addToSelectionMany(ids, this.selectedItems);
        this.selectedItems = this.selectedItems.addToSelectionMany(ids);
    }

    /**
     * 全てのアイテムを選択する
     */
    selectAll(): void {
        const panelIds = this.panelContents.panels.allIds();
        const threadIds = this.commentContents.allIds();
        this.addToSelectionMany([...this.model.elementIds(), ...threadIds, ...panelIds]);
        this.selectedItems = SelectedItemSet.from([...this.model.elementIds(), ...threadIds, ...panelIds]);
    }

    /**
     * ドラッグ開始時に要素の選択状態を適切に変更する。
     *
     *  - ドラッグの対象要素が選択済みならば、既存の選択状態を変更しない
     *  - 対象要素が非選択状態のとき、
     *     - 複数選択モードであれば、対象要素を追加選択
     *     - そうでなければ、対象要素のみを単一選択
     *
     * @param id ドラッグ移動で掴んでいる対象要素の識別子
     * @param multiSelectionMode 複数選択モード
     */
    selectForDragStart(id: ModelElementId, multiSelectionMode: MultiSelectionMode): void {
        if (this.selectedItems.getSelectedItems().includes(id)) {
            return;
        }

        if (multiSelectionMode.isReadyForMultiSelection || multiSelectionMode.isMultiElementsSelectionMode) {
            this.addToSelection(id);
        } else {
            this.selectOnly(id);
        }
    }

    // ============================================================
    // 選択中要素の操作
    // ============================================================
    private selectedNodes(): NodeCollection {
        return this.model.getNodes(this.selectedItems.getSelectedItems());
    }

    private selectedZones(): StickyZoneCollection {
        return this.model.getZones(this.selectedItems.getSelectedItems());
    }

    private selectedZonePositions(): PositionSet {
        return this.model.getZonePositions(this.selectedItems.getSelectedItems());
    }

    private selectedLinks(): LinkCollection {
        return this.model.getLinks(this.selectedItems.getSelectedItems());
    }

    private selectedNodeDescriptions(): StickyNodeDescriptionSet {
        return this.nodeDescriptionSet.filterByModelElementIds(this.selectedItems.getSelectedItems());
    }

    changeSelectedNodeZoneThemeColor(themeColor: ThemeColor): void {
        const command = CompositeCommand.composeOptionalCommands(
            this.selectedNodeThemeColorUpdateCommand(themeColor),
            this.selectedZoneThemeColorUpdateCommand(themeColor)
        );

        if (command) {
            this.commandManager.execute(command);

            // 変更された要素の種類に応じて最終変更情報を更新する
            if (!this.selectedNodes().isEmpty()) this.styleProvider.nodeColorChanged(themeColor);
            if (!this.selectedZones().isEmpty()) this.styleProvider.zoneColorChanged(themeColor);
        }
    }

    private selectedNodeThemeColorUpdateCommand(themeColor: ThemeColor): NodeStyleUpdateMultiCommand | undefined {
        const styleSet = this.selectedNodes().styleSet();
        return this.nodeStylesUpdateCommand(styleSet, styleSet.withThemeColor(themeColor));
    }

    private selectedZoneThemeColorUpdateCommand(themeColor: ThemeColor): StickyZoneStyleUpdateMultiCommand | undefined {
        const styleSet = this.selectedZones().styleSet();
        return this.zoneStylesUpdateCommand(styleSet, styleSet.withThemeColor(themeColor));
    }

    changeSelectedNodeZoneFontSize(fontSize: FontSize): void {
        const command = CompositeCommand.composeOptionalCommands(
            this.selectedNodeFontSizeUpdateCommand(fontSize),
            this.selectedZoneFontSizeUpdateCommand(fontSize)
        );

        if (command) this.commandManager.execute(command);
    }

    private selectedNodeFontSizeUpdateCommand(fontSize: FontSize): NodeStyleUpdateMultiCommand | undefined {
        const styleSet = this.selectedNodes().styleSet();
        return this.nodeStylesUpdateCommand(styleSet, styleSet.withFontSize(fontSize));
    }

    private selectedZoneFontSizeUpdateCommand(fontSize: FontSize): StickyZoneStyleUpdateMultiCommand | undefined {
        const styleSet = this.selectedZones().styleSet();
        return this.zoneStylesUpdateCommand(styleSet, styleSet.withFontSize(fontSize));
    }

    private nodeStylesUpdateCommand(from: NodeStyleSet, to: NodeStyleSet): NodeStyleUpdateMultiCommand | undefined {
        if (from.isEqual(to)) return;

        return new NodeStyleUpdateMultiCommand(from, to, this.nodeRepository);
    }

    private zoneStylesUpdateCommand(
        from: StickyZoneStyleSet,
        to: StickyZoneStyleSet
    ): StickyZoneStyleUpdateMultiCommand | undefined {
        if (from.isEqual(to)) return;

        return new StickyZoneStyleUpdateMultiCommand(from, to, this.zoneRepository);
    }

    changeSelectedLinkLineStyles(lineStyle: LinkLineStyle): void {
        const styleSet = this.selectedLinks().styleSet();
        this.changeLinkStyles(styleSet, styleSet.withLineStyle(lineStyle));
        this.styleProvider.linkLineStyleChanged(lineStyle);
    }

    changeSelectedLinkMarkStyles(markStyle: LinkMarkStyle): void {
        const styleSet = this.selectedLinks().styleSet();
        this.changeLinkStyles(styleSet, styleSet.withMarkStyle(markStyle));
        this.styleProvider.linkMarkStyleChanged(markStyle);
    }

    changeReverseLinks(): void {
        const links = this.selectedLinks().entities();
        const command = new CompositeCommand(
            ...links.map((link) => {
                return new LinkReverseCommand(this.viewModel, this.linkEntityOperation, link);
            })
        );
        this.commandManager.execute(command);
    }

    changeSelectedLinkColors(color: LinkColor): void {
        const styleSet = this.selectedLinks().styleSet();
        this.changeLinkStyles(styleSet, styleSet.withColor(color));
        this.styleProvider.linkColorChanged(color);
    }

    private changeLinkStyles(from: LinkStyleSet, to: LinkStyleSet): void {
        if (from.isEqual(to)) return;

        const command = new LinkStyleUpdateMultiCommand(from, to, this.linkRepository);
        this.commandManager.execute(command);
    }

    /**
     * 選択されているノードとゾーンをグルーピング（ゾーンを作成してその中に追加）します。
     */
    async groupSelectedElements(): Promise<string | undefined> {
        const zoneId = await this.zoneCreationOperation.createZoneFor(
            this.selectedNodes(),
            this.selectedZones(),
            this.selectedZonePositions(),
            this.styleProvider.zoneStyle()
        );

        if (!zoneId) return;

        this.selectOnly(zoneId);

        return zoneId;
    }

    /**
     * 選択中の要素からLinkだけ削除する
     */
    removeMultiSelectedLinks(): void {
        const selectedLinks = this.selectedLinks().entities();
        const command = new LinkMultiDeleteCommand(this.viewModel, this.linkEntityOperation, selectedLinks);
        this.commandManager.execute(command);
    }

    // ============================================================
    // モデルコメントの操作
    // ============================================================

    /**
     * モデルコメント・スレッドの作成
     * @param creatingModelComment
     */
    async createModelCommentThread(creatingModelComment: CreatingModelComment): Promise<void> {
        await this.modelCommentOperation.createThread(creatingModelComment);
    }

    /**
     * 現在選択中ノードのドロップ先ゾーンを探す
     */
    findDropTargetZone(): StickyZone | null {
        const ids = this.droppableSelectedIds();
        if (ids.length === 0) return null;

        return this.model.findDropTargetZone(ids, this.displayOrderTree);
    }

    // ゾーンに対してドロップ可能な選択中要素IDのリストを返す
    private droppableSelectedIds(): ModelElementId[] {
        return [...this.selectedNodes().ids(), ...this.selectedZones().ids()];
    }

    private draggingElements(): DraggingElements | null {
        const ids = this.droppableSelectedIds();
        if (ids.length === 0) return null;

        const { displayOrderTree } = this;

        const draggingElementIds = displayOrderTree.minimizeElementIdsForDrag(ids);
        const dropTargetZoneId = this.model.findDropTargetZone(draggingElementIds, displayOrderTree)?.id || null;

        return new DraggingElements(draggingElementIds, dropTargetZoneId);
    }

    /**
     * 選択要素をドラッグ＆ドロップしたものとして、親子関係変更のコマンドを生成します。
     */
    async buildSelectedElementsDropCommand(): Promise<ICommand | null> {
        const draggingElements = this.draggingElements();
        if (!draggingElements || !(await draggingElements.willChangeParent(this.displayOrderRepository))) return null;

        // 親子関係ができる場合はリンクを削除する
        const links = this.model.aggregateRemovingLinksBy(draggingElements, this.displayOrderTree);

        return CompositeCommand.composeOptionalCommands(
            draggingElements.buildChangeParentCommand(this.displayOrderRepository),
            ...links.deleteCommands(this.viewModel, this.linkEntityOperation)
        );
    }

    selectedModelElementPositionMap(): ModelElementPositionMap {
        const { displayOrderTree } = this;

        const ids = displayOrderTree.aggregateDescendantIds(this.selectedItems.getSelectedItems());

        const zonePositions = this.model.getZonePositions(ids);
        const nodes = this.model.getNodes(ids);

        return new ModelElementPositionMap(nodes.positionSet(), zonePositions);
    }

    /**
     * 選択中の要素をクリップボードにコピーし、コピーが成功したかどうかを返します。
     */
    copySelectedElements(): boolean {
        const { contents } = this.buildSelectedContents();
        if (contents.isEmpty()) {
            return false;
        }

        const payload = StickyModelClipboardPayload.fromContent(this.viewModel.id, contents);

        return copy(JSON.stringify(payload), {
            format: 'application/json',
            onCopy: (clipboardData) => {
                // 付箋のテキストのみをテキスト形式のクリップボードにコピーしてBalus以外で付箋テキストを利用できるようにする。
                // https://docs.google.com/presentation/d/1G-IXu_hY7xDagUH1kgsSfNpXt5DcI8xzRy1eSgFpfww/edit#slide=id.g1043591efc8_0_9

                // onCopyの引数の型がobjectとして定義されてしまっているためキャストし直す
                (clipboardData as DataTransfer).setData('text/plain', contents.exportNodesText());
            },
        });
    }

    /**
     * クリープボードの内容をビューに貼り付けます。
     * @return エラーの場合はエラー文字列。成功またはモデル内のコンテンツ形式ではないため貼り付けしなかった場合はnullを返します。
     */
    async pasteClipboardPayload(payload: ApplicationClipboardPayload, position: Point): Promise<string | null> {
        if (isStickyModelClipboardPayload(payload)) return this.pasteStickyModelClipboardPayload(payload, position);
        if (isTSVClipboardPayload(payload)) return this.pasteTSV(payload, position);
        if (isTextClipboardPayload(payload)) return this.pasteText(payload, position);
        if (isImageClipboardPayload(payload)) return this.pasteImage(payload, position);

        // ビューの貼り付けなどここで対応しないpayloadが渡ってきた場合はエラーではなく無視する
        // （別の場所でハンドルされるためエラーメッセージが出るとおかしい）
        return null;
    }

    /**
     * 同じモデルからの貼り付けかどうかや同じクリップボードからの貼り付け回数に応じて位置を調整して貼り付けます。
     */
    private async pasteStickyModelClipboardPayload(
        payload: StickyModelClipboardPayload,
        position: Point
    ): Promise<string | null> {
        const contents = StickyModelClipboardPayload.toContent(payload);

        // 復元したペイロード中に含まれる ViewModelAssets の情報を取得して複製する
        const assets = await ViewModelAssetCollectionForClone.fetchByUrlList(contents.assetUrls());
        const baseUrl = location.href;
        const [assetUrlMap, errorMessage] = await assets.cloneNew(
            this.groupId,
            this.viewModel.id,
            this.currentUserId,
            baseUrl
        );

        // ビューやビューモデルのように完成したモデルそのものを貼り付けるのと違い、自分の付箋・ゾーンとして貼り付ける。
        // 参考: https://github.com/levii/balus-app/pull/991#issuecomment-908317310
        const adjustedModelContents = this.pastePositionAdjuster.adjustElements(
            contents.cloneNew(assetUrlMap).updateNodeZoneCreatedUserKey(UserKey.buildFromId(this.currentUserId)),
            position,
            this.buildSelectedContents().contents.getUnionRect()
        );
        const pasteTargetZoneId = this.model.findPasteTargetZone(position, this.displayOrderTree)?.id;

        this.deselectAll();
        this.createElements(adjustedModelContents, pasteTargetZoneId);
        this.addToSelectionMany(adjustedModelContents.elementIds());

        return errorMessage;
    }

    /**
     * クリップボードペイロード中の画像をアップロードして、説明パネルを追加します。
     */
    private async pasteImage(payload: ImageClipboardPayload, position: Point): Promise<string | null> {
        if (payload.files.length > 10) {
            return '一度にアップロード可能な画像は10枚までです。';
        }

        const results = await Promise.all(
            payload.files.map(async (file) => {
                const asset = ViewModelAsset.buildFromFile(file, this.currentUserId);
                const result = await asset.upload(this.groupId, this.viewModel.id, file);
                if (result) {
                    return result;
                }

                const markdown = asset.imageMarkdown(this.groupId, this.viewModel.id, location.href);
                this.addDescriptionPanel(position, markdown);
            })
        );

        // アップロード時にエラーがあれば、(最初の)エラー文言を返す
        for (const result of results) {
            if (result) return result;
        }

        return null;
    }

    private pasteTSV(payload: TSVClipboardPayload, position: Point): string | null {
        const table = new FixedCellSizeTextTable(payload.data, StickyNode.size(), ModelLayout.GridSize);
        if (table.cellCount() > MaxPasteTSVCount) {
            return `貼り付け対象の要素数が上限（${MaxPasteTSVCount}個）を超えています。`;
        }

        const nodes = this.nodeCreationOperation.createNodesFromTable(
            this.viewModel,
            table,
            position,
            this.styleProvider.nodeStyle()
        );

        this.deselectAll();
        this.addToSelectionMany(nodes.ids());
        return null;
    }

    private pasteText(payload: TextClipboardPayload, position: Point): string | null {
        const text = payload.data;
        if (text.length > MaxPasteTextLength) {
            return `付箋貼り付け文字数の上限（${MaxPasteTextLength}）を超えています。`;
        }

        const isUrlString = (url: string): boolean => {
            try {
                new URL(url); // URLとして解釈できない文字列が渡された場合には例外を投げる
                return true;
            } catch {
                return false;
            }
        };

        const nodeId = this.nodeCreationOperation.createNode(
            this.viewModel,
            position,
            this.styleProvider.nodeStyle(),
            text,
            isUrlString(text) ? text : null
        );

        this.deselectAll();
        this.selectOnly(nodeId);
        return null;
    }

    /**
     * 選択中の要素を複製して貼り付けます。
     */
    duplicateSelectedElements(position: Point): void {
        const { contents } = this.buildSelectedContents();
        if (contents.isEmpty()) return;

        const payload = StickyModelClipboardPayload.fromContent(this.viewModel.id, contents);

        this.pasteStickyModelClipboardPayload(payload, position);
    }

    /**
     * 指定した位置に説明パネルを追加し、追加した説明パネルを選択します。
     */
    addDescriptionPanel(position: Point, description?: string): DescriptionPanelId | undefined {
        const panelId = DescriptionPanelCreationOperation.create({
            position,
            description,
            viewModelId: this.viewModel.id,
            modelId: this.model.id,
            commandManager: this.commandManager,
        });
        if (!panelId) return undefined;
        this.selectOnly(panelId);
        return panelId;
    }

    getCreatePoint(transformedVisibleAreaCenterPoint: Point): Point {
        const selectedRect = this.buildSelectedContents().contents.getUnionRect();
        const creatingContentBaseTopLeftPoint = selectedRect
            ? selectedRect.topLeft()
            : transformedVisibleAreaCenterPoint;
        return creatingContentBaseTopLeftPoint.addXY(StickyNode.size().width / 2, StickyNode.size().height / 2);
    }
}
