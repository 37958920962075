import { ViewJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/views/{viewId}/ViewJSON';

export class ViewSetting {
    public readonly showNodeCreatedUser: boolean;

    public constructor(showNodeCreatedUser: boolean) {
        this.showNodeCreatedUser = showNodeCreatedUser;
    }

    static buildNew(): ViewSetting {
        return this.load({
            showNodeCreatedUser: false,
        });
    }

    public dump(): ViewJSON['setting'] {
        return {
            showNodeCreatedUser: this.showNodeCreatedUser,
        };
    }

    static load(dump: ViewJSON['setting']): ViewSetting {
        const { showNodeCreatedUser } = dump;
        return new this(showNodeCreatedUser);
    }

    public isEquals(other: ViewSetting): boolean {
        return other instanceof ViewSetting && other.showNodeCreatedUser == this.showNodeCreatedUser;
    }
}
