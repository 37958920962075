import { Fragment } from 'react';
import { useFetchCommentThreads } from './useFetchCommentThreads';
import { CommentListItem } from './CommentListItem';
import { Loading } from '@framework/ui/atoms/Loading';
import { classNames } from '@framework/utils';
import { ViewCollection } from '@view-model/domain/view';
import { ViewModelId } from '@schema-common/base';

type Props = {
    viewModelId: ViewModelId;
    views: ViewCollection;
};

export const CommentList: React.FC<Props> = ({ viewModelId, views }: Props) => {
    const { commentThreads, firstLoading } = useFetchCommentThreads(viewModelId, views);
    const className = 'bg-white pointer-events-auto';

    if (firstLoading) {
        return (
            <div className={classNames(className, 'w-[280px] p-4')}>
                <Loading />
            </div>
        );
    }

    if (commentThreads.length === 0) {
        return <div className={classNames(className, 'w-[280px] p-4')}>コメントはありません | No comments yet</div>;
    }

    return (
        <div className={classNames(className, 'max-h-full w-[280px] overflow-y-scroll')}>
            {commentThreads.map((ct, index) => {
                const view = views.findById(ct.viewId);
                if (!view) return null;

                return (
                    <Fragment key={ct.id}>
                        <CommentListItem commentThread={ct.modelCommentThread} view={view} />
                        {commentThreads.length - 1 !== index && (
                            <div className="mx-4 border-b border-solid border-gray-300" />
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
};
