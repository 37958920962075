import { ColorMenu } from '@model-framework/color';
import {
    LinkColor,
    LinkLineStyle,
    LinkLineStyleMenuView,
    LinkMarkStyle,
    LinkMarkStyleMenuView,
    ReverseLinkButton,
} from '@model-framework/link';
import { StickyNodeGroupingMenuButton } from '@model-framework/menu';
import { FontSize, FontSizeMenu } from '@model-framework/text';
import { ThemeColor } from '@view-model/models/common/color';
import { LinkStyle } from '@view-model/models/sticky/StickyLink';
import { MenuSize, MultiSelectionMenuId } from '@view-model/models/sticky/ui';
// /StickyZoneViewや/StickyNodeView から読み込むと実行時エラーが出てしまうので、一段下の/domainから読み込む
import { NodeFontSize } from '@view-model/models/sticky/StickyNodeView/domain';
import { StickyZoneFontSize } from '@view-model/models/sticky/StickyZoneView/domain';
import { RemoveMultiSelectedLinksButton } from './RemoveMultiSelectedLinksButton';

type Props = {
    menuId: MultiSelectionMenuId;
    isOpen: boolean;
    currentFontSize?: FontSize;
    currentThemeColor?: ThemeColor;
    currentLinkLineStyle?: LinkLineStyle;
    currentLinkMarkStyle?: LinkMarkStyle;
    currentLinkColor?: LinkColor;
    onMenuOpen(): void;
    onMenuClose(): void;
    onFontSizeSelected(fontSize: FontSize): void;
    onThemeColorSelected(themeColor: ThemeColor): void;
    onGroupSelectedNodes(): void;
    onLinkLineStyleSelected(lineStyle: LinkLineStyle): void;
    onLinkMarkStyleSelected(markStyle: LinkMarkStyle): void;
    onReverseLinks(): void;
    onRemoveLinks(): void;
    onLinkColorSelected(color: LinkColor): void;
};

const fontSizeMenuItems = FontSize.values().filter(
    (fontSize) => NodeFontSize.include(fontSize) && StickyZoneFontSize.include(fontSize)
);

export const MultiSelectionMenuItem: React.FC<Props> = ({
    menuId,
    isOpen,
    currentFontSize,
    currentThemeColor,
    currentLinkLineStyle,
    currentLinkMarkStyle,
    currentLinkColor,
    onMenuOpen,
    onMenuClose,
    onFontSizeSelected,
    onThemeColorSelected,
    onGroupSelectedNodes,
    onLinkLineStyleSelected,
    onLinkMarkStyleSelected,
    onReverseLinks,
    onRemoveLinks,
    onLinkColorSelected,
}: Props) => {
    return (
        <>
            {menuId === MultiSelectionMenuId.FontSize && (
                <FontSizeMenu
                    currentFontSize={currentFontSize}
                    menuSize={MenuSize}
                    items={fontSizeMenuItems}
                    isOpen={isOpen}
                    onMenuClicked={isOpen ? onMenuClose : onMenuOpen}
                    onSelected={onFontSizeSelected}
                    text="テキストサイズ | Text size"
                />
            )}

            {menuId === MultiSelectionMenuId.ThemeColor && (
                <ColorMenu
                    currentColorName={currentThemeColor}
                    menuSize={MenuSize}
                    items={ThemeColor.menuItems()}
                    numColumns={4}
                    isOpen={isOpen}
                    onMenuClicked={isOpen ? onMenuClose : onMenuOpen}
                    onSelected={onThemeColorSelected}
                    text="カラー | Color"
                />
            )}

            {menuId === MultiSelectionMenuId.GroupStickyElements && (
                <StickyNodeGroupingMenuButton
                    menuSize={MenuSize}
                    onClick={() => {
                        // 他のメニューが開いていた場合閉じる
                        onMenuClose();
                        onGroupSelectedNodes();
                    }}
                    text="グループ化 | Group"
                />
            )}

            {menuId === MultiSelectionMenuId.RemoveMultiSelectedLinks && (
                <RemoveMultiSelectedLinksButton
                    menuSize={MenuSize}
                    onClick={onRemoveLinks}
                    text="リンクのみ削除 |  Delete links only"
                />
            )}

            {menuId === MultiSelectionMenuId.LinkLineStyle && (
                <LinkLineStyleMenuView
                    menuSize={MenuSize}
                    currentLineStyle={currentLinkLineStyle}
                    items={LinkStyle.lineStyles}
                    isOpen={isOpen}
                    onMenuClicked={isOpen ? onMenuClose : onMenuOpen}
                    onSelected={onLinkLineStyleSelected}
                    text="スタイル | Style"
                />
            )}

            {menuId === MultiSelectionMenuId.LinkMarkStyle && (
                <LinkMarkStyleMenuView
                    menuSize={MenuSize}
                    currentMarkStyle={currentLinkMarkStyle}
                    items={LinkStyle.markStyles}
                    isOpen={isOpen}
                    onMenuClicked={isOpen ? onMenuClose : onMenuOpen}
                    onSelected={onLinkMarkStyleSelected}
                    text="リンクのタイプ | Type"
                />
            )}

            {menuId === MultiSelectionMenuId.ReverseLink && (
                <ReverseLinkButton menuSize={MenuSize} onClick={onReverseLinks} text="反転 | Flip" />
            )}

            {menuId === MultiSelectionMenuId.LinkColor && (
                <ColorMenu
                    menuSize={MenuSize}
                    currentColorName={currentLinkColor}
                    items={LinkColor.menuItems()}
                    isOpen={isOpen}
                    onMenuClicked={isOpen ? onMenuClose : onMenuOpen}
                    onSelected={onLinkColorSelected}
                    text="カラー | Color"
                />
            )}
        </>
    );
};
