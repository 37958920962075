import { Timestamp } from '@framework/Timestamp';
import { GroupMemberInvitationStatus } from './GroupMemberInvitationStatus';
import { InvitationSenderGroup } from './InvitationSenderGroup';
import { InvitationSenderUser } from './InvitationSenderUser';
import { MemberInvitation } from './MemberInvitation';
import {
    GroupMemberInvitationJSON,
    GroupMemberInvitationJSONOnWrite,
} from '@schema-app/group-contents/{groupKey}/member-invitations/{groupMemberInvitationId}/GroupMemberInvitationJSON';
import { UserId } from '@schema-common/base';
import { Random } from '@framework/Random';
import { GroupEntity, GroupMemberRole } from '@group/domain';
import { UserData } from '@user/UserData';

export class GroupMemberInvitation {
    /**
     * グループへのメンバー招待状。
     * ある特定のグループに対する 1人のユーザ(メールアドレス)宛の招待を表す。
     */
    private constructor(
        readonly id: string,
        readonly senderUser: InvitationSenderUser,
        readonly senderGroup: InvitationSenderGroup,
        readonly invitation: MemberInvitation,
        readonly active: boolean,
        readonly createdAt: Timestamp,
        readonly updatedAt: Timestamp,
        readonly acceptedAt: Timestamp | null,
        readonly acceptedUserId: string | null,
        readonly expiresAt: Timestamp
    ) {}

    static buildNew(
        sender: UserData,
        group: GroupEntity,
        email: string,
        role: GroupMemberRole,
        message: string,
        baseUrl: string,
        active: boolean,
        createdAt: Timestamp,
        updatedAt: Timestamp,
        acceptedAt: Timestamp | null,
        acceptedUserId: string | null,
        expiresAt: Timestamp
    ): GroupMemberInvitation {
        return new GroupMemberInvitation(
            Random.generateRandomID(60),
            InvitationSenderUser.fromUserData(sender),
            InvitationSenderGroup.fromGroup(group),
            new MemberInvitation(email, role, message, baseUrl),
            true,
            createdAt,
            createdAt,
            null,
            null,
            expiresAt
        );
    }

    dump(): GroupMemberInvitationJSONOnWrite {
        const {
            id,
            senderUser,
            senderGroup,
            invitation,
            active,
            createdAt,
            updatedAt,
            acceptedAt,
            acceptedUserId,
            expiresAt,
        } = this;
        return {
            id,
            senderUser: senderUser.dump(),
            senderGroup: senderGroup.dump(),
            invitation: invitation.dump(),
            active,
            createdAt: createdAt.toUnixTimestamp(),
            updatedAt: updatedAt.toUnixTimestamp(),
            acceptedAt: acceptedAt ? acceptedAt.toUnixTimestamp() : null,
            acceptedUserId,
            expiresAt: expiresAt.toUnixTimestamp(),
        };
    }

    static load(dump: GroupMemberInvitationJSON): GroupMemberInvitation {
        return new this(
            dump.id,
            InvitationSenderUser.load(dump.senderUser),
            InvitationSenderGroup.load(dump.senderGroup),
            MemberInvitation.load(dump.invitation),
            dump.active,
            new Timestamp(dump.createdAt),
            new Timestamp(dump.updatedAt),
            dump.acceptedAt ? new Timestamp(dump.acceptedAt) : null,
            dump.acceptedUserId || null,
            new Timestamp(dump.expiresAt)
        );
    }

    get groupId(): string {
        return this.senderGroup.groupId;
    }

    get mailAddress(): string {
        return this.invitation.email;
    }

    get status(): GroupMemberInvitationStatus {
        // 招待を承諾済みであれば、 'accepted'
        if (this.acceptedAt) {
            return 'accepted';
        }

        // 招待を承諾しておらず、かつ、非active の場合は、招待が無効化されたので 'invalid'
        if (!this.active) {
            return 'invalid';
        }

        // 招待の有効期限が現在時刻よりも以前か否かで、期限切れ・期限内かを判定する
        return this.expiresAt.isBeforeNow() ? 'expired' : 'pending';
    }

    isActive(): boolean {
        return this.active;
    }

    isAccepted(): boolean {
        return this.acceptedUserId !== null;
    }

    isExpired(): boolean {
        return this.expiresAt.isBeforeNow();
    }

    isPending(): boolean {
        return this.status === 'pending';
    }

    static compareByCreatedAt(a: GroupMemberInvitation, b: GroupMemberInvitation): number {
        return a.createdAt.compareTo(b.createdAt);
    }

    /**
     * 招待状を受諾状態にして返します。
     */
    accept(userId: UserId, now: Timestamp = Timestamp.now()): GroupMemberInvitation {
        return GroupMemberInvitation.load({
            ...this.dump(),
            acceptedAt: now.toUnixTimestamp(),
            acceptedUserId: userId,
        });
    }
}
