import { useCallback, useEffect, useState } from 'react';
import { GroupMemberRole } from '@group/domain';
import { RoleSelect } from '../RoleSelect';
import * as EmailValidator from 'email-validator';
import { EmailAddressParser, EmailParseResult } from './EmailAddressParser';
import { classNames } from '@framework/utils';
import { MAX_INVITATION_COUNT } from './createGroupMemberInvitations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

type Props = {
    emails: EmailParseResult[];
    role: GroupMemberRole;
    disabled: boolean;
    onChangeRole(role: GroupMemberRole): void;
    onAddEmails(email: EmailParseResult[]): void;
    onRemoveEmail(email: string): void;
    onRemoveInvalidEmails(): void;
};

export const MemberInvitationEmailInput: React.FC<Props> = ({
    emails,
    role,
    disabled,
    onAddEmails,
    onRemoveEmail,
    onRemoveInvalidEmails,
    onChangeRole,
}: Props) => {
    const [value, setValue] = useState<string>('');
    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value), []);
    const [error, setError] = useState<string>('');
    const hasInvalidEmails = emails.some(({ valid }) => !valid);

    const handleAddEmail = useCallback(() => {
        if (value === '') return;

        if (emails.some(({ email }) => email === value)) {
            setError('このメールアドレスは既に指定されています。');
            return;
        }

        if (EmailValidator.validate(value)) {
            onAddEmails([{ email: value, valid: true }]);
            setValue('');
            setError('');
        } else {
            setError('正しいメールアドレスを入力してください。');
        }
    }, [value, onAddEmails, emails]);

    const handlePaste = useCallback(
        (event: React.ClipboardEvent<HTMLInputElement>) => {
            // 元々の貼り付けイベントを抑止する
            event.preventDefault();

            // クリップボードの情報を読み取り、区切り文字で分割・メールアドレスとしてのvalidation checkを行う
            const payload = event.clipboardData.getData('text/plain');
            const parsedEmails = EmailAddressParser.parse(payload);

            // 既存のメールアドレスを record にまとめておいて
            const presentEmails = emails.reduce((record: Record<string, boolean>, { email }) => {
                record[email] = true;
                return record;
            }, {});

            // 追加するメールアドレス一覧から、重複を取り除く
            const filteredEmails = parsedEmails.filter(({ email }) => {
                const alreadyExists = presentEmails[email];
                presentEmails[email] = true;
                return !alreadyExists;
            });

            onAddEmails(filteredEmails);
            setValue('');
            setError('');
        },
        [emails, onAddEmails]
    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' && value) {
                handleAddEmail();
            }
        },
        [value, handleAddEmail]
    );

    // 入力欄が空文字列に戻ったらエラーメッセージを解除する
    useEffect(() => {
        if (value === '') {
            setError('');
        }
    }, [value]);

    useEffect(() => {
        // FIXME: 件数が問題なくなってもこのエラーメッセージが消えないまま残る
        if (emails.length > MAX_INVITATION_COUNT) {
            setError(`メールアドレスは${MAX_INVITATION_COUNT}件までしか追加できません。`);
        }
    }, [emails.length]);

    return (
        <>
            <div className="flex flex-row justify-between rounded-sm border border-gray-500 p-1">
                <div className="flex grow flex-wrap">
                    {emails.map(({ email, valid }) => (
                        <Item key={email} email={email} valid={valid} onRemove={onRemoveEmail} />
                    ))}

                    <span className="m-2 grow">
                        <input
                            type="text"
                            className="w-full focus:outline-none"
                            value={value}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            onBlur={handleAddEmail}
                            onPasteCapture={handlePaste}
                            disabled={disabled}
                            autoFocus
                        />
                        {error && (
                            <span className="text-red-700">
                                <FontAwesomeIcon icon={faExclamationCircle} />
                                {error}
                            </span>
                        )}
                    </span>
                </div>
                <RoleSelect role={role} onChange={onChangeRole} disabled={disabled} />
            </div>
            {hasInvalidEmails && (
                <div className="flex flex-row justify-end p-1">
                    <button className="text-sm text-brand" onClick={onRemoveInvalidEmails}>
                        エラー項目を全て削除する
                    </button>
                </div>
            )}
        </>
    );
};

type ItemProps = {
    email: string;
    valid: boolean;
    onRemove(email: string): void;
};

const Item: React.FC<ItemProps> = ({ email, valid, onRemove }: ItemProps) => {
    const handleRemove = useCallback(() => onRemove(email), [email, onRemove]);

    return (
        <div className={classNames('m-1 items-center rounded-full px-4 py-1', valid ? 'bg-brand-light' : 'bg-red-300')}>
            {email}
            <span
                className={classNames(
                    'cursor-pointer pl-2',
                    valid ? 'text-brand hover:text-brand-dark' : 'text-red-700 hover:text-red-900'
                )}
                onClick={handleRemove}
            >
                <FontAwesomeIcon icon={faTimesCircle} />
            </span>
        </div>
    );
};
