import { Rect } from '@view-model/models/common/basic';
import { ViewerJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-viewer-contents/{viewModelId}/viewers/{clientId}/ViewerJSON';
import { Timestamp } from '@framework/Timestamp';
import { ClientId } from '@schema-common/base';

export class Viewer {
    constructor(
        readonly userId: string,
        readonly userName: string,
        readonly imageUrl: string | null,
        readonly clientId: ClientId,
        readonly rect: Rect,
        readonly timestamp: Timestamp
    ) {}

    dump(): ViewerJSON {
        const { userId, userName, imageUrl, clientId, rect, timestamp } = this;
        const { position, size } = rect.dump();
        return {
            userId,
            userName,
            imageUrl: imageUrl || null,
            clientId,
            position,
            size,
            timestamp: timestamp.toISOString(),
        };
    }

    static load(dump: ViewerJSON): Viewer {
        const { userId, userName, imageUrl, clientId, position, size, timestamp } = dump;
        return new this(userId, userName, imageUrl, clientId, new Rect(position, size), new Timestamp(timestamp));
    }

    static compareByTimestamp(a: Viewer, b: Viewer): number {
        return a.timestamp.compareTo(b.timestamp);
    }

    static compareByClientId(a: Viewer, b: Viewer): number {
        return a.clientId.localeCompare(b.clientId);
    }
}
