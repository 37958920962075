import { ConsistencyLinkKey, ViewKey } from '@view-model/domain/key';
import { ConsistencyLink } from '@view-model/domain/ConsistencyLink';
import { ViewCollection } from '@view-model/domain/view';
import { ConsistencyLinkJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/consistency-links/{consistencyLinkId}/ConsistencyLinkJSON';
import { ViewId } from '@schema-common/base';

export type ConsistencyLinkCollectionJSON = Record<string, ConsistencyLinkJSON>;

export class ConsistencyLinkCollection {
    private readonly models: Readonly<ConsistencyLink[]>;
    constructor(models: ConsistencyLink[] = []) {
        this.models = models;
    }

    static buildEmpty(): ConsistencyLinkCollection {
        return new ConsistencyLinkCollection();
    }

    map<U>(callbackfn: (value: ConsistencyLink, index: number, array: ConsistencyLink[]) => U): U[] {
        return this.toArray().map(callbackfn);
    }

    forEach(callbackfn: (value: ConsistencyLink, index: number, array: ConsistencyLink[]) => void): void {
        return this.toArray().forEach(callbackfn);
    }

    add(link: ConsistencyLink): ConsistencyLinkCollection {
        if (this.get(link.key)) {
            console.warn(`ConsistencyLink: ${link.key} is already exists in the collection.`);
            return this.remove(link.key).add(link);
        }

        return new ConsistencyLinkCollection([...this.models, link]);
    }

    update(link: ConsistencyLink): ConsistencyLinkCollection {
        if (!this.get(link.key)) {
            console.warn(`ConsistencyLink: ${link.key} is not exists in the collection, but updated.`);
        }

        return new ConsistencyLinkCollection([...this.models, link]);
    }

    remove(key: ConsistencyLinkKey): ConsistencyLinkCollection {
        return new ConsistencyLinkCollection(this.models.filter((link: ConsistencyLink) => !link.key.isEqual(key)));
    }

    get(key: ConsistencyLinkKey): ConsistencyLink | undefined {
        return this.models.find((link: ConsistencyLink) => link.key.isEqual(key));
    }

    getById(id: string): ConsistencyLink | undefined {
        return this.models.find((link: ConsistencyLink) => link.id === id);
    }

    toArray(): ConsistencyLink[] {
        return [...this.models];
    }

    dump(): ConsistencyLinkCollectionJSON {
        const dump: ConsistencyLinkCollectionJSON = {};
        this.models.forEach((link: ConsistencyLink) => {
            dump[link.id] = link.dump();
        });

        return dump;
    }

    static load(dump: ConsistencyLinkCollectionJSON): ConsistencyLinkCollection {
        const links = Object.values(dump).map((link) => ConsistencyLink.load(link));
        return new ConsistencyLinkCollection(links);
    }

    clone(): ConsistencyLinkCollection {
        return new ConsistencyLinkCollection(this.models.map((link: ConsistencyLink) => link.clone()));
    }

    cloneNew(viewKeyMap: Record<string, ViewKey>): ConsistencyLinkCollection {
        return new ConsistencyLinkCollection(this.models.map((link: ConsistencyLink) => link.cloneNew(viewKeyMap)));
    }

    /**
     * 与えられたViewCollectionで整合性が取れているか（ViewKeyが存在するか）をチェックし、整合性の取れていないリンクがあれば
     * そのコレクションを返す。
     *
     * @param views リンク対象のView集合
     */
    invalidLinksBy(views: ViewCollection): ConsistencyLinkCollection | undefined {
        const links = this.models.filter((consistencyLink: ConsistencyLink) => !consistencyLink.isValidBy(views));
        return links.length > 0 ? new ConsistencyLinkCollection(links) : undefined;
    }

    /**
     * 与えられたViewCollectionで整合性が取れているか（ViewKeyが存在するか）をチェックし、整合性の取れているリンクがあれば
     * そのコレクションを返す。
     *
     * @param views リンク対象のView集合
     */
    validLinksBy(views: ViewCollection): ConsistencyLinkCollection | undefined {
        const links = this.models.filter((consistencyLink: ConsistencyLink) => consistencyLink.isValidBy(views));
        return links.length > 0 ? new ConsistencyLinkCollection(links) : undefined;
    }

    existLinkRelatedTo(viewKey: ViewKey): boolean {
        const links = this.models.filter((consistencyLink: ConsistencyLink) => consistencyLink.isLinkTo(viewKey));
        return links.length > 0;
    }

    filterByViewKey(viewKey: ViewKey): ConsistencyLinkCollection {
        const links = this.models.filter((link: ConsistencyLink) => link.isLinkTo(viewKey));
        return new ConsistencyLinkCollection(links);
    }

    filterByViewId(viewId: ViewId): ConsistencyLinkCollection {
        const viewKey = ViewKey.buildFromID(viewId);
        const links = this.models.filter((link: ConsistencyLink) => link.isLinkTo(viewKey));
        return new ConsistencyLinkCollection(links);
    }

    filterbyViewIds(viewIds: ViewId[]): ConsistencyLinkCollection {
        const viewKeys = viewIds.map((id) => ViewKey.buildFromID(id));

        const links = this.models.filter((link: ConsistencyLink) => viewKeys.some((key) => link.isLinkTo(key)));

        return new ConsistencyLinkCollection(links);
    }
}
