import { newFolderId } from './FolderId';
import { FolderId, WorkspaceId } from '@schema-common/base';
import { FolderJSON } from '@schema-app/workspace-contents/{workspaceKey}/folders/{folderId}/FolderJSON';

export class Folder {
    constructor(
        public readonly id: FolderId,
        public readonly name: string,
        public readonly workspaceId: WorkspaceId
    ) {}

    static buildNew(name: string, workspaceId: WorkspaceId): Folder {
        return new Folder(newFolderId(), name, workspaceId);
    }

    static compare(a: Folder, b: Folder): number {
        return a.compareTo(b);
    }

    compareTo(other: Folder): number {
        const name = this.name.toLowerCase();
        const otherName = other.name.toLowerCase();
        return name.localeCompare(otherName);
    }

    withName(name: string): Folder {
        return new Folder(this.id, name, this.workspaceId);
    }

    dump(): FolderJSON {
        const { id, name, workspaceId } = this;
        return {
            id,
            name,
            workspaceId,
        };
    }

    static load(dump: FolderJSON): Folder {
        const { id, name, workspaceId } = dump;
        return new Folder(id, name, workspaceId);
    }
}
