import { useCallback, useMemo, useState } from 'react';
import { FlatButton } from '@framework/ui/atoms';
import { Modal } from '@framework/ui/elements';
import { GroupEntity, GroupMemberRole } from '@group/domain';
import { GroupMemberEditTab, MenuName } from '../GroupMemberEditTab';
import { MemberInvitationForm } from './MemberInvitationForm';
import { EmailParseResult } from './EmailAddressParser';
import { SignUpMethodName } from '@group/domain/GroupEntity';
import { createGroupMemberInvitations, MAX_INVITATION_COUNT } from './createGroupMemberInvitations';
import { UserData } from '@user/UserData';

type Props = {
    isOpen: boolean;
    onClose(): void;
    groupEntity: GroupEntity;
    sender: UserData;
    onClickMenu(menu: MenuName): void;
};

export const MemberInvitationModal: React.FC<Props> = ({
    isOpen,
    onClose,
    groupEntity,
    sender,
    onClickMenu,
}: Props) => {
    const [emails, setEmails] = useState<EmailParseResult[]>([]);
    const [role, setRole] = useState<GroupMemberRole>('member');
    const [message, setMessage] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const hasInvalidAddress = useMemo(() => emails.some(({ valid }) => !valid), [emails]);
    const sendable = emails.length > 0 && emails.length <= MAX_INVITATION_COUNT && !hasInvalidAddress;

    const handleClose = useCallback(() => {
        setEmails([]);
        setRole('member');
        setMessage('');
        setLoading(false);
        onClose();
    }, [onClose]);

    const handleInvite = useCallback(async () => {
        if (!sendable) {
            alert('不正なメールアドレスが含まれているか件数が多すぎるため、招待を行うことはできません。');
            return;
        }

        setLoading(true);

        const emailAddresses = emails.map(({ email }) => email);
        if (await createGroupMemberInvitations(groupEntity, sender, emailAddresses, role, message)) {
            handleClose();
        } else {
            alert('グループメンバーの招待に失敗しました。もう一度試してみるか、サポート担当者にお問合せください。');
        }
    }, [emails, groupEntity, handleClose, message, role, sendable, sender]);

    const availableSignUpMethods = groupEntity.setting.availableSignUpMethods;

    return (
        <Modal isOpen={isOpen} onClose={onClose} className="flex flex-col">
            {/* header */}
            <div className="mb-1 p-4 text-2xl font-bold">メンバーを招待</div>

            {/* tab */}
            <GroupMemberEditTab current="invitations" showDeleteButton={false} onClickMenu={onClickMenu} />

            {/* content */}
            <div className="overflow-y-scroll px-4 py-2" style={{ maxHeight: 'calc(85vh - 160px)' }}>
                <MemberInvitationForm
                    emails={emails}
                    role={role}
                    message={message}
                    disabled={loading}
                    onChangeEmails={setEmails}
                    onChangeRole={setRole}
                    onChangeMessage={setMessage}
                />

                {/* 新規ユーザ登録時に利用可能なログイン方法が制限されているときには、注意書きを表示する。 */}
                {availableSignUpMethods.someRestricted() && (
                    <div className="pt-1 text-sm">
                        ※ 招待された方が新規ユーザ登録時に利用可能な方法は、以下のとおりです。
                        <ul className="list-inside list-disc px-6">
                            {SignUpMethodName.values().map((name) => (
                                <li key={name}>
                                    {SignUpMethodName.getLocalString(name)} (
                                    {availableSignUpMethods.availableOf(name) ? '利用可能' : '利用不可'})
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            {/* footer */}
            <hr className="my-2 w-full" />
            <div className="flex justify-end px-8 py-4">
                <FlatButton onClick={handleClose} disabled={loading}>
                    閉じる
                </FlatButton>
                <FlatButton
                    onClick={handleInvite}
                    color="brand"
                    className="ml-2"
                    disabled={!sendable || loading}
                    loading={loading}
                >
                    招待を送信
                </FlatButton>
            </div>
        </Modal>
    );
};
