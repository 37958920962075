import { DraggableSVGGElement } from '@model-framework/ui';
import { ViewId, ViewModelId } from '@schema-common/base';
import { ViewName } from '@view-model/domain/view';
import { Rect } from '@view-model/models/common/basic';
import { useD3DblClickCallback } from '@view-model/models/common/hooks';
import { useCallback, useRef, useState } from 'react';
import { EditableViewTitle } from '../ViewTitleInput/EditableViewTitle';
import { ViewTitleMenu } from '../ViewTitleMenu';
import { useAtomValue } from 'jotai';
import { viewTitleBarAtom } from '../viewTitlaBarAtom';

type Props = {
    viewRect: Rect;
    name: ViewName;
    viewId: ViewId;
    viewModelId: ViewModelId;
    onClick(): void;
    onCopyView(): void;
    onOpenPopupMenu(): void;
    onDragStart(viewId: ViewId): void;
    onDrag(dx: number, dy: number): void;
    onDragEnd(): void;
};

export const EditableTitleBar: React.FC<Props> = ({
    viewModelId,
    viewRect,
    name,
    viewId,
    onClick,
    onCopyView,
    onOpenPopupMenu,
    onDragStart,
    onDrag,
    onDragEnd,
}: Props) => {
    //------------------------------------------------------------------------------
    // タイトル編集
    //------------------------------------------------------------------------------
    const [isEditing, setIsEditing] = useState(false);
    const ref = useRef<SVGRectElement | null>(null);

    const startEdit = useCallback(() => setIsEditing(true), []);
    useD3DblClickCallback(ref, startEdit, true);

    const handleOnNameEndEdit = useCallback(() => setIsEditing(false), []);

    const { height, shouldHideMenuButtons, menu, margin } = useAtomValue(viewTitleBarAtom);

    const handleDragStart = useCallback(() => {
        onDragStart(viewId);
    }, [onDragStart, viewId]);

    return (
        <>
            <DraggableSVGGElement
                position={viewRect.topLeft()}
                onDragStart={handleDragStart}
                onDrag={onDrag}
                onDragEnd={onDragEnd}
                onClick={onClick}
            >
                <rect
                    ref={ref}
                    className="fill-brand"
                    width={viewRect.width}
                    height={height}
                    rx={height / 2}
                    ry={height / 2}
                    x={0}
                    y={-height - margin * 2}
                    stroke="none"
                    cursor="move"
                    style={{ pointerEvents: isEditing ? 'none' : 'auto' }}
                />
                <foreignObject
                    width={viewRect.width}
                    height={height}
                    transform={`translate(0, ${-height - margin * 2})`}
                    className="pointer-events-none"
                >
                    <div className={'flex items-center gap-8 px-16 text-[80px]'} style={{ height }}>
                        {!shouldHideMenuButtons && <div className="shrink-0" style={{ width: menu.containerWidth }} />}
                        <EditableViewTitle
                            viewModelId={viewModelId}
                            viewId={viewId}
                            isEditing={isEditing}
                            name={name}
                            onNameEndEdit={handleOnNameEndEdit}
                        />
                        {!shouldHideMenuButtons && (
                            <ViewTitleMenu
                                readonly={false}
                                viewId={viewId}
                                onCopyView={onCopyView}
                                onOpenPopupMenu={onOpenPopupMenu}
                            />
                        )}
                    </div>
                </foreignObject>
            </DraggableSVGGElement>
        </>
    );
};
