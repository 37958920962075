import { useState, useCallback } from 'react';
import {
    BottomResizeView,
    LeftBottomResizeView,
    LeftResizeView,
    LeftTopResizeView,
    RightBottomResizeView,
    RightResizeView,
    RightTopResizeView,
    TopResizeView,
} from './AbstractResize';

type Position = {
    x: number;
    y: number;
};

type Size = {
    width: number;
    height: number;
};

export type Resizing = {
    dTop: number;
    dRight: number;
    dBottom: number;
    dLeft: number;
};

type Props = {
    defaultSize: Size;
    minSize?: Size;
    onResizeStart(): void;
    onResize(position: Position, size: Size): void;
    onResizeEnd(delta: Resizing): void;
};

export const StickyZoneResizer: React.FC<Props> = ({
    defaultSize,
    minSize = { width: 128, height: 128 },
    onResizeStart,
    onResize,
    onResizeEnd,
}: Props) => {
    const [size, setSize] = useState<Size>(defaultSize);
    const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
    const [delta, setDelta] = useState<Resizing>({
        dTop: 0,
        dRight: 0,
        dBottom: 0,
        dLeft: 0,
    });
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const handleResizeStart = useCallback((): void => {
        setSize(defaultSize);
        setDelta({
            dTop: 0,
            dRight: 0,
            dBottom: 0,
            dLeft: 0,
        });
        setIsDragging(true);
        onResizeStart();
    }, [defaultSize, onResizeStart]);

    /**
     * resizerの大きさを変える関数
     * @param {number} dTop top方向への差分
     * @param {number} dRight right方向への差分
     * @param {number} dBottom bottom方向への差分
     * @param {number} dLeft left方向への差分
     **/
    const handleResize = useCallback(
        (dTop: number, dRight: number, dBottom: number, dLeft: number): void => {
            const { x, y } = position;
            const { width, height } = size;

            // 下限サイズを下回る場合には、ドラッグ差分を取り消すように補正する
            if (width + dRight - dLeft < minSize.width) {
                dRight = 0;
                dLeft = 0;
            }
            if (height + dBottom - dTop < minSize.height) {
                dBottom = 0;
                dTop = 0;
            }

            const newPosition = {
                x: x + dLeft,
                y: y + dTop,
            };
            const newSize = {
                width: width + dRight - dLeft,
                height: height + dBottom - dTop,
            };
            const newDelta = {
                dTop: delta.dTop + dTop,
                dRight: delta.dRight + dRight,
                dBottom: delta.dBottom + dBottom,
                dLeft: delta.dLeft + dLeft,
            };

            setSize(newSize);
            setPosition(newPosition);
            setDelta(newDelta);

            onResize(newPosition, newSize);
        },
        [delta.dBottom, delta.dLeft, delta.dRight, delta.dTop, minSize.height, minSize.width, onResize, position, size]
    );

    const handleResizeEnd = useCallback((): void => {
        onResizeEnd(delta);
        setPosition({
            x: 0,
            y: 0,
        });
        setIsDragging(false);
    }, [delta, onResizeEnd]);

    const currentSize = isDragging ? size : defaultSize;
    const resizerLineSize = 6;
    const resizerContainerStrokeSize = 24;
    const resizerCircleSize = 12;
    const circleOffset = resizerLineSize / 2; // 四隅の丸を線の幅の半分だけ外側に出す

    return (
        <g transform={`translate(${position.x}, ${position.y})`}>
            {isDragging && <rect width={currentSize.width} height={currentSize.height} fill="#eee" fillOpacity="0.7" />}
            <RightResizeView
                width={resizerLineSize}
                height={currentSize.height}
                containerWidth={resizerContainerStrokeSize}
                containerHeight={currentSize.height}
                offsetX={currentSize.width + resizerLineSize / 2}
                offsetY={currentSize.height / 2}
                handleResize={handleResize}
                handleResizeStart={handleResizeStart}
                handleResizeEnd={handleResizeEnd}
                cursor={'ew-resize'}
            />
            <LeftResizeView
                width={resizerLineSize}
                height={currentSize.height}
                containerWidth={resizerContainerStrokeSize}
                containerHeight={currentSize.height}
                offsetX={-resizerLineSize / 2}
                offsetY={currentSize.height / 2}
                handleResize={handleResize}
                handleResizeStart={handleResizeStart}
                handleResizeEnd={handleResizeEnd}
                cursor={'ew-resize'}
            />
            <TopResizeView
                width={currentSize.width}
                height={resizerLineSize}
                containerWidth={currentSize.width}
                containerHeight={resizerContainerStrokeSize}
                offsetX={currentSize.width / 2}
                offsetY={-resizerLineSize / 2}
                handleResize={handleResize}
                handleResizeStart={handleResizeStart}
                handleResizeEnd={handleResizeEnd}
                cursor={'ns-resize'}
            />
            <BottomResizeView
                width={currentSize.width}
                height={resizerLineSize}
                containerWidth={currentSize.width}
                containerHeight={resizerContainerStrokeSize}
                offsetX={currentSize.width / 2}
                offsetY={currentSize.height + resizerLineSize / 2}
                handleResize={handleResize}
                handleResizeStart={handleResizeStart}
                handleResizeEnd={handleResizeEnd}
                cursor={'ns-resize'}
            />
            <RightTopResizeView
                r={resizerCircleSize}
                offsetX={currentSize.width + circleOffset}
                offsetY={-circleOffset}
                handleResize={handleResize}
                handleResizeStart={handleResizeStart}
                handleResizeEnd={handleResizeEnd}
                cursor={'nesw-resize'}
            />
            <RightBottomResizeView
                r={resizerCircleSize}
                offsetX={currentSize.width + circleOffset}
                offsetY={currentSize.height + circleOffset}
                handleResize={handleResize}
                handleResizeStart={handleResizeStart}
                handleResizeEnd={handleResizeEnd}
                cursor={'nwse-resize'}
            />
            <LeftBottomResizeView
                r={resizerCircleSize}
                offsetX={-circleOffset}
                offsetY={currentSize.height + circleOffset}
                handleResize={handleResize}
                handleResizeStart={handleResizeStart}
                handleResizeEnd={handleResizeEnd}
                cursor={'nesw-resize'}
            />
            <LeftTopResizeView
                r={resizerCircleSize}
                offsetX={-circleOffset}
                offsetY={-circleOffset}
                handleResize={handleResize}
                handleResizeStart={handleResizeStart}
                handleResizeEnd={handleResizeEnd}
                cursor={'nwse-resize'}
            />
        </g>
    );
};
