import { PositionSetRepository } from '@view-model/models/common/PositionSet';
import { DescriptionPanel } from './DescriptionPanel';
import { DescriptionPanelCollection } from './DescriptionPanelCollection';
import { DescriptionPanelCollectionContents } from './DescriptionPanelCollectionContents';
import { DescriptionPanelJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/model-contents/{modelId}/description-panels/{descriptionPanelId}/DescriptionPanelJSON';
import { RTDBPath, RecordRepository } from '@framework/repository';
import { ModelId, ViewModelId } from '@schema-common/base';

export class DescriptionPanelCascadeRepository {
    private readonly panelsRepository: RecordRepository<DescriptionPanelJSON, DescriptionPanel>;
    private readonly positionsRepository: PositionSetRepository;

    constructor(viewModelId: ViewModelId, modelId: ModelId) {
        this.panelsRepository = new RecordRepository(
            DescriptionPanel,
            RTDBPath.DescriptionPanel.panelsPath(viewModelId, modelId)
        );

        this.positionsRepository = new PositionSetRepository(
            RTDBPath.DescriptionPanel.positionsPath(viewModelId, modelId)
        );
    }

    async saveContents(contents: DescriptionPanelCollectionContents): Promise<void> {
        const { panels, positions } = contents;
        await Promise.all([this.panelsRepository.save(panels.toRecord()), this.positionsRepository.saveAll(positions)]);
    }

    async loadContents(): Promise<DescriptionPanelCollectionContents> {
        const panels = await this.panelsRepository.get();
        const positions = await this.positionsRepository.loadAll();

        if (panels && positions) {
            return new DescriptionPanelCollectionContents(
                new DescriptionPanelCollection(Object.values(panels)),
                positions
            );
        } else {
            return DescriptionPanelCollectionContents.buildEmpty();
        }
    }
}
