import { FolderTree, FolderTreeJSON } from './FolderTree';
import { ViewModelId, FolderId } from '@schema-common/base';
import { RootFolderTreeJSON } from '@schema-app/workspace-contents/{workspaceKey}/root-folder-tree/RootFolderTreeJSON';

export class RootFolderTree {
    static ROOT_ID = '__root__';

    /**
     * ワークスペースに1つ存在するルートフォルダを表現するオブジェクト。
     *
     * 内部的には FolderTree のオブジェクトを保持している。
     * このオブジェクトは、ビューモデルとフォルダーの実体は保持しておらず、それらの識別子(id)のみを保持している。
     *
     * @param rootTree {FolderTree}
     */
    constructor(private readonly rootTree: FolderTree) {
        if (this.rootTree.id !== RootFolderTree.ROOT_ID) {
            throw new Error(`rootTree.id must be ${RootFolderTree.ROOT_ID}, but got ${this.rootTree.id}`);
        }
    }

    static buildEmpty(): RootFolderTree {
        return new RootFolderTree(FolderTree.build(this.ROOT_ID));
    }

    static load(dump: RootFolderTreeJSON): RootFolderTree {
        const { folderIdMap, viewModelIdMap } = dump;

        return new RootFolderTree(
            FolderTree.load({
                id: this.ROOT_ID,
                folderIdMap: folderIdMap || {},
                viewModelIdMap: viewModelIdMap || {},
            })
        );
    }

    dump(): FolderTreeJSON {
        return this.rootTree.dump();
    }

    isEmpty(): boolean {
        return this.rootTree.isEmpty();
    }

    has(id: ViewModelId | FolderId): boolean {
        return this.rootTree.has(id);
    }

    /**
     * 指定した folderId 配下のビューモデルID一覧を返す
     * @param folderId
     */
    getViewModelIdsOf(folderId: FolderId): ViewModelId[] {
        return this.rootTree.findFolder(folderId)?.getViewModelIds() || [];
    }

    /**
     * ルートフォルダ配下のビューモデルID一覧を返す
     */
    getViewModelIdsOfRoot(): ViewModelId[] {
        return this.getViewModelIdsOf(RootFolderTree.ROOT_ID);
    }

    /**
     * 指定した folderId 配下のフォルダID一覧を返す
     * @param folderId
     */
    getFolderIdsOf(folderId: FolderId): FolderId[] {
        return this.rootTree.findFolder(folderId)?.getFolderIds() || [];
    }

    /**
     * 指定した folderId 配下に子孫要素があれば true を返す
     * @param folderId
     */
    hasChildrenOf(folderId: FolderId): boolean {
        const folder = this.findFolder(folderId);
        return !!folder?.hasChildren();
    }

    hasDescendantViewModel(viewModelId: ViewModelId): boolean {
        return this.rootTree.descendantsViewModelIds().includes(viewModelId);
    }

    /**
     * ルートフォルダ配下のフォルダID一覧を返す
     */
    getFolderIdsOfRoot(): FolderId[] {
        return this.getFolderIdsOf(RootFolderTree.ROOT_ID);
    }
    /**
     * ルートフォルダツリーに含まれる全てのフォルダID一覧を返す
     */
    getAllFolderIds(): FolderId[] {
        return this.rootTree.descendantsFolderIds();
    }

    /**
     * ルートフォルダツリーに含まれる全てのビューモデルID一覧を返す
     */
    getAllViewModelIds(): FolderId[] {
        return this.rootTree.descendantsViewModelIds();
    }

    /**
     * 指定の要素(ビューモデルIDまたはフォルダID)が、ルートフォルダに属しているときに true を返す
     * @param id
     */
    includesInRoot(id: ViewModelId | FolderId): boolean {
        const viewModelIds = this.getViewModelIdsOfRoot();
        const folderIds = this.getFolderIdsOfRoot();
        return viewModelIds.includes(id) || folderIds.includes(id);
    }

    /**
     * 指定した folderId の FolderTree を返す。
     * @param folderId
     */
    findFolder(folderId: FolderId): FolderTree | undefined {
        return this.rootTree.findFolder(folderId);
    }

    /**
     * 指定した viewModelId が属する FolderTree を返す。
     * @param viewModelId
     */
    findFolderOfViewModel(viewModelId: ViewModelId): FolderTree | undefined {
        return this.rootTree.findFolderOfViewModel(viewModelId);
    }
}
