import { Position } from '@view-model/models/common/types/ui';
import { DraggableSVGGElement } from '@model-framework/ui';

type Props = {
    cursor: 'ns-resize' | 'ew-resize';
    positions: [Position, Position];
    lineStrokeWidth: number;
    draggerStrokeWidth: number;
    handlePosition: 'top' | 'bottom' | 'left' | 'right';
    onResize(dx: number, dy: number, dWidth: number, dHeight: number): void;
    onResizeStart(): void;
    onResizeEnd(): void;
};

export const ResizerLineElement: React.FC<Props> = ({
    cursor,
    positions,
    lineStrokeWidth,
    draggerStrokeWidth,
    handlePosition,
    onResize,
    onResizeEnd,
    onResizeStart,
}: Props) => {
    const { x, y } = positions[0];
    const [{ x: x1, y: y1 }, { x: x2, y: y2 }] = positions;

    const handleDrag = (dx: number, dy: number) => {
        switch (handlePosition) {
            case 'top':
                onResize(0, dy, 0, -dy);
                break;
            case 'left':
                onResize(dx, 0, -dx, 0);
                break;
            case 'right':
                onResize(0, 0, dx, 0);
                break;
            case 'bottom':
                onResize(0, 0, 0, dy);
                break;
        }
    };

    return (
        <DraggableSVGGElement
            position={{ x, y }}
            onDrag={handleDrag}
            onDragStart={onResizeStart}
            onDragEnd={onResizeEnd}
        >
            {/* 色付きの直線 */}
            <line
                className="stroke-brand"
                x1={x1 - x}
                y1={y1 - y}
                x2={x2 - x}
                y2={y2 - y}
                strokeWidth={lineStrokeWidth}
            />
            {/* 透明のドラッグ操作可能な領域を上にかぶせる */}
            <line
                className="stroke-transparent"
                x1={x1 - x}
                y1={y1 - y}
                x2={x2 - x}
                y2={y2 - y}
                strokeWidth={draggerStrokeWidth}
                cursor={cursor}
            />
        </DraggableSVGGElement>
    );
};
