import { ViewJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/views/{viewId}/ViewJSON';
import { AssetUrlMap, ViewModelName } from '@view-model/domain/view-model';
import { ViewCollection } from '@view-model/domain/view';
import { ConsistencyLinkCollection, ConsistencyLinkCollectionJSON } from '@view-model/domain/ConsistencyLink';
import { ModelContentsCollection, StickyModelContentsJSON } from '@view-model/domain/model';
import { PositionSet, PositionSetJSON } from '@view-model/models/common/PositionSet';
import { ViewRepository } from '@view-model/infrastructure/view-model/ViewRepository';

type ViewModelContentJSON = {
    name: string;
    views: ViewJSON[];
    viewPositions: PositionSetJSON;
    consistencyLinks: ConsistencyLinkCollectionJSON;
    models: StickyModelContentsJSON[];
};

export class ViewModelContent {
    private views: ViewCollection;
    private _viewPositions: PositionSet;
    public readonly consistencyLinks: ConsistencyLinkCollection;

    constructor(
        public readonly name: ViewModelName,
        views: ViewCollection,
        viewPositions: PositionSet,
        consistencyLinks: ConsistencyLinkCollection,
        public readonly models: ModelContentsCollection
    ) {
        this.views = views;
        this._viewPositions = viewPositions;

        // 不整合な ConsistencyLink が発生することがあるのでそのガードを入れる。
        // https://levii.slack.com/archives/C01873KAN0G/p1611115055056700?thread_ts=1611113221.055800&cid=C01873KAN0G
        //
        // 本当はViewModelEntityで一貫性を保つようにして欲しいが、consistencyLinkがpublicで個別に操作できてしまい、
        // 現状の作りだと対応が困難なためここでカバーする。
        const invalidLinks = consistencyLinks.invalidLinksBy(views);
        if (invalidLinks) {
            invalidLinks.forEach((link) => {
                console.warn(`Invalid ConsistencyLink is found and ignore this one: ${link.key.toString()}`);
            });
            const validLinks = consistencyLinks.validLinksBy(views);
            this.consistencyLinks = validLinks ? validLinks : new ConsistencyLinkCollection();
        } else {
            this.consistencyLinks = consistencyLinks;
        }
    }

    get viewPositions(): PositionSet {
        return this._viewPositions;
    }

    getViews(): ViewCollection {
        return this.views;
    }

    getModels(): ModelContentsCollection {
        return this.models;
    }

    dump(): ViewModelContentJSON {
        return {
            name: this.name.dump(),
            views: this.views.dump(),
            viewPositions: this.viewPositions.dump(),
            consistencyLinks: this.consistencyLinks.dump(),
            models: this.models.dump(),
        };
    }

    static load(dump: ViewModelContentJSON): ViewModelContent {
        return new ViewModelContent(
            ViewModelName.load(dump.name),
            ViewCollection.load(dump.views),
            PositionSet.load(dump.viewPositions),
            ConsistencyLinkCollection.load(dump.consistencyLinks),
            ModelContentsCollection.load(dump.models)
        );
    }

    cloneNew(assetUrlMap: AssetUrlMap): ViewModelContent {
        const [models, modelKeyMap] = this.models.cloneNew(assetUrlMap);
        const [views, viewKeyMap, idMap] = this.views.cloneNew(modelKeyMap);
        const viewPositions = this.viewPositions.cloneNew(idMap);
        return new ViewModelContent(
            this.name,
            views,
            viewPositions,
            this.consistencyLinks.cloneNew(viewKeyMap),
            models
        );
    }

    moveViewPositions(dx: number, dy: number): void {
        this._viewPositions = this.viewPositions.moveAll(dx, dy);
    }

    /**
     * orderがセットされていないViewのオーダーをセットする
     * start からスタートしてorderがセットされていないものがある度にインクリメントしながら採番する。
     * リファクタリングの過程で初期実装をひとまずそのままメソッド化。
     *
     * @param start オーダーの開始番号。
     */
    correctViewOrders(start: number): void {
        this.views = this.views.correctOrder(start);
    }

    async saveViews(viewRepository: ViewRepository): Promise<void> {
        return viewRepository.saveCollection(this.views);
    }

    /**
     * このビューモデル中に含まれるview-model-assetsのURL一覧
     */
    assetUrls(): string[] {
        return this.models.assetUrls();
    }
}
