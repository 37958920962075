import { FlatButton } from '@framework/ui/atoms';
import { Modal } from '@framework/ui/elements';
import { GroupEntity } from '@group/domain';
import { GroupMemberEditTab, MenuName } from '../GroupMemberEditTab';
import { MemberEditList } from './MemberEditList';

type Props = {
    isOpen: boolean;
    onClose(): void;
    groupEntity: GroupEntity;
    currentUserId: string;
    onClickMenu(menu: MenuName): void;
};

export const MemberEditModal: React.FC<Props> = ({
    isOpen,
    onClose,
    groupEntity,
    currentUserId,
    onClickMenu,
}: Props) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} className="flex flex-col">
            {/* header */}
            <div className="mb-1 p-4 text-2xl font-bold">メンバーの一覧</div>

            {/* tab */}
            <GroupMemberEditTab current="members" showDeleteButton={true} onClickMenu={onClickMenu} />

            {/* content */}
            <div className="overflow-y-auto px-4 py-2" style={{ maxHeight: 'calc(85vh - 160px)' }}>
                <MemberEditList
                    groupId={groupEntity.id}
                    memberRoles={groupEntity.members}
                    currentUserId={currentUserId}
                />
            </div>

            {/* footer */}
            <hr className="my-2 w-full" />
            <div className="flex justify-end px-8 py-4">
                <FlatButton onClick={onClose}>閉じる</FlatButton>
            </div>
        </Modal>
    );
};
