import { UserKey } from '@user/domain';
import { ClientId } from '@schema-common/base';
import { EditingUserJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-viewer-contents/{viewModelId}/editing-user/{editLockKey}/EditingUserJSON';
import { UserPublicProfile } from '@user/PublicProfile';

type EditingUserAttributes = {
    key: UserKey;
    name: string;
    imageURL: string | null;
    clientID: ClientId;
};

export class EditingUser {
    public readonly key: UserKey;
    public readonly name: string;
    public readonly imageURL: string | null;
    public readonly clientID: ClientId;

    constructor(attributes: EditingUserAttributes) {
        this.key = attributes.key;
        this.name = attributes.name;
        this.imageURL = attributes.imageURL;
        this.clientID = attributes.clientID;
    }

    static buildFromProfile(profile: UserPublicProfile, clientID: ClientId): EditingUser {
        return new this({
            key: UserKey.buildFromId(profile.id),
            name: profile.name,
            imageURL: profile.imageUrl,
            clientID,
        });
    }

    isEqual(other: EditingUser): boolean {
        return (
            other instanceof EditingUser &&
            this.key.isEqual(other.key) &&
            this.name == other.name &&
            this.imageURL == other.imageURL &&
            this.clientID === other.clientID
        );
    }

    dump(): EditingUserJSON {
        const { key, name, imageURL, clientID } = this;

        return {
            key: key.toString(),
            name: name.toString(),
            imageURL: imageURL?.toString() || null,
            clientID,
        };
    }

    static load(dump: EditingUserJSON): EditingUser {
        return new EditingUser({
            key: new UserKey(dump.key),
            name: dump.name,
            imageURL: dump.imageURL,
            clientID: dump.clientID,
        });
    }
}
