import { DisplayOrderChangeset } from '@model-framework/display-order/infrastructure/DisplayOrderChangeset';
import { IDisplayOrderKeyGenerator } from '@model-framework/display-order/infrastructure/IDisplayOrderKeyGenerator';
import { DisplayOrderJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/model-contents/{modelId}/display-order/{zoneId}/DisplayOrderJSON';
import { NodeId, StickyZoneId } from '@schema-common/base';
import { DisplayOrderTree, DisplayOrderTreeZone } from '../domain';
import { DisplayOrder } from './DisplayOrder';
import { DisplayOrderZoneEntry } from './DisplayOrderZoneEntry';

type DisplayOrderTreeMapData = Record<StickyZoneId, DisplayOrderJSON>;

/**
 * リポジトリ内のツリー構造を表現し、DisplayOrderTreeへの変換を担うクラス
 */
export class DisplayOrderTreeMap {
    constructor(private readonly tree: DisplayOrderTreeMapData = {}) {}

    /**
     * このデータに対応するDisplayOrderTreeに変換してそれを返します。
     * @param rootId ツリーのルートID
     */
    toDisplayOrderTree(rootId: StickyZoneId): DisplayOrderTree {
        const { nodeIds, zones } = this.displayOrderTreeZone(rootId);

        return new DisplayOrderTree(nodeIds, zones);
    }

    // アルファベット昇順（String.localeCompare() は順序が違うので代わりに使えないことに注意。英語の大文字小文字の順序が入れ替わってしまう）
    private static compareOrderKey(o1: string, o2: string): number {
        if (o1 === o2) return 0;

        return o1 < o2 ? -1 : 1;
    }

    private displayOrderTreeZone(zoneId: StickyZoneId): DisplayOrderTreeZone {
        const zone = this.tree[zoneId];
        if (!zone) return new DisplayOrderTreeZone(zoneId);

        const nodeOrders = zone.nodeOrders || {};
        const nodeIds = Object.keys(nodeOrders).sort((id1, id2) =>
            DisplayOrderTreeMap.compareOrderKey(nodeOrders[id1], nodeOrders[id2])
        );

        const zoneOrders = zone.zoneOrders || {};
        const zones = Object.keys(zoneOrders)
            .sort((id1, id2) => DisplayOrderTreeMap.compareOrderKey(zoneOrders[id1], zoneOrders[id2]))
            .map((id) => this.displayOrderTreeZone(id));

        return new DisplayOrderTreeZone(zoneId, nodeIds, zones);
    }

    /**
     * 渡されたIDに一致するノードのDisplayOrderを返します。
     * @param id
     */
    findNodeOrder(id: NodeId): DisplayOrder | undefined {
        return this.findNodeOrders([id])[0];
    }

    /**
     * 渡されたIDリストに一致するノードのDisplayOrderのリストを返します。
     * @param ids
     */
    findNodeOrders(ids: NodeId[]): DisplayOrder[] {
        const orders: DisplayOrder[] = [];
        const idSet = new Set(ids);

        for (const [parentId, zone] of Object.entries(this.tree)) {
            for (const [nodeId, key] of Object.entries(zone.nodeOrders || {})) {
                if (idSet.has(nodeId)) {
                    orders.push(DisplayOrder.node(nodeId, parentId, key));
                }
            }
        }

        return orders;
    }

    /**
     * 渡されたIDに一致するゾーンのDisplayOrderを返します。
     * @param id
     */
    findZoneOrder(id: StickyZoneId): DisplayOrder | undefined {
        return this.findZoneOrders([id])[0];
    }

    /**
     * 渡されたIDリストに一致するゾーンのDisplayOrderのリストを返します。
     * @param zoneIds
     */
    findZoneOrders(zoneIds: StickyZoneId[]): DisplayOrder[] {
        const orders: DisplayOrder[] = [];
        const idSet = new Set(zoneIds);

        for (const [parentId, zone] of Object.entries(this.tree)) {
            for (const [zoneId, key] of Object.entries(zone.zoneOrders || {})) {
                if (idSet.has(zoneId)) {
                    orders.push(DisplayOrder.zone(zoneId, parentId, key));
                }
            }
        }

        return orders;
    }

    /**
     * 渡されたIDリストに一致するDisplayOrderのリストを返します。
     * @param ids
     */
    findOrders(ids: (NodeId | StickyZoneId)[]): DisplayOrder[] {
        return [...this.findNodeOrders(ids), ...this.findZoneOrders(ids)];
    }

    /**
     * 兄弟ゾーンを探してそのDisplayOrderを返します（渡されたゾーンは含まない）。
     * @param zoneId
     */
    findZoneSiblingOrders(zoneId: StickyZoneId): DisplayOrder[] {
        for (const [parentId, zone] of Object.entries(this.tree)) {
            const zoneOrders = zone.zoneOrders || {};

            if (Object.keys(zoneOrders).includes(zoneId)) {
                const orders: DisplayOrder[] = [];
                for (const [id, key] of Object.entries(zoneOrders)) {
                    if (zoneId !== id) {
                        orders.push(DisplayOrder.zone(id, parentId, key));
                    }
                }
                return orders;
            }
        }

        return [];
    }

    /**
     * 指定されたゾーンIDのDisplayOrderZoneEntryを返します。
     * @param zoneId
     */
    findZoneEntry(zoneId: StickyZoneId): DisplayOrderZoneEntry | undefined {
        return this.findZoneEntries([zoneId])[0];
    }

    /**
     * 渡されたIDリストに一致するDisplayOrderZoneEntryのリストを返します。
     * @param zoneIds
     */
    findZoneEntries(zoneIds: StickyZoneId[]): DisplayOrderZoneEntry[] {
        const entries: DisplayOrderZoneEntry[] = [];
        const idSet = new Set(zoneIds);

        for (const [parentId, parentZone] of Object.entries(this.tree)) {
            if (idSet.has(parentId)) {
                const nodeOrders = Object.entries(parentZone.nodeOrders || {}).map(([nodeId, key]) =>
                    DisplayOrder.node(nodeId, parentId, key)
                );
                const zoneOrders = Object.entries(parentZone.zoneOrders || {}).map(([zoneId, key]) =>
                    DisplayOrder.zone(zoneId, parentId, key)
                );

                entries.push(new DisplayOrderZoneEntry(parentId, nodeOrders, zoneOrders));
            }
        }

        return entries;
    }

    /**
     * 指定されたノードとゾーンを削除するChangesetを作成して返します。
     * @param orderKeyGenerator 新しいオーダーキーを生成するジェネレータ
     * @param nodeIds 削除対象のノードIDリスト
     * @param zoneIds 削除対象のゾーンIDリスト
     */
    removeNodesAndZonesChangeset(
        orderKeyGenerator: IDisplayOrderKeyGenerator,
        nodeIds: NodeId[],
        zoneIds: StickyZoneId[]
    ): DisplayOrderChangeset {
        const zoneEntries = this.findZoneEntries(zoneIds); // 親としてのゾーン

        const releasedOrders: DisplayOrder[] = []; // 親としてのゾーンの子供たちを解放する（退避してルートに移動する）

        for (const zone of zoneEntries) {
            const orderTree = this.toDisplayOrderTree('__root__');
            const ancestors = orderTree.ancestors(zone.getZoneId());
            const notRemovedAncestors = ancestors.filter((ancestor) => !zoneIds.includes(ancestor));

            // 削除対象になっていない子のみを移動
            const nodeOrders = zone.nodeOrders().filter((order) => !nodeIds.includes(order.elementId()));
            const zoneOrders = zone.zoneOrders().filter((order) => !zoneIds.includes(order.elementId()));

            const childOrders = [...nodeOrders, ...zoneOrders];

            if (notRemovedAncestors.length) {
                releasedOrders.push(
                    ...childOrders.map((order) =>
                        // ancestors は末尾が一番の子要素となる
                        order.changeParent(notRemovedAncestors.slice(-1)[0], orderKeyGenerator.generate())
                    )
                );
            } else {
                releasedOrders.push(
                    ...childOrders.map((order) => order.changeParent('__root__', orderKeyGenerator.generate()))
                );
            }
        }

        // 子としてのゾーン、ノード
        // 削除される親に含まれるゾーン、ノードは親削除で一緒に消えるので除外。そうしないと同じパスに対する書き込みになりエラーになる。
        const orders = [...this.findZoneOrders(zoneIds), ...this.findNodeOrders(nodeIds)].filter(
            (order) => !order.isChildOfAny(zoneIds)
        );

        return DisplayOrderChangeset.changeset()
            .removeOrders(orders)
            .removeZoneEntries(zoneEntries)
            .addOrders(releasedOrders);
    }
}
