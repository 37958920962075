import { useSnapshot } from '@framework/hooks/useSnapshot';
import { RTDBPath } from '@framework/repository';
import { Viewer } from '@view-model/Viewer/Viewer';
import { ViewerJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-viewer-contents/{viewModelId}/viewers/{clientId}/ViewerJSON';
import { useSetAtom } from 'jotai/react';
import { viewersAtom } from '@view-model/models/sticky/viewers/viewersAtom';
import { ViewModelId } from '@schema-common/base';
import { useEffect } from 'react';

// viewersAtomにビューモデルを閲覧中のユーザ情報を読み込む
export const ViewersLoader = ({ viewModelId }: { viewModelId: ViewModelId }) => {
    const setViewers = useSetAtom(viewersAtom);

    useSnapshot({
        path: RTDBPath.ViewModelViewer.viewersPath(viewModelId),
        stateless: true,
        load: ({ getChildValues }) => {
            const viewersJSON = getChildValues() as Record<string, ViewerJSON>;
            const viewers = Object.values(viewersJSON).map((viewerJSON) => Viewer.load(viewerJSON));
            setViewers(viewers);
        },
    });

    useEffect(() => {
        return () => {
            setViewers([]);
        };
    }, [setViewers]);

    return null;
};
