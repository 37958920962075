import { useEffect, useMemo, useState } from 'react';
import { Rect } from '@view-model/models/common/basic';
import { ModelId, NodeId, ViewModelId } from '@schema-common/base';
import { StickyNodeDescriptionContent } from './components';
import { StickyNodeDescription, StickyNodeDescriptionRepository } from './domain';

type Props = {
    viewModelId: ViewModelId;
    modelId: ModelId;
    nodeId: NodeId;
    nodeRect: Rect;
    readonly: boolean;
};

/**
 * 付箋への補足説明（吹き出し）
 *
 * @param nodeId
 * @param nodeRect 言及対象付箋の矩形領域
 * @param readonly 編集可能かどうか
 * @param nodeRepository
 */
export const StickyNodeDescriptionView: React.FC<Props> = ({
    viewModelId,
    modelId,
    nodeId,
    nodeRect,
    readonly,
}: Props) => {
    const repository = useMemo(() => {
        return StickyNodeDescriptionRepository.buildFromIds(viewModelId, modelId, nodeId);
    }, [modelId, nodeId, viewModelId]);

    const [description, setDescription] = useState<StickyNodeDescription | null>(null);

    // 「Can't perform a React state update on an unmounted component〜」というwarningは無視しても問題ない
    // cf. https://github.com/levii/balus-app/discussions/2686
    useEffect(() => {
        repository.addListener((desc) => {
            setDescription(desc);
        });

        return () => repository.removeListener();
    }, [repository]);

    if (!description?.isVisible()) return null;

    return (
        <StickyNodeDescriptionContent
            viewModelId={viewModelId}
            nodeId={nodeId}
            description={description}
            readonly={readonly}
            nodeRect={nodeRect}
            repository={repository}
        />
    );
};
