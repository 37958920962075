import { StickyModel, ModelEntity, ModelType } from '@view-model/domain/model';
import { ModelKey } from '@view-model/domain/key';
import { ModelJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/models/{modelId}/ModelJSON';
import { PositionSet } from '@view-model/models/common/PositionSet';

function buildStickyModel(j: ModelJSON): StickyModel {
    const version = j.version || 1;

    return new StickyModel({
        key: new ModelKey(j.key),
        nodes: [],
        links: [],
        zones: [],
        zonePositions: new PositionSet(),
        version: version,
    });
}

export class ModelContentMapper {
    public toDomainObject(j: ModelJSON): StickyModel | null {
        const type = ModelType[j.type as keyof typeof ModelType];

        if (type === ModelType.Basic || type === ModelType.Sticky) {
            return buildStickyModel(j);
        }

        return null;
    }

    public toSerialize(model: ModelEntity): ModelJSON {
        return {
            key: model.key.toString(),
            type: model.type.toString(),
            namespace: model.namespace.toString(),
            version: model.version,
        };
    }
}
