import { useCallback, useEffect, useRef, useState } from 'react';

type Result<T> = {
    isOpen: boolean;
    popupRef: React.RefObject<T>;
    handleOpen(): void;
    handleClose(): void;
};

type Handler = (e: MouseEvent) => unknown;

export const usePopupRef = <T extends HTMLElement | SVGGElement>(initialState?: boolean): Result<T> => {
    const [isOpen, setIsOpen] = useState<boolean>(initialState || false);
    const popupRef = useRef<T>(null);
    const documentClickHandler = useRef<Handler>();
    const rootNode = useRef<HTMLElement>(document.querySelector('#root'));

    useEffect(() => {
        // ポップアップ以外の場所をクリックしたときの処理
        const handler = (e: MouseEvent) => {
            // ポップアップ要素が無ければ何もしない
            if (!popupRef.current) {
                return;
            }

            // クリックイベントの対象がポップアップ要素に含まれていれば何もしない
            if (popupRef.current.contains(e.target as Node)) {
                return;
            }

            // ポップアップを閉じて、イベントリスナーを削除
            setIsOpen(false);
            if (rootNode.current) {
                rootNode.current.removeEventListener('click', handler);
            }
        };

        documentClickHandler.current = handler;
    }, []);

    const handleOpen = useCallback(() => {
        const handler = documentClickHandler.current;
        if (!handler) {
            return;
        }

        // すでにポップアップが開いているならば何もしない
        if (isOpen) {
            return;
        }

        setIsOpen(true);
        if (rootNode.current) {
            rootNode.current.addEventListener('click', handler);
        }
    }, [isOpen]);

    const handleClose = useCallback(() => {
        setIsOpen(false);

        // 「ポップアップ以外の場所をクリックしたときの処理」のイベントリスナーを削除
        const handler = documentClickHandler.current;
        if (!handler) {
            return;
        }
        if (rootNode.current) {
            rootNode.current.removeEventListener('click', handler);
        }
    }, []);

    return { isOpen, popupRef, handleOpen, handleClose };
};
