import { ViewId } from '@schema-common/base';
import { toast } from 'react-hot-toast';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { CommandManager } from '@model-framework/command';
import { ShortcutManager } from '@view-model/application/shortcuts';
import { ConsistencyLinksOperation, StickyModelContentsOperation } from '@view-model/adapter';
import { useEffect, useRef } from 'react';
import { MultiSelectionMode } from './MultiSelectionMode';

interface Props {
    selectedViewIds: Set<ViewId>;
    viewModel: ViewModelEntity;
    selectedConsistencyLinkId: string | null;
    commandManagerRef: React.MutableRefObject<CommandManager>;
    setMultiSelectionMode: React.Dispatch<React.SetStateAction<MultiSelectionMode>>;
    stickyModelContentsOperations: Record<string, StickyModelContentsOperation>;
}

// セットアップしたショートカットマネージャーを返す
export function useViewModelPageContentShortcutManager(props: Props): ShortcutManager {
    const shortcutManagerRef = useRef<ShortcutManager>(new ShortcutManager());
    const propsRef = useRef<Props>(props);
    propsRef.current = props;
    const { selectedViewIds } = props;

    useEffect(() => {
        const shortcutManager = shortcutManagerRef.current;

        shortcutManager.addListener();
        return () => shortcutManager.removeListener();
    }, []);

    useEffect(() => {
        const shortcutManager = shortcutManagerRef.current;
        const { setMultiSelectionMode, stickyModelContentsOperations } = propsRef.current;
        shortcutManager.onMounted(
            // turnOnMultiSelectionMode
            () => {
                if (Object.values(stickyModelContentsOperations).some((operation) => operation.hasSelectedElements())) {
                    setMultiSelectionMode(MultiSelectionMode.multiElementsSelectionMode);
                } else {
                    setMultiSelectionMode(MultiSelectionMode.readyForMultiSelectionMode);
                }
            },
            // turnOffMultiSelectionMode
            () => {
                setMultiSelectionMode(MultiSelectionMode.offMode);
            },
            // onDelete
            () => {
                const { selectedViewIds, selectedConsistencyLinkId, viewModel, commandManagerRef } = propsRef.current;
                // ビューを選択中の場合には、ビューの側でハンドリングしているので、ここでは何もしない
                if (selectedViewIds.size > 0) {
                    return;
                }

                // 整合性リンクを選択している場合には、その整合性リンクを削除する
                if (selectedConsistencyLinkId) {
                    ConsistencyLinksOperation.handleDelete({
                        id: selectedConsistencyLinkId,
                        viewModelId: viewModel.id,
                        commandManager: commandManagerRef.current,
                    }).then();
                }
            },
            // onUndo
            async () => {
                const commandManager = propsRef.current.commandManagerRef.current;
                if (await commandManager.canUndo()) {
                    commandManager.undo();
                } else {
                    toast.error("取り消しできませんでした | Couldn't get back");
                }
            },
            // onRedo
            async () => {
                const commandManager = propsRef.current.commandManagerRef.current;
                if (await commandManager.canRedo()) {
                    commandManager.redo();
                } else {
                    toast.error("やり直しできませんでした | Couldn't redone");
                }
            }
        );

        return () => shortcutManager.onWillUnmount();
    }, [shortcutManagerRef, selectedViewIds]);

    return shortcutManagerRef.current;
}
