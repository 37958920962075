import { LinkJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/model-contents/{modelId}/links/{linkId}/LinkJSON';
import { MultiplicityAttributesJSON } from '@schema-common/view-model';

export class Multiplicity {
    public readonly lower: number;
    public readonly upper: number;

    public constructor(attributes: MultiplicityAttributesJSON) {
        this.lower = attributes.lower;
        this.upper = attributes.upper == -1 ? Infinity : attributes.upper;

        this.validate();
    }

    private validate() {
        if (!Number.isInteger(this.lower) || !(Number.isInteger(this.upper) || this.upper == Infinity)) {
            throw new Error('multiplicity lower and upper should be a Integer or Infinity (upper only).');
        }

        if (this.lower < 0 || this.upper < 0) {
            throw new Error('multiplicity lower and upper should be equal or larger than 0.');
        }

        if (this.lower > this.upper) {
            throw new Error('multiplicity upper should be equal or larger than lower.');
        }
    }

    public dump(): MultiplicityAttributesJSON {
        // JSON表現で Infinity は扱えないので、 -1 で代替する
        return {
            lower: this.lower,
            upper: this.upper == Infinity ? -1 : this.upper,
        };
    }

    public isEquals(other: Multiplicity): boolean {
        return other instanceof Multiplicity && this.lower == other.lower && this.upper == other.upper;
    }

    public toString(): string {
        if (this.lower === this.upper) {
            return `${this.lower}`;
        }

        const upper = this.upper === Infinity ? '*' : this.upper.toString();
        return `${this.lower}..${upper}`;
    }
}

export class LinkMultiplicity {
    public readonly isEnabled: boolean;
    public readonly source: Multiplicity;
    public readonly target: Multiplicity;

    public constructor(attributes: LinkJSON['multiplicity']) {
        this.isEnabled = attributes.isEnabled;
        this.source = new Multiplicity(attributes.source);
        this.target = new Multiplicity(attributes.target);
    }

    public static load(json: LinkJSON['multiplicity']): LinkMultiplicity {
        if (!json) {
            return LinkMultiplicity.Disabled();
        }

        return new LinkMultiplicity(json);
    }

    public dump(): LinkJSON['multiplicity'] {
        return {
            isEnabled: this.isEnabled,
            source: this.source.dump(),
            target: this.target.dump(),
        };
    }

    public static Disabled(): LinkMultiplicity {
        return new LinkMultiplicity({
            isEnabled: false,
            source: {
                lower: 1,
                upper: 1,
            },
            target: {
                lower: 1,
                upper: 1,
            },
        });
    }

    public isEquals(other: LinkMultiplicity): boolean {
        return (
            other instanceof LinkMultiplicity &&
            this.isEnabled == other.isEnabled &&
            this.source.isEquals(other.source) &&
            this.target.isEquals(other.target)
        );
    }

    public withEnabled(isEnabled: boolean): LinkMultiplicity {
        return new LinkMultiplicity({
            isEnabled: isEnabled,
            source: this.source,
            target: this.target,
        });
    }

    public withToggleEnabled(): LinkMultiplicity {
        return this.withEnabled(!this.isEnabled);
    }

    public withSource(source: Multiplicity): LinkMultiplicity {
        return new LinkMultiplicity({
            isEnabled: this.isEnabled,
            source: source,
            target: this.target,
        });
    }

    public withTarget(target: Multiplicity): LinkMultiplicity {
        return new LinkMultiplicity({
            isEnabled: this.isEnabled,
            source: this.source,
            target: target,
        });
    }
}
