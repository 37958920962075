import { useCallback, useState } from 'react';
import { DraggableSVGGElement } from '@model-framework/ui';
import { Position } from '@view-model/models/common/types/ui';
import { Point } from '@view-model/models/common/basic';

type Props = {
    position: Position;
    onDragStart?: () => void;
    onDrag(dx: number, dy: number): void;
    onDragEnd?: () => void;
    onDragMoveStarted?: () => void;
    onDragMoveEnded?: () => void;
    onClick?: () => void;
    dragHandleContent: React.ReactElement | null;
    hoveringDragHandleContent?: React.ReactElement | null;
    children: React.ReactNode;
};

/**
 * ドラッグ移動可能な要素のうち、ドラッグハンドル部分を指定するようなコンテナ。
 *
 * ノードのように、対象要素のどこでも掴んで移動可能なものではなく、
 * 対象要素の特定部分のみを掴めるようなものを実現するためのコンテナコンポーネント。
 *
 * @param position 要素の表示位置
 * @param onDragStart
 * @param onDrag
 * @param onDragEnd
 * @param onDragMoveEnded
 * @param onDragMoveStarted
 * @param onClick
 * @param dragHandleContent ドラッグハンドル要素
 * @param hoveringDragHandleContent コンテンツ要素上をホバーしているときに表示するドラッグハンドル
 * @param children コンテンツ要素
 * @constructor
 */
export const DraggableContainer: React.FC<Props> = ({
    position,
    onDragStart,
    onDrag,
    onDragEnd,
    onDragMoveEnded,
    onDragMoveStarted,
    onClick,
    dragHandleContent,
    hoveringDragHandleContent,
    children,
}: Props) => {
    const [hovering, setHovering] = useState(false);
    const handleMouseEnter = useCallback(() => setHovering(true), []);
    const handleMouseLeave = useCallback(() => setHovering(false), []);

    return (
        <g onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={onClick}>
            <g transform={Point.fromPosition(position).toSVGTranslate()}>{children}</g>
            {/* ドラッグハンドル部が手前に描画されるよに、後から描画する */}
            <DraggableSVGGElement
                position={position}
                onDragStart={onDragStart}
                onDrag={onDrag}
                onDragEnd={onDragEnd}
                onDragMoveStarted={onDragMoveStarted}
                onDragMoveEnded={onDragMoveEnded}
            >
                {hovering && hoveringDragHandleContent}
                {dragHandleContent}
            </DraggableSVGGElement>
        </g>
    );
};
