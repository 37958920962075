import { StickyZoneMenuId } from './StickyZoneMenuId';
import { StickyZoneFontSize, StickyZoneStyle } from '../../domain';
import { FontSize, FontSizeMenu } from '@model-framework/text';
import { ColorMenu } from '@model-framework/color';
import { StickyNodeGroupingMenuButton, TrashMenuButton } from '@model-framework/menu';
import { ThemeColor } from '@view-model/models/common/color';
import { AnalysisMenuButton } from '@model-framework/menu/AnalysisMenuButton';

type Props = {
    menuId: StickyZoneMenuId;
    menuSize: number;
    currentStyle: StickyZoneStyle;
    isOpen: boolean;
    onMenuOpen(menu: StickyZoneMenuId): void;
    onMenuClose(): void;
    onFontSizeSelected(fontSize: FontSize): void;
    onThemeColorSelected(themeColor: ThemeColor): void;
    onDeleteZone(): void;
    onGroupSelectedZone(): void;
    onAnalysisSelected(): void;
};

export const StickyZoneMenuContent: React.FC<Props> = ({
    menuId,
    menuSize,
    currentStyle,
    isOpen,
    onMenuOpen,
    onMenuClose,
    onFontSizeSelected,
    onThemeColorSelected,
    onDeleteZone,
    onGroupSelectedZone,
    onAnalysisSelected,
}: Props) => {
    return (
        <>
            {menuId === StickyZoneMenuId.FontSize && (
                <FontSizeMenu
                    currentFontSize={currentStyle.fontSize}
                    menuSize={menuSize}
                    items={StickyZoneFontSize.values()}
                    isOpen={isOpen}
                    onMenuClicked={() => {
                        isOpen ? onMenuClose() : onMenuOpen(menuId);
                    }}
                    onSelected={onFontSizeSelected}
                    text="テキストサイズ | Text size"
                />
            )}
            {menuId === StickyZoneMenuId.ThemeColor && (
                <ColorMenu
                    currentColorName={currentStyle.themeColor}
                    menuSize={menuSize}
                    items={ThemeColor.menuItems()}
                    numColumns={4}
                    isOpen={isOpen}
                    text="カラー | Color"
                    onMenuClicked={() => {
                        isOpen ? onMenuClose() : onMenuOpen(menuId);
                    }}
                    onSelected={onThemeColorSelected}
                />
            )}
            {menuId === StickyZoneMenuId.Grouping && (
                <StickyNodeGroupingMenuButton
                    menuSize={menuSize}
                    onClick={() => {
                        // 他のメニューが開いていた場合閉じる
                        onMenuClose();
                        onGroupSelectedZone();
                    }}
                    text="グループ化 | Group"
                />
            )}
            {menuId === StickyZoneMenuId.Analysis && (
                <AnalysisMenuButton
                    menuSize={menuSize}
                    onClick={() => {
                        // 他のメニューが開いていた場合閉じる
                        onMenuClose();
                        onAnalysisSelected();
                    }}
                    text="自動タイトル付け (α) | Title this zone (α)"
                />
            )}
            {menuId === StickyZoneMenuId.DeleteZone && (
                <TrashMenuButton menuSize={menuSize} onClick={onDeleteZone} text="削除 | Delete" />
            )}
        </>
    );
};
