import { useCallback, useMemo, useRef, useEffect, memo } from 'react';
import { StickyZoneTextarea } from './StickyZoneTextarea';
import { StickyZoneResizerContainer } from './StickyZoneResizerContainer';
import { CreatedUserView } from '@view-model/models/framework/ui';
import { StickyZoneKey } from '@view-model/domain/key';
import { StickyZone, StickyZoneText } from '../domain';
import { useStickyZoneContext } from '../adapter';
import { RectShape } from '@model-framework/shape';
import { useD3DblClickCallback } from '@view-model/models/common/hooks';
import { D3Mouse } from '@view-model/models/common/d3/D3Mouse';
import { HandleD3Drag } from '@view-model/models/common/d3/HandleD3Drag';
import { D3DragEvent } from 'd3-drag';
import { Position } from '@view-model/models/common/types/ui';
import { LinkCreator } from '@view-model/models/common/components/LinkCreator';
import { EditingUserIconView, useOtherUserEditingToast, EditingUser } from '@model-framework/text/editing-user';
import { Point, Rect } from '@view-model/models/common/basic';
import { StickyZoneId } from '@schema-common/base';
import { useHandler } from '@framework/hooks/useHandler';

const MemoizedStickyZoneTextarea = memo(StickyZoneTextarea);

type PointPair = {
    start: Position | null;
    end: Position | null;
};

type Props = {
    readonly: boolean;
    stickyZone: StickyZone;
    position: Point;
    isHoverSelected: boolean;
    isSelected: boolean;
    isMultiSelected: boolean;
    isLinkableTarget: boolean;
    isMultiSelectionMode: boolean;
    showMenu: boolean;
    showCreatedUser: boolean;
    editing: boolean;
    editingUser: EditingUser | null;
    otherUserSelected: boolean;
    onEditStart(): void;
    onDblClickZone(position: Position, zoneKey: StickyZoneKey): void;
    onLinkerStart(startPosition: Position): void;
    onLinkerMove(currentPosition: Position): void;
    onLinkerEnd(currentPosition: Position): void;
    onRectSelection(rect: Rect): void;
    onRectSelectionEnd(rect: Rect, zoneId: StickyZoneId): void;
    shape: RectShape;
};

export const StickyZoneContent: React.FC<Props> = ({
    readonly,
    stickyZone,
    position,
    isHoverSelected,
    isSelected,
    isMultiSelected,
    isLinkableTarget,
    isMultiSelectionMode,
    showMenu,
    showCreatedUser,
    editing,
    editingUser,
    otherUserSelected,
    onEditStart,
    onDblClickZone,
    onLinkerStart,
    onLinkerMove,
    onLinkerEnd,
    onRectSelection,
    onRectSelectionEnd,
    shape,
}: Props) => {
    const stickyZoneContext = useStickyZoneContext();

    const { width, height } = stickyZone.size.dump();
    const style = stickyZone.style;

    const nodeCreatableZoneRef = useRef<SVGGElement>(null);
    const pointsRef = useRef<PointPair>({ start: null, end: null });
    const d3Mouse = useMemo(() => new D3Mouse(nodeCreatableZoneRef), []);
    const notifyOtherUserEditing = useOtherUserEditingToast();

    // 複数選択モードの時はドラッグによる矩形範囲選択を有効にする
    useEffect(() => {
        const onDragStart = function (event: D3DragEvent<Element, unknown, unknown>) {
            if (!isMultiSelectionMode) {
                return;
            }
            const [mouseX, mouseY] = d3Mouse.getPosition(event);
            pointsRef.current.start = position.addXY(mouseX, mouseY);
        };

        const onDrag = function (event: D3DragEvent<Element, unknown, unknown>) {
            if (!isMultiSelectionMode) {
                return;
            }
            const [mouseX, mouseY] = d3Mouse.getPosition(event);
            pointsRef.current.end = position.addXY(mouseX, mouseY);

            const { start, end } = pointsRef.current;
            if (start && end) {
                onRectSelection(Rect.fromPositions([start, end]));
            }
        };

        const onDragEnd = function () {
            const { start, end } = pointsRef.current;
            if (isMultiSelectionMode && start && end) {
                onRectSelectionEnd(Rect.fromPositions([start, end]), stickyZone.id);
            }
            pointsRef.current = { start: null, end: null };
        };

        const handleD3Drag = new HandleD3Drag(nodeCreatableZoneRef, isMultiSelectionMode);
        handleD3Drag.onMounted(onDrag, onDragStart, onDragEnd);
        return () => {
            handleD3Drag.onWillUnmounted();
        };
    }, [isMultiSelectionMode, onRectSelectionEnd, d3Mouse, onRectSelection, position, stickyZone]);

    useD3DblClickCallback(
        nodeCreatableZoneRef,
        useCallback(
            (event) => {
                const [x, y] = d3Mouse.getPosition(event);
                const clickPosition = position.addXY(x, y).dump();
                onDblClickZone(clickPosition, stickyZone.key);
            },
            [d3Mouse, position, onDblClickZone, stickyZone]
        ),
        // 本来はハンドルする場合だけ伝搬を止めればいいが、現状ではズームが発動するのでstopPropagationで伝搬を止める
        true
    );

    const containerMargin = 10;
    const containerRectShape = shape.resize({
        dWidth: containerMargin * 2,
        dHeight: containerMargin * 2,
        rx: 20,
        ry: 20,
    });
    const containerTranslate = `translate(${-containerMargin}, ${-containerMargin})`;

    const handleEditStart = useHandler(async function handleEditStart() {
        if (readonly) return;

        onEditStart();

        const started = await stickyZoneContext.handleEditingTextStart(stickyZone.text);

        if (!started) {
            notifyOtherUserEditing();
        }
    });

    const handleEditEnd = useHandler(async function handleEditEnd(text: StickyZoneText) {
        return stickyZoneContext.handleEditingTextConfirmed(text);
    });

    const handleChangeText = useHandler(async function handleChangeText(text: StickyZoneText) {
        return stickyZoneContext.handleEditingTextChanged(text);
    });

    const textareaSize = useMemo(() => ({ width, height }), [width, height]);
    const { fillColor, hoverFillColor, borderColor, hoverBorderColor } = style.getStyles();

    return (
        <>
            <g ref={nodeCreatableZoneRef}>
                {shape.render({
                    fillOpacity: 0.75,
                    strokeWidth: 4,
                    stroke: isHoverSelected ? hoverBorderColor : borderColor,
                    fill: isHoverSelected ? hoverFillColor : fillColor,
                })}
            </g>

            <MemoizedStickyZoneTextarea
                size={textareaSize}
                isMyEditing={editing}
                otherUserEditing={!!editingUser}
                textSelectable={isSelected && !isMultiSelected}
                text={stickyZone.text}
                fontSize={style.fontSize}
                onChange={handleChangeText}
                onEditStart={handleEditStart}
                onEditEnd={handleEditEnd}
            />

            {isMultiSelected ? (
                // 複数選択しているときにはブランドカラーの枠線を表示する
                <g transform={containerTranslate}>
                    {containerRectShape.render({
                        pointerEvents: 'none',
                        strokeWidth: 8,
                        className: 'stroke-brand fill-black/0',
                    })}
                </g>
            ) : isSelected ? (
                !readonly ? (
                    // 単一選択、かつ、編集可能であれば、ゾーンのResizerを表示する
                    <StickyZoneResizerContainer stickyZone={stickyZone} position={position} />
                ) : (
                    // 編集不可の場合には、ブランドカラーの枠線を表示する
                    <g transform={containerTranslate}>
                        {containerRectShape.render({
                            pointerEvents: 'none',
                            strokeWidth: 8,
                            className: 'stroke-brand fill-black/0',
                        })}
                    </g>
                )
            ) : otherUserSelected ? (
                // 自分が選択していなくて、他の人が選択している場合には、黄色の枠線を表示する
                <g transform={containerTranslate}>
                    {containerRectShape.render({
                        pointerEvents: 'none',
                        strokeWidth: 10,
                        className: 'stroke-orange-400 fill-black/0',
                    })}
                </g>
            ) : null}

            {editingUser && <g>{shape.render({ pointerEvents: 'none', className: 'fill-neutral-500/20' })}</g>}

            {showCreatedUser && !editingUser && (
                <CreatedUserView userId={stickyZone.createdUserId} position={new Point(0, height)} />
            )}

            {editingUser && (
                <EditingUserIconView currentEditingUser={editingUser} position={new Point(0, height)} iconSize={48} />
            )}

            {isLinkableTarget && (
                <g transform={containerTranslate}>
                    {containerRectShape.render({
                        strokeWidth: 10,
                        pointerEvents: 'none',
                        className: 'stroke-indigo-300 fill-black/0',
                    })}
                </g>
            )}

            {showMenu && (
                <LinkCreator
                    elementSize={{ width, height }}
                    onLinkerStart={onLinkerStart}
                    onLinkerMove={onLinkerMove}
                    onLinkerEnd={onLinkerEnd}
                />
            )}
        </>
    );
};
