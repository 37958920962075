import { Key } from '@framework/domain';
import { NodeId } from '@schema-common/base';

export class StickyNodeDescriptionEditLockKey extends Key {
    protected get KINDS(): string[] {
        return ['StickyNodeDescriptionEditLock'];
    }

    // 編集ロックに利用するキーを生成。StickyNodeDescriptionはStickyNodeと一対一に対応するのでnodeIdを流用してキーを生成する。
    public static fromNodeId(id: NodeId): StickyNodeDescriptionEditLockKey {
        return new StickyNodeDescriptionEditLockKey(`StickyNodeDescriptionEditLock:${id}`);
    }
}
