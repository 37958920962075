import { StickyNodeDescription } from './StickyNodeDescription';
import { ModelElementId, NodeKey } from '@view-model/domain/key';
import { CompositeCommand, ICommand } from '@model-framework/command';
import { StickyNodeDescriptionJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/model-contents/{modelId}/node-descriptions/{nodeId}/StickyNodeDescriptionJSON';
import { ModelId, NodeId, ViewModelId } from '@schema-common/base';

export type StickyNodeDescriptionSetJSON = Record<NodeId, StickyNodeDescriptionJSON>;

export class StickyNodeDescriptionSet {
    private constructor(private readonly descriptions: Record<NodeId, StickyNodeDescription>) {}

    get size(): number {
        return Object.keys(this.descriptions).length;
    }

    static fromArray(descriptions: StickyNodeDescription[]): StickyNodeDescriptionSet {
        const record: Record<NodeId, StickyNodeDescription> = {};
        descriptions.forEach((desc: StickyNodeDescription) => {
            record[desc.nodeId] = desc;
        });

        return new this(record);
    }

    dump(): StickyNodeDescriptionSetJSON {
        const dump: StickyNodeDescriptionSetJSON = {};
        Object.entries(this.descriptions).forEach(([nodeId, description]: [NodeId, StickyNodeDescription]) => {
            dump[nodeId] = description.dump();
        });

        return dump;
    }

    toArray(): StickyNodeDescription[] {
        return Object.values(this.descriptions);
    }

    static load(dump: StickyNodeDescriptionSetJSON): StickyNodeDescriptionSet {
        const descriptions = Object.values(dump).map((d) => StickyNodeDescription.load(d));
        return this.fromArray(descriptions);
    }

    add(value: StickyNodeDescription): StickyNodeDescriptionSet {
        return new StickyNodeDescriptionSet({
            ...this.descriptions,
            [value.nodeId]: value,
        });
    }

    delete(value: StickyNodeDescription): StickyNodeDescriptionSet {
        const { [value.nodeId]: _deleting, ...rest } = this.descriptions;

        return new StickyNodeDescriptionSet(rest);
    }

    cloneNew(nodeKeyMap: Record<string, NodeKey>): StickyNodeDescriptionSet {
        const newDescriptions = this.toArray().map((desc) => desc.cloneNew(nodeKeyMap));

        return StickyNodeDescriptionSet.fromArray(newDescriptions);
    }

    filterByModelElementIds(ids: ModelElementId[]): StickyNodeDescriptionSet {
        const newDescriptions = this.toArray().filter((desc) => ids.some((id) => desc.relatedTo(id)));

        return StickyNodeDescriptionSet.fromArray(newDescriptions);
    }

    buildCreateCommand(viewModelId: ViewModelId, modelId: ModelId): ICommand {
        const commands = this.toArray().map((desc) => desc.buildCreateCommand(viewModelId, modelId));

        return new CompositeCommand(...commands);
    }
}
