import { useEffect } from 'react';
import { useCurrentUserId } from './AuthContext';
import { FirebaseAuthenticationClient } from '../firebase/auth/FirebaseAuthenticationClient';
import Cookies from 'js-cookie';

const IDTokenCookieKey = 'Balus-ID-Token';

export const IdTokenSyncToCookie: React.FC<unknown> = () => {
    const currentUserId = useCurrentUserId();

    // ログイン中のユーザIDが変化した際には、強制的に IdToken をリフレッシュする
    useEffect(() => {
        const forceRefresh = true;
        FirebaseAuthenticationClient.getIdTokenResult(forceRefresh).then((tokenResult) => {
            if (!tokenResult) {
                Cookies.set(IDTokenCookieKey, '', { expires: 0 });
                return;
            }

            const { token, expirationTime } = tokenResult;
            Cookies.set(IDTokenCookieKey, token, { expires: new Date(expirationTime) });
        });
    }, [currentUserId]);

    // Firebase Auth の保持する IDトークン (JWT) の更新を監視し、IDトークンが更新された時にCookieにもその値を設定する
    useEffect(() => {
        const unsubscribe = FirebaseAuthenticationClient.onIdTokenChanged((user) => {
            if (!user) {
                Cookies.set(IDTokenCookieKey, '', { expires: 0 });
                return;
            }

            // IdTokenをCookieに設定する (Cookieの有効期限と、IdTokenの有効期限を揃える)
            user.getIdTokenResult().then((tokenResult) => {
                const { token, expirationTime } = tokenResult;
                Cookies.set(IDTokenCookieKey, token, { expires: new Date(expirationTime) });
            });
        });

        return () => unsubscribe();
    }, []);

    return <></>;
};
