import { Position } from '@view-model/models/common/types/ui';
import { DraggableSVGGElement } from '@model-framework/ui';

type Props = {
    cursor: 'nesw-resize' | 'nwse-resize';
    position: Position;
    circleRadius: number;
    handlePosition: 'right-top' | 'right-bottom' | 'left-top' | 'left-bottom';
    onResize(dx: number, dy: number, dWidth: number, dHeight: number): void;
    onResizeStart(): void;
    onResizeEnd(): void;
};

export const ResizerCircleElement: React.FC<Props> = ({
    cursor,
    position,
    circleRadius,
    handlePosition,
    onResize,
    onResizeStart,
    onResizeEnd,
}: Props) => {
    const { x, y } = position;

    const handleDrag = (dx: number, dy: number) => {
        switch (handlePosition) {
            case 'left-top':
                onResize(dx, dy, -dx, -dy);
                break;
            case 'left-bottom':
                onResize(dx, 0, -dx, dy);
                break;
            case 'right-top':
                onResize(0, dy, dx, -dy);
                break;
            case 'right-bottom':
                onResize(0, 0, dx, dy);
                break;
        }
    };

    return (
        <DraggableSVGGElement
            position={{ x, y }}
            onDrag={handleDrag}
            onDragStart={onResizeStart}
            onDragEnd={onResizeEnd}
        >
            <circle className="fill-brand" cursor={cursor} r={circleRadius} />
        </DraggableSVGGElement>
    );
};
