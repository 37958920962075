import { getClientId } from '@framework/app';
import { useCommandManager } from '@model-framework/command';
import {
    LinkColor,
    LinkLineStyle,
    LinkMarkStyle,
    LinkTextView,
    LinkWrapperView,
    useExclusiveLinkEditing,
} from '@model-framework/link';
import { LinkId } from '@schema-common/base';
import { LinkKey, LinkableTargetKey, ModelKey } from '@view-model/domain/key';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { Point, Rect, Size } from '@view-model/models/common/basic';
import { Fragment, useCallback, useRef, useState } from 'react';
import { LinkOperation } from './adapter';
import { LinkLine, LinkMenu, LinkMultiplicityPair } from './components';
import { LinkBend, LinkEntity, LinkName, LinkPlacement, Multiplicity } from './domain';
import { LinkDragHandle } from './components/LinkDragHandle';
import { LinkerState } from '@view-model/models/common/components/LinkCreator/LinkerState';
import { Position } from '@view-model/models/common/types/ui';
import { useOtherUserEditingToast } from '@view-model/models/framework/text/editing-user';
import { useOtherUserIsSelected } from '../client-selected-items/useOtherUserIsSelected';

type Props = {
    readonly: boolean;
    viewModel: ViewModelEntity;
    modelKey: ModelKey;
    link: LinkEntity;
    isSelected: boolean;
    isRelatedNodeSelected: boolean;
    placement: LinkPlacement;
    showMenu: boolean;
    onClick: (id: LinkId) => void;
    onLineStyleSelected(lineStyle: LinkLineStyle): void;
    onMarkStyleSelected(markStyle: LinkMarkStyle): void;
    onColorSelected(color: LinkColor): void;
    onReplaceStart: (linkerState: LinkerState<LinkableTargetKey>, linkKey: LinkKey) => void;
    onReplaceMove: (currentPosition: Position) => void;
    onReplaceEnd: (currentPosition: Position) => void;
    isReplacing: boolean;
};

type State = {
    bendingPoint: Point;
    draggingBendingPoint: boolean;
    otherUserSelected: boolean;
    textRectSize: Size;
    isLineSelected: boolean;
    isSourceMultiplicityMenuShown: boolean;
    isTargetMultiplicityMenuShown: boolean;
};

export const StickyLink = (props: Props) => {
    const linkOperationRef = useRef<LinkOperation | null>(null);

    const { viewModel, modelKey, link, onReplaceStart, onReplaceMove, onReplaceEnd } = props;

    const commandManager = useCommandManager();

    if (!linkOperationRef.current) {
        linkOperationRef.current = new LinkOperation(viewModel, modelKey, link.key, commandManager);
    }

    const [state, setState] = useState<State>({
        bendingPoint: Point.fromPosition(props.placement.bendingPoint(link.bend).toObject()),
        draggingBendingPoint: false,
        otherUserSelected: false,
        textRectSize: new Size(0, 0),
        isLineSelected: false,
        isSourceMultiplicityMenuShown: false,
        isTargetMultiplicityMenuShown: false,
    });

    const bendingPoint = Point.fromPosition(props.placement.bendingPoint(link.bend).toObject());
    const bezier = props.placement.bezierLine(bendingPoint);

    // 編集中は内部の状態、そうでない場合はpropsからの値を参照する
    const currentBendingPoint = state.draggingBendingPoint ? state.bendingPoint : bendingPoint;
    const currentBezier = state.draggingBendingPoint ? props.placement.bezierLine(state.bendingPoint) : bezier;
    const actualTextRect = Rect.fromCenterPointSize(currentBendingPoint, state.textRectSize);

    const movingSourcePositionRef = useRef<Point>(Point.fromPosition(bezier.startEdge().position));
    const movingTargetPositionRef = useRef<Point>(Point.fromPosition(bezier.endEdge().position));

    const notifyOtherUserEditingToast = useOtherUserEditingToast();
    const { otherUserEditing, endEdit, startEdit } = useExclusiveLinkEditing(link.key, viewModel.id);

    const otherUserSelected = useOtherUserIsSelected({
        nodeId: link.id,
        clientId: getClientId(),
        modelId: modelKey.modelID,
    });

    if (!props.isSelected && state.isLineSelected) {
        setState((prev) => ({
            ...prev,
            isLineSelected: false,
        }));
    }

    if (!props.isSelected && state.draggingBendingPoint) {
        setState((prev) => ({
            ...prev,
            draggingBendingPoint: false,
        }));
    }

    const isViewModelUpdatable = !props.readonly;

    const handleClick = (): void => {
        props.onClick(props.link.id);
    };

    const handleToggleMultiplicityEnabled = useCallback((): void => {
        linkOperationRef.current?.toggleMultiplicityEnable();
    }, []);

    const handleUpdateSourceMultiplicity = useCallback((multiplicity: Multiplicity): void => {
        linkOperationRef.current?.updateSourceMultiplicity(multiplicity);
    }, []);

    const handleUpdateTargetMultiplicity = useCallback((multiplicity: Multiplicity): void => {
        linkOperationRef.current?.updateTargetMultiplicity(multiplicity);
    }, []);

    const handleBendingPointDblClick = useCallback((): void => {
        if (otherUserEditing) {
            notifyOtherUserEditingToast();
            return;
        }
        linkOperationRef.current?.updateBend(LinkBend.Straight());
        setState((prev) => ({
            ...prev,
            draggingBendingPoint: false,
            bendingPoint: Point.fromPosition(props.placement.bendingPoint(LinkBend.Straight()).toObject()),
        }));
    }, [otherUserEditing, notifyOtherUserEditingToast, props.placement]);

    const handleBendingPointDragStart = useCallback(() => {
        if (otherUserEditing) {
            notifyOtherUserEditingToast(link.key.toString());
            return;
        }
        startEdit();
        setState((prev) => ({
            ...prev,
            draggingBendingPoint: true,
            bendingPoint: Point.fromPosition(props.placement.bendingPoint(link.bend).toObject()),
        }));
    }, [otherUserEditing, link.key, notifyOtherUserEditingToast, startEdit, link.bend, props.placement]);

    const handleBendingPointDrag = useCallback(
        (dx: number, dy: number): void => {
            if (otherUserEditing) {
                notifyOtherUserEditingToast(link.key.toString());
                return;
            }
            startEdit();
            setState((prev) => {
                const bendingPoint = prev.bendingPoint.addXY(dx, dy);
                return { ...prev, bendingPoint };
            });
        },
        [otherUserEditing, link.key, notifyOtherUserEditingToast, startEdit]
    );

    const handleBendingPointDragEnd = useCallback((): void => {
        const bend = props.placement.bend(state.bendingPoint);
        const bendingPoint = Point.fromPosition(props.placement.bendingPoint(bend).toObject());

        if (!otherUserEditing) {
            linkOperationRef.current?.updateBend(bend);
            endEdit();
        }

        setState((prev) => ({ ...prev, draggingBendingPoint: false, bendingPoint }));
    }, [props.placement, state.bendingPoint, otherUserEditing, endEdit]);

    const handleTextChange = useCallback((text: string): void => {
        const name = new LinkName(text);
        linkOperationRef.current?.handleTextChange(name);
    }, []);

    const handleTextStartEdit = useCallback(
        (text: string): void => {
            if (otherUserEditing) {
                notifyOtherUserEditingToast();
                return;
            }
            linkOperationRef.current?.handleTextStartEdit(new LinkName(text));
            startEdit();
        },
        [otherUserEditing, startEdit, notifyOtherUserEditingToast]
    );

    const handleTextEndEdit = useCallback(
        (text: string): void => {
            if (otherUserEditing) return;
            linkOperationRef.current?.handleTextEndEdit(new LinkName(text));
            setState((prev) => ({ ...prev, isLineSelected: true }));
            endEdit();
        },
        [otherUserEditing, endEdit]
    );

    const handleReverseLink = useCallback((): void => {
        linkOperationRef.current?.reverseLink();
    }, []);

    const handleDeleteLink = useCallback((): void => {
        linkOperationRef.current?.deleteLink();
    }, []);

    const handleSizeChange = useCallback((size: Size): void => {
        setState((prev) => {
            return { ...prev, textRectSize: size };
        });
    }, []);

    const handleLineClick = useCallback((): void => {
        setState((prev) => ({ ...prev, isLineSelected: true }));
    }, []);

    const handleTextClick = useCallback((): void => {
        setState((prev) => ({ ...prev, isLineSelected: false }));
    }, []);

    const handleToggleSourceMultiplicityMenu = useCallback((visible: boolean): void => {
        setState((prev) => ({ ...prev, isSourceMultiplicityMenuShown: visible }));
    }, []);

    const handleToggleTargetMultiplicityMenu = useCallback((visible: boolean): void => {
        setState((prev) => ({ ...prev, isTargetMultiplicityMenuShown: visible }));
    }, []);

    const handleReplaceSourceStart = () => {
        if (otherUserEditing) {
            notifyOtherUserEditingToast();
            return;
        }

        movingSourcePositionRef.current = Point.fromPosition(bezier.startEdge().position);

        startEdit();
        setState((prev) => ({ ...prev }));

        const {
            link: { from, to, key },
        } = props;
        const linkerState = LinkerState.replacingSourceStart(
            from,
            bezier.startEdge().position,
            to,
            bezier.endEdge().position
        );
        onReplaceStart(linkerState, key);
    };

    const handleReplaceTargetStart = () => {
        if (otherUserEditing) {
            notifyOtherUserEditingToast();
            return;
        }

        movingTargetPositionRef.current = Point.fromPosition(bezier.endEdge().position);

        startEdit();
        setState((prev) => ({ ...prev }));

        const {
            link: { from, to, key },
        } = props;
        const linkerState = LinkerState.replacingTargetStart(
            from,
            bezier.startEdge().position,
            to,
            bezier.endEdge().position
        );
        onReplaceStart(linkerState, key);
    };

    const handleReplaceSourceMove = useCallback(
        (dx: number, dy: number) => {
            movingSourcePositionRef.current = movingSourcePositionRef.current.addXY(dx, dy);
            const movingPosition = movingSourcePositionRef.current;
            onReplaceMove(movingPosition);
        },
        [onReplaceMove, movingSourcePositionRef]
    );

    const handleReplaceTargetMove = useCallback(
        (dx: number, dy: number) => {
            movingTargetPositionRef.current = movingTargetPositionRef.current.addXY(dx, dy);
            const movingPosition = movingTargetPositionRef.current;
            onReplaceMove(movingPosition);
        },
        [onReplaceMove, movingTargetPositionRef]
    );

    const handleReplaceSourceEnd = useCallback(() => {
        if (otherUserEditing) return;
        const movingPosition = movingSourcePositionRef.current;
        onReplaceEnd(movingPosition);
        endEdit();
    }, [movingSourcePositionRef, onReplaceEnd, otherUserEditing, endEdit]);

    const handleReplaceTargetEnd = useCallback(() => {
        if (otherUserEditing) return;
        const movingPosition = movingTargetPositionRef.current;
        onReplaceEnd(movingPosition);
        endEdit();
    }, [movingTargetPositionRef, onReplaceEnd, otherUserEditing, endEdit]);

    const lineView = (
        <LinkLine
            key="LinkLineView"
            style={props.link.style}
            isSelected={props.isSelected}
            isOtherUserSelected={otherUserSelected ?? false}
            isRelatedNodeSelected={props.isRelatedNodeSelected}
            showMenu={isViewModelUpdatable && props.showMenu}
            bendingPoint={currentBendingPoint}
            bezier={currentBezier}
            onBendingPointDblClick={handleBendingPointDblClick}
            onBendingPointDrag={handleBendingPointDrag}
            onBendingPointDragStart={handleBendingPointDragStart}
            onBendingPointDragEnd={handleBendingPointDragEnd}
            onLineClick={handleLineClick}
        />
    );

    const textView = (props.isSelected || link.name.value) && (
        <g key="LinkTextView" transform={actualTextRect.topLeft().toSVGTranslate()}>
            <LinkTextView
                text={link.name.toString()}
                showLabel={isViewModelUpdatable && props.showMenu}
                editable={isViewModelUpdatable}
                otherUserEditing={otherUserEditing}
                onChange={handleTextChange}
                onStartEdit={handleTextStartEdit}
                onEndEdit={handleTextEndEdit}
                onSizeChange={handleSizeChange}
                onTextClick={handleTextClick}
            />
        </g>
    );

    const multiplicityPairView = link.multiplicity.isEnabled && (
        <LinkMultiplicityPair
            key={'LinkMultiplicityPairView'}
            bezier={currentBezier}
            editable={props.showMenu}
            isLinkSelected={props.isSelected}
            multiplicity={link.multiplicity}
            isSourceMultiplicityMenuShown={state.isSourceMultiplicityMenuShown}
            onUpdateSourceMultiplicity={handleUpdateSourceMultiplicity}
            onUpdateTargetMultiplicity={handleUpdateTargetMultiplicity}
            onToggleSourceMultiplicityMenu={handleToggleSourceMultiplicityMenu}
            onToggleTargetMultiplicityMenu={handleToggleTargetMultiplicityMenu}
        />
    );

    const isMultiplicityMenuShown = state.isSourceMultiplicityMenuShown || state.isTargetMultiplicityMenuShown;
    const showLinkMenu = isViewModelUpdatable && props.showMenu && !isMultiplicityMenuShown;

    return (
        <LinkWrapperView onClick={handleClick}>
            {!props.isReplacing && (
                <>
                    {state.isLineSelected ? (
                        // リンクのライン選択時は、ラインを前面にもってくる
                        <Fragment>
                            {textView}
                            {lineView}
                            {multiplicityPairView}
                        </Fragment>
                    ) : (
                        // リンクのライン非選択時は、テキストを前面にもってくる
                        <Fragment>
                            {lineView}
                            {textView}
                            {multiplicityPairView}
                        </Fragment>
                    )}
                </>
            )}
            {showLinkMenu && !props.isReplacing && (
                <LinkMenu
                    position={actualTextRect.topCenter()}
                    style={props.link.style}
                    isMultiplicityEnabled={link.multiplicity.isEnabled}
                    onLineStyleSelected={props.onLineStyleSelected}
                    onMarkStyleSelected={props.onMarkStyleSelected}
                    onColorSelected={props.onColorSelected}
                    onToggleMultiplicityEnabled={handleToggleMultiplicityEnabled}
                    onReverseLink={handleReverseLink}
                    onDeleteLink={handleDeleteLink}
                />
            )}

            {state.isLineSelected && !props.isReplacing && (
                <LinkDragHandle
                    SourceLineEdge={currentBezier.startEdge()}
                    TargetLineEdge={currentBezier.endEdge()}
                    onReplaceSourceStart={handleReplaceSourceStart}
                    onReplaceTargetStart={handleReplaceTargetStart}
                    onReplaceSourceMove={handleReplaceSourceMove}
                    onReplaceTargetMove={handleReplaceTargetMove}
                    onReplaceSourceEnd={handleReplaceSourceEnd}
                    onReplaceTargetEnd={handleReplaceTargetEnd}
                />
            )}
        </LinkWrapperView>
    );
};
