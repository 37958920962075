import { useCallback } from 'react';
import { FlatButton } from '@framework/ui/atoms';
import { Modal } from '@framework/ui/elements';
import { GroupEntity } from '@group/domain';
import { GroupMemberEditTab, MenuName } from '../GroupMemberEditTab';
import { MemberInvitationList } from './MemberInvitationList';

type Props = {
    isOpen: boolean;
    onClose(): void;
    groupEntity: GroupEntity;
    onClickMenu(menu: MenuName): void;
};

export const MemberInvitationListModal: React.FC<Props> = ({ isOpen, onClose, groupEntity, onClickMenu }: Props) => {
    const handleClose = useCallback(() => onClose(), [onClose]);
    const handleFocus = useCallback(() => onClickMenu('invitation'), [onClickMenu]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} className="flex flex-col">
            {/* header */}
            <div className="mb-1 p-4 text-2xl font-bold">メンバーを招待</div>

            {/* tab */}
            <GroupMemberEditTab current="invitations" showDeleteButton={false} onClickMenu={onClickMenu} />

            {/* content */}
            <div className="px-4">
                <div className="overflow-y-auto px-4 py-2" style={{ maxHeight: 'calc(85vh - 160px)' }}>
                    <div className="flex flex-col">
                        <div className="pt-4 text-2xl font-bold">メールで招待する</div>

                        <div className="pb-1 pt-4 font-bold">送付先</div>
                        <input
                            type="text"
                            className="w-full rounded-sm border border-gray-500 p-4"
                            placeholder="メールアドレスでグループメンバーを追加"
                            onFocus={handleFocus}
                        />

                        <div className="pb-1 pt-4 font-bold">招待リスト</div>
                        <MemberInvitationList groupId={groupEntity.id} />
                    </div>
                </div>
            </div>

            {/* footer */}
            <hr className="my-2 w-full" />
            <div className="flex justify-end px-8 py-4">
                <FlatButton onClick={handleClose}>閉じる</FlatButton>
            </div>
        </Modal>
    );
};
