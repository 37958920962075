import { StickyModelContentsOperation, ElementDragManager } from '@view-model/adapter';
import { toast } from 'react-hot-toast';
import { ModelElementId } from '@view-model/domain/key';
import { ApplicationClipboardPayload } from '@view-model/application/clipboard';
import { ReadonlyHandlers } from './ReadonlyHandlers';
import { Point } from '@view-model/models/common/basic';
import { MultiSelectionMode } from '@user/pages/ViewModelPage';

export class WritableHandlers extends ReadonlyHandlers {
    constructor(
        protected readonly operation: StickyModelContentsOperation,
        private readonly dragManager: ElementDragManager,
        private readonly onDeleteView: () => void
    ) {
        super(operation);
    }

    handleArrowKeys(dx: number, dy: number): void {
        this.operation.moveSelectedElements(dx, dy);
    }

    handleDeleteSelectedElements(): void {
        if (this.operation.hasSelectedElements()) {
            this.operation.deleteSelectedElements().then();
            toast('削除しました | Deleted');
        } else {
            // 選択している要素がない場合はViewの削除としてコールバックを呼ぶ
            this.onDeleteView();
        }
    }

    handleCutSelectedElements(): void {
        const { operation } = this;

        if (!operation.hasSelectedElements()) return;

        if (operation.copySelectedElements()) {
            operation.deleteSelectedElements().then(() => {
                toast.success('カットしました | Cut');
            });
        } else {
            toast.error('カットに失敗しました | Cut failed');
        }
    }

    async handlePasteClipboardPayload(payload: ApplicationClipboardPayload, position: Point): Promise<void> {
        const toastId = 'sticky-model-contents-pasting';
        toast('ペーストしています...', { id: toastId, duration: Infinity });

        const err = await this.operation.pasteClipboardPayload(payload, position);
        toast.dismiss(toastId);

        if (err) {
            toast.error(err);
        }
    }

    handleDuplicateSelectedElements(position: Point): void {
        this.operation.duplicateSelectedElements(position);
    }

    handleDragStart(id: ModelElementId, multiSelectionMode: MultiSelectionMode): void {
        this.dragManager.onDragStart(id, multiSelectionMode, this.operation);
    }

    handleDrag(dx: number, dy: number): void {
        this.dragManager.onDrag(dx, dy).then();
    }

    handleDragEnd(): void {
        this.dragManager.onDragEnd(this.operation).then();
    }
}
