import { useMemo } from 'react';
import { Rect } from '@view-model/models/common/basic';
import { useStickyNodeDescriptionPlacement, useStickyNodeDescriptionContent } from '../hook';
import { NodeId, ViewModelId } from '@schema-common/base';
import { useExclusiveTextEditing } from '@model-framework/text/editing-user';
import { StickyNodeDescription, StickyNodeDescriptionEditLockKey, StickyNodeDescriptionRepository } from '../domain';
import { Callout } from '@model-framework/ui';
import { StickyNodeDescriptionLayout } from './StickyNodeDescriptionLayout';
import { ModelLayout } from '@view-model/models/sticky/layout';

type Props = {
    viewModelId: ViewModelId;
    nodeId: NodeId;
    description: StickyNodeDescription;
    readonly: boolean;
    nodeRect: Rect;
    repository: StickyNodeDescriptionRepository;
};

export const StickyNodeDescriptionContent: React.FC<Props> = ({
    viewModelId,
    nodeId,
    description,
    readonly,
    nodeRect,
    repository,
}: Props) => {
    const { position, size, targetRect, setSize, onDragStart, onDrag, onDragEnd } = useStickyNodeDescriptionPlacement(
        nodeRect,
        repository,
        description,
        readonly
    );

    const { content, onStartEdit, onEdit, onEndEdit } = useStickyNodeDescriptionContent(description, repository);

    const editLockKey = useMemo(() => StickyNodeDescriptionEditLockKey.fromNodeId(nodeId), [nodeId]);

    const { editing, startEdit, endEdit } = useExclusiveTextEditing(viewModelId, editLockKey, content, readonly);

    // 空文字はfalseなのでcontentだけ厳密にnullチェックする
    if (!position || !targetRect || content === null) return null;

    return (
        <Callout
            editing={editing}
            text={content}
            position={position}
            size={size}
            minHeight={StickyNodeDescriptionLayout.initialSize().height}
            targetRect={targetRect}
            tailBaseLineLength={ModelLayout.GridSize}
            tailBaseLineMargin={ModelLayout.GridSize}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            onChangeSize={setSize}
            onStartEdit={onStartEdit}
            onEdit={onEdit}
            onEndEdit={onEndEdit}
            onBlur={endEdit}
            onDblClick={startEdit}
        />
    );
};
