import { createRef, Component } from 'react';
import { select } from 'd3-selection';
import { drag as Drag, DragBehavior, D3DragEvent } from 'd3-drag';

type Position = {
    x: number;
    y: number;
};

type Props = {
    children: React.ReactNode;
    className?: string;
    position: Position;
    onDragStart?: () => void;
    onDrag(dx: number, dy: number): void;
    onDragEnd?: () => void;
    onDragMoveStarted?: () => void;
    onDragMoveEnded?: () => void;
    onClick?: () => void;
};

// Functional Componentの場合、d3-dragと組み合わせたときのstate更新がうまくいかないためClassコンポーネントで実装
export class DraggableSVGGElement extends Component<Props> {
    private readonly drag: DragBehavior<SVGGElement, unknown, unknown>;
    private readonly ref: React.RefObject<SVGGElement>;
    private dragMoved = false;

    constructor(props: Props) {
        super(props);

        this.drag = Drag<SVGGElement, unknown, unknown>()
            .on('drag', this.onDrag.bind(this))
            .on('start', this.onDragStart.bind(this))
            .on('end', this.onDragEnd.bind(this));

        this.onClick = this.onClick.bind(this);
        this.ref = createRef();
    }

    componentDidMount(): void {
        this.ref.current && select(this.ref.current).call(this.drag);
    }

    componentWillUnmount(): void {
        select(this.ref.current).on('.drag', null);
    }

    private onDragStart() {
        this.props.onDragStart?.();
        this.dragMoved = false;
    }

    private onDrag(event: D3DragEvent<Element, unknown, unknown>): void {
        if (!this.dragMoved) {
            this.onDragMoveStarted();
            this.dragMoved = true;
        }

        this.props.onDrag?.(event.dx, event.dy);
    }

    private onDragEnd(): void {
        if (this.dragMoved) {
            this.onDragMoveEnded();
            this.dragMoved = false;
        }
        this.props.onDragEnd?.();
    }

    private onDragMoveStarted(): void {
        this.props.onDragMoveStarted?.();
    }

    private onDragMoveEnded(): void {
        this.props.onDragMoveEnded?.();
    }

    private onClick() {
        this.props.onClick?.();
    }

    render(): React.ReactNode {
        const { position, children } = this.props;

        return (
            <g
                className={this.props.className}
                ref={this.ref}
                transform={`translate(${position.x}, ${position.y})`}
                onClick={this.onClick}
            >
                {children}
            </g>
        );
    }
}
