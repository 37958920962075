import { LinkableTargetId, LinkableTargetKey, LinkKey, NodeKey, StickyZoneKey } from '@view-model/domain/key';
import { LinkStyle, LinkType, LinkBend, LinkMultiplicity, LinkName, LinkPlacementFactory } from './vo';
import { LinkableTargetCollection } from '@view-model/domain/model/LinkableTargetCollection';
import { Key, Model } from '@framework/domain';
import { Rect } from '@view-model/models/common/basic';
import { LinkJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/model-contents/{modelId}/links/{linkId}/LinkJSON';
import { LinkId } from '@schema-common/base';

interface ILink {
    key: LinkKey;
    from: LinkableTargetKey;
    to: LinkableTargetKey;
    name: LinkName;
    bend: LinkBend;
    style: LinkStyle;
    multiplicity: LinkMultiplicity;
}

function buildLinkableTargetKey(keyStr: string): LinkableTargetKey {
    const key = new Key(keyStr);
    switch (key.kind) {
        case NodeKey.KIND:
            return new NodeKey(keyStr);
        case StickyZoneKey.KIND:
            return new StickyZoneKey(keyStr);
        default:
            throw new Error(`Not supported key in LinkableTargetKey: ${keyStr}`);
    }
}

export class LinkEntity extends Model<LinkKey> {
    public readonly key: LinkKey;
    public readonly from: LinkableTargetKey;
    public readonly to: LinkableTargetKey;
    public readonly type: LinkType = LinkType.Link;
    private readonly _name: LinkName;
    private readonly _bend: LinkBend;
    private readonly _style: LinkStyle;
    private readonly _multiplicity: LinkMultiplicity;

    public constructor(attributes: ILink) {
        super();

        this.key = attributes.key;
        this.from = attributes.from;
        this.to = attributes.to;
        this._name = attributes.name;
        this._bend = attributes.bend;
        this._style = attributes.style;
        this._multiplicity = attributes.multiplicity;
    }

    public static buildNew(from: LinkableTargetKey, to: LinkableTargetKey): LinkEntity {
        return new LinkEntity({
            key: LinkKey.buildNew(),
            from: from,
            to: to,
            name: new LinkName(''),
            bend: LinkBend.Straight(),
            style: LinkStyle.buildNew(),
            multiplicity: LinkMultiplicity.Disabled(),
        });
    }

    public static load(json: LinkJSON): LinkEntity {
        return new LinkEntity({
            key: new LinkKey(json.key),
            from: buildLinkableTargetKey(json.fromKey),
            to: buildLinkableTargetKey(json.toKey),
            name: LinkName.load(json.name),
            bend: LinkBend.load(json.bend),
            style: LinkStyle.load(json.style),
            multiplicity: LinkMultiplicity.load(json.multiplicity),
        });
    }

    public dump(): LinkJSON {
        return {
            key: this.key.toString(),
            fromKey: this.from.toString(),
            toKey: this.to.toString(),
            name: this.name.dump(),
            bend: this.bend.dump(),
            style: this.style.dump(),
            multiplicity: this.multiplicity.dump(),
        };
    }

    public clone(): LinkEntity {
        return LinkEntity.load(this.dump());
    }

    public cloneNew(newKeyMap: Record<string, LinkableTargetKey>): LinkEntity {
        return LinkEntity.load(
            Object.assign(this.dump(), {
                key: LinkKey.buildNew().toString(),
                fromKey: newKeyMap[this.from.toString()].toString(),
                toKey: newKeyMap[this.to.toString()].toString(),
            })
        );
    }

    public get id(): LinkId {
        return this.key.id as LinkId;
    }

    public get fromId(): LinkableTargetId {
        return this.from.id as LinkableTargetId;
    }

    public get toId(): LinkableTargetId {
        return this.to.id as LinkableTargetId;
    }

    public get name(): LinkName {
        return this._name;
    }

    public get bend(): LinkBend {
        return this._bend;
    }

    public get style(): LinkStyle {
        return this._style;
    }

    public get multiplicity(): LinkMultiplicity {
        return this._multiplicity;
    }

    public isEqual(other: LinkEntity): boolean {
        return (
            other instanceof LinkEntity &&
            this.key.isEqual(other.key) &&
            this.from.isEqual(other.from) &&
            this.to.isEqual(other.to) &&
            this.bend.isEquals(other.bend) &&
            this.style.isEquals(other.style) &&
            this.multiplicity.isEquals(other.multiplicity) &&
            this.name.isEquals(other.name) &&
            this.type == other.type
        );
    }

    private attributes(): ILink {
        const { key, from, to, name, bend, style, multiplicity } = this;
        return { key, from, to, name, bend, style, multiplicity };
    }

    withName(name: LinkName): LinkEntity {
        return new LinkEntity({ ...this.attributes(), name });
    }

    withBend(bend: LinkBend): LinkEntity {
        return new LinkEntity({ ...this.attributes(), bend });
    }

    withMultiplicity(multiplicity: LinkMultiplicity): LinkEntity {
        return new LinkEntity({ ...this.attributes(), multiplicity });
    }

    withFromToKeys(fromKey: LinkableTargetKey, toKey: LinkableTargetKey) {
        return new LinkEntity({ ...this.attributes(), from: fromKey, to: toKey });
    }

    public isLinkTo(key: LinkableTargetKey): boolean {
        return this.to.isEqual(key) || this.from.isEqual(key);
    }

    /**
     * このリンクが指定された要素セットそれぞれのいずれかの要素をつなぐリンクかどうかを返します。
     */
    isLinkBetweenAny(a: Set<LinkableTargetId>, b: Set<LinkableTargetId>): boolean {
        const { fromId, toId } = this;
        return (a.has(fromId) && b.has(toId)) || (a.has(toId) && b.has(fromId));
    }

    /**
     * このリンクが、要素aと要素bを接続する場合に true を返す
     * @param a
     * @param b
     */
    isConnectTo(a: LinkableTargetKey, b: LinkableTargetKey): boolean {
        return this.isLinkTo(a) && this.isLinkTo(b) && !a.isEqual(b);
    }

    public isValidBy(elements: LinkableTargetCollection): boolean {
        return elements.includeKey(this.from) && elements.includeKey(this.to);
    }

    /**
     * 渡されたIDリスト内で接続できるかどうかを返します。
     * @param ids
     */
    canConnectWithin(ids: LinkableTargetId[]): boolean {
        return ids.includes(this.fromId) && ids.includes(this.toId);
    }

    public getReversedLink(): LinkEntity {
        return new LinkEntity({
            key: this.key,
            from: this.to,
            to: this.from,
            name: this.name,
            bend: new LinkBend({ r: 1 - this.bend.r, d: -this.bend.d }),
            style: this.style,
            multiplicity: this.multiplicity,
        });
    }

    /**
     * リンクを覆う矩形を返します。
     * @param linkPlacementFactory リンクの配置情報を生成するファクトリ
     */
    getBounds(linkPlacementFactory: LinkPlacementFactory): Rect | null {
        const placement = linkPlacementFactory.linkPlacementOf(this);
        if (!placement) return null;

        const bezier = placement.bezierLine(placement.bendingPoint(this.bend));
        return bezier.boundingRect();
    }
}
