import { useCallback, useMemo, useState } from 'react';
import { useUserPublicProfile, useUserPublicProfiles } from '@user/PublicProfile';
import { UserIcon } from '@framework/ui/atoms/UserIcon';
import { useMountedRef } from '@framework/hooks';
import { Loading } from '@framework/ui/atoms/Loading';
import { Tooltip } from '@framework/ui/atoms/Tooltip';
import { GroupMemberRole, GroupMemberRoles } from '@group/domain';
import { RoleSelect } from '../RoleSelect';
import { GroupOperation } from '@group/domain/GroupEntity/GroupOperation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

type Props = {
    groupId: string;
    currentUserId: string;
    memberRoles: GroupMemberRoles;
};

export const MemberEditList: React.FC<Props> = ({ groupId, currentUserId, memberRoles }: Props) => {
    const userIds = useMemo(() => {
        return memberRoles.userIds().filter((id) => id !== currentUserId);
    }, [currentUserId, memberRoles]);
    const profiles = useUserPublicProfiles(userIds);
    const currentUserProfile = useUserPublicProfile(currentUserId);
    const currentUserRole = memberRoles.getRoleOf(currentUserId);

    if (!profiles || !currentUserProfile) {
        return (
            <div className="py-4">
                <Loading />
            </div>
        );
    }

    return (
        <>
            <div className="px-4 py-2 font-bold">{memberRoles.length}人のメンバー</div>
            <ul className="p-2">
                {currentUserRole && (
                    <MemberItem
                        groupId={groupId}
                        userId={currentUserId}
                        name={currentUserProfile.name}
                        imageUrl={currentUserProfile.imageUrl}
                        role={currentUserRole}
                        editable={false}
                    />
                )}

                {profiles.map(({ id: userId, name, imageUrl }) => {
                    const role = memberRoles.getRoleOf(userId);
                    if (!role) return null;

                    return (
                        <MemberItem
                            key={userId}
                            groupId={groupId}
                            userId={userId}
                            name={name}
                            imageUrl={imageUrl}
                            role={role}
                            editable={true}
                        />
                    );
                })}
            </ul>
        </>
    );
};

type ItemProps = {
    groupId: string;
    userId: string;
    name: string;
    imageUrl: string | null;
    role: GroupMemberRole;
    editable: boolean;
};

const MemberItem: React.FC<ItemProps> = ({ groupId, userId, name, imageUrl, role, editable }: ItemProps) => {
    const [done, setDone] = useState(false);
    const [failed, setFailed] = useState(false);

    const mountedRef = useMountedRef();

    const handleRoleChange = useCallback(
        async (role: GroupMemberRole) => {
            if (!editable) return;

            setFailed(false);

            // マウント解除済みならば、state更新しない
            if (!mountedRef.current) return;

            if (await GroupOperation.updateMemberRole(groupId, userId, role)) {
                setDone(true);
                setTimeout(() => mountedRef.current && setDone(false), 1000);
            } else {
                setFailed(true);
            }
        },
        [editable, mountedRef, userId, groupId]
    );

    return (
        <li className="flex flex-row items-center p-2 hover:bg-gray-200">
            <UserIcon name={name} id={userId} imageUrl={imageUrl} size="medium" />
            <span className="grow truncate px-2">{name}</span>
            <span className="flex">
                {done && <FontAwesomeIcon icon={faCheck} className="my-auto mr-2 text-brand" />}
                {failed && (
                    <Tooltip tooltip="ロールの更新に失敗しました" placement="bottom">
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-red-700" />
                    </Tooltip>
                )}
                <RoleSelect role={role} onChange={handleRoleChange} disabled={!editable} />
            </span>
        </li>
    );
};
