import { useCallback, useState } from 'react';
import { ViewModelClipboardPayload } from '@view-model/application/operation';
import { ViewJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/views/{viewId}/ViewJSON';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Button } from '@framework/ui/atoms/Button';

type Props = {
    isOpen: boolean;
    onSubmit(): Promise<void>;
    onClose(): void;
    payload: ViewModelClipboardPayload;
};

const toReadableName = (name: string): string => {
    return name === '' ? '名称未設定のビュー' : name;
};

export const ViewModelPasteConfirmModal: React.FC<Props> = ({ isOpen, onSubmit, onClose, payload }: Props) => {
    const { views, consistencyLinks } = payload;
    const consistencyLinkCount = Object.entries(consistencyLinks).length;
    const [pasting, setPasting] = useState(false);

    const handleSubmit = useCallback(async () => {
        setPasting(true);
        await onSubmit();
        setPasting(false);
    }, [onSubmit]);

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={onClose}
            title={'貼り付け処理を続行してもよいですか？'}
            submitButton={
                <Button onClick={handleSubmit} color="primary" loading={pasting} disabled={pasting}>
                    貼り付け
                </Button>
            }
            cancelButton={
                <Button onClick={onClose} color="secondary" disabled={pasting}>
                    キャンセル
                </Button>
            }
        >
            <div className="overflow-y-auto p-4" style={{ width: '600px', maxHeight: '70vh' }}>
                以下のビュー、整合性リンクと、その中のモデル（ノード、リンク）の貼り付け処理を行います。
                <br />
                <div className="py-2">
                    <div className="text-xl font-bold">ビュー</div>
                    <ul className="ml-8 list-disc">
                        {views.length > 0 ? (
                            views.map((view: ViewJSON) => <li key={view.key}>{toReadableName(view.name)}</li>)
                        ) : (
                            <li>作成されるビューはありません</li>
                        )}
                    </ul>
                </div>
                <div className="py-2">
                    <div className="text-xl font-bold">整合性リンク</div>
                    <ul className="ml-8 list-disc">
                        {consistencyLinkCount > 0 ? (
                            <li>{consistencyLinkCount}個の整合性リンクが作成されます</li>
                        ) : (
                            <li>作成される整合性リンクはありません</li>
                        )}
                    </ul>
                </div>
            </div>
        </GeneralModal>
    );
};
