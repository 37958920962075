import { Point } from '@view-model/models/common/basic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons';

export const HyperLinkViewModelIcon: React.FC = () => {
    const iconSize = 48;
    const position = new Point(-iconSize / 2, -iconSize / 2);

    return (
        <g fill={'white'} transform={position.toSVGTranslate()}>
            <foreignObject width={iconSize} height={iconSize}>
                <div className="bg-brand px-1 py-2 text-white">
                    <FontAwesomeIcon icon={faProjectDiagram} size="2x" />
                </div>
            </foreignObject>
        </g>
    );
};
