import { useCallback, useState } from 'react';
import { FlatButton } from '@framework/ui/atoms';
import { Modal } from '@framework/ui/elements';
import { GroupEntity } from '@group/domain';
import { GroupMemberEditTab, MenuName } from '../GroupMemberEditTab';
import { MemberRemoveList } from './MemberRemoveList';
import { useMountedRef } from '@framework/hooks';
import { GroupOperation } from '@group/domain/GroupEntity/GroupOperation';

type Props = {
    isOpen: boolean;
    onClose(): void;
    groupEntity: GroupEntity;
    currentUserId: string;
    onClickMenu(menu: MenuName): void;
};

export const MemberRemoveModal: React.FC<Props> = ({
    isOpen,
    onClose,
    groupEntity,
    currentUserId,
    onClickMenu,
}: Props) => {
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
    const handleChange = useCallback((userIds: string[]) => setSelectedUserIds(userIds), []);
    const [loading, setLoading] = useState(false);

    const mountedRef = useMountedRef();

    const handleClose = useCallback(() => {
        setSelectedUserIds([]);
        onClose();
    }, [onClose]);

    const handleRemove = useCallback(async () => {
        const confirmMessage = [
            `選択された${selectedUserIds.length}人をグループから削除します。`,
            'グループメンバーから削除されると、所属していたワークスペースからも削除されます。',
            'この操作は元に戻すことはできません。よく確認してから実行してください。',
        ].join('\n');

        if (!confirm(confirmMessage)) {
            return;
        }

        // マウント解除済みならば削除操作を中断
        if (!mountedRef.current) return;

        setLoading(true);

        if (await GroupOperation.removeMembers(groupEntity.id, selectedUserIds)) {
            handleClose();
        } else {
            alert('グループメンバーの削除に失敗しました。もう一度試してみるか、サポート担当者にお問い合わせください。');
        }
    }, [handleClose, groupEntity, mountedRef, selectedUserIds]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} className="flex flex-col">
            {/* header */}
            <div className="mb-1 p-4 text-2xl font-bold">メンバーの削除</div>

            {/* tab */}
            <GroupMemberEditTab current="members" showDeleteButton={false} onClickMenu={onClickMenu} />

            {/* content */}
            <div className="overflow-y-auto px-4 py-2" style={{ maxHeight: 'calc(85vh - 160px)' }}>
                <MemberRemoveList
                    currentUserId={currentUserId}
                    memberRoles={groupEntity.members}
                    selectedUserIds={selectedUserIds}
                    onChange={handleChange}
                />
            </div>

            {/* footer */}
            <hr className="my-2 w-full" />
            <div className="flex justify-end px-8 py-4">
                <FlatButton onClick={handleClose}>閉じる</FlatButton>
                <FlatButton
                    onClick={handleRemove}
                    color="brand"
                    className="ml-2"
                    disabled={selectedUserIds.length === 0}
                    loading={loading}
                >
                    {selectedUserIds.length === 0
                        ? '選択したメンバーを削除'
                        : `選択した${selectedUserIds.length}人のメンバーを削除`}
                </FlatButton>
            </div>
        </Modal>
    );
};
