import { LinkColor, LinkLineStyle, LinkMarkStyle } from '@model-framework/link';
import { FontSize } from '@model-framework/text';
import { MultiSelectionRect } from '@model-framework/ui';
import { Rect } from '@view-model/models/common/basic';
import { ThemeColor } from '@view-model/models/common/color';
import { MultiSelectionMenu } from '@view-model/models/sticky/ui';

type Props = {
    rect: Rect;
    readonly: boolean;
    isNodeSelected: boolean;
    isLinkSelected: boolean;
    isZoneSelected: boolean;
    currentFontSize?: FontSize;
    currentThemeColor?: ThemeColor;
    currentLinkLineStyle?: LinkLineStyle;
    currentLinkMarkStyle?: LinkMarkStyle;
    currentLinkColor?: LinkColor;
    onFontSizeSelected(fontSize: FontSize): void;
    onThemeColorSelected(themeColor: ThemeColor): void;
    onGroupSelectedElements(): void;
    onLinkLineStyleSelected(lineStyle: LinkLineStyle): void;
    onLinkMarkStyleSelected(markStyle: LinkMarkStyle): void;
    onReverseLinks(): void;
    onRemoveLinks(): void;
    onLinkColorSelected(color: LinkColor): void;
};

export const MultiSelectionView: React.FC<Props> = ({
    rect,
    readonly,
    isNodeSelected,
    isLinkSelected,
    isZoneSelected,
    currentFontSize,
    currentThemeColor,
    currentLinkLineStyle,
    currentLinkMarkStyle,
    currentLinkColor,
    onFontSizeSelected,
    onThemeColorSelected,
    onGroupSelectedElements,
    onLinkLineStyleSelected,
    onLinkMarkStyleSelected,
    onReverseLinks,
    onRemoveLinks,
    onLinkColorSelected,
}: Props) => {
    const rc = rect.applyMarginKeepingCenter(10); // 付箋の外に足される選択枠分のマージンをつける

    return (
        <>
            <MultiSelectionRect rect={rc} />
            {!readonly && (
                <MultiSelectionMenu
                    selectionRect={rc}
                    isNodeSelected={isNodeSelected}
                    isLinkSelected={isLinkSelected}
                    isZoneSelected={isZoneSelected}
                    currentFontSize={currentFontSize}
                    currentThemeColor={currentThemeColor}
                    currentLinkLineStyle={currentLinkLineStyle}
                    currentLinkMarkStyle={currentLinkMarkStyle}
                    currentLinkColor={currentLinkColor}
                    onFontSizeSelected={onFontSizeSelected}
                    onThemeColorSelected={onThemeColorSelected}
                    onGroupSelectedElements={onGroupSelectedElements}
                    onLinkLineStyleSelected={onLinkLineStyleSelected}
                    onLinkMarkStyleSelected={onLinkMarkStyleSelected}
                    onReverseLinks={onReverseLinks}
                    onRemoveLinks={onRemoveLinks}
                    onLinkColorSelected={onLinkColorSelected}
                />
            )}
        </>
    );
};
